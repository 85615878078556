import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Table } from 'primeng/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreatebulkletterService } from 'src/app/services/createbulkletter.service';

@Component({
  selector: 'app-bulkletterdashboard',
  templateUrl: './bulkletterdashboard.component.html',
  styleUrls: ['./bulkletterdashboard.component.css']
})
export class BulkletterdashboardComponent implements OnInit {

  //common variables
  trustedHtml: any;
  ScreenName: string = 'Batch Dashboard';
  typeSelected: string;
  bulkletterList: any;
  errorValue = [];
  errorLogCount: number;
  isNewFlow: boolean;

  //Grid , myletter and allletter variables
  firstpage = 0;
  first = 0;
  rows = 10;

  //Roles and their actions variables
  norole: boolean = false;
  ADGroupNames: any;
  Roles: any;
  DashboardRoles: any;
  newRoles: any;
  Username: any;
  IsBulkLetter: boolean = true;

  // batchLetter
  bulkbatchlist: any;
  batchlistbyId: any;
  samePartner: boolean = false;
  partnerNames: any;
  myRole: any;
  onlyPartner: any;
  onlySigner: any;
  includesPartner: any;
  isPartner: any;

  //DeleteBatch
  displayDeleteModal: string;
  deleteBatchId: number;

  //Modal variables
  displayLogout = "none";
  displayWarningModal = "none";
  warningMessage: string;
  displayErrorModal = "none";
  errorMessage: string;
  displaySuccessModal = "none";
  successMessage: string;
  secondPopupOpen: boolean = false;
  displayConsoleLogerror = 'none';
  displayPartnerOfficeList = 'none';
  displayCreateletter = 'none';

  //partner office list variable
  filteredBatchArray: any;
  partnerOfficeList: any;
  displayhide = 'none';

  isCreateLetterEnabled: boolean = false;

  //Createletter Modal
  createLetterPage: any
  disabelCreationModeButton: boolean = true;
  isClientsActive: boolean = false;
  isTemplateActive: boolean = false;

  //viewchild
  @ViewChild('maintoggledrop', { static: false }) maintoggledrop: ElementRef;
  @ViewChild('dt') table: Table;
  @ViewChild('dt1') table1: Table;

  constructor(private CreatebulkletterService: CreatebulkletterService, private renderer: Renderer2, public s: DomSanitizer, private router: Router, private formbld: FormBuilder, private primengConfig: PrimeNGConfig, private elementRef: ElementRef, public SpinnerService: NgxSpinnerService) {
    this.typeSelected = 'timer';
  }

  ngOnInit(): void {
    this.Username = localStorage.getItem("UserName");
    console.log = function () { };
    this.getbatchlist();
    //this.errorlogconsole();
  }

  // next() {
  //   this.first = this.first + this.rows;
  // }

  // prev() {
  //   this.first = this.first - this.rows;
  // }

  // reset() {
  //   this.first = 0;
  // }

  // isLastPage(): boolean {
  //   return this.bulkletterList ? this.first === (this.bulkletterList.length - this.rows) : true;
  // }

  // isFirstPage(): boolean {
  //   return this.bulkletterList ? this.first === 0 : true;
  // }

  addClassInDashboard(content: string) {
    if (content == 'sidebar-open') {
      this.renderer.addClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    } else {
      this.renderer.removeClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    }
  }

  //#region screenaccesspermssions

  getScreenAccessPermission() {
    this.Roles = localStorage.getItem('NewRoles');
    this.ADGroupNames = localStorage.getItem("ADGroupNames");
    this.Username = localStorage.getItem("UserName");
    let roles = localStorage.getItem("NewRoles");
    this.newRoles = JSON.parse(roles);
    this.DashboardRoles = localStorage.getItem("IniRoles").replace(/,/g, ", ");


    if (this.DashboardRoles.trim() == "" || this.DashboardRoles == undefined || this.DashboardRoles == null) {
      this.norole = true;
    }
  }
  //#endregion

  CreatebulkletterModal() {
    this.disabelCreationModeButton = true;
    localStorage.removeItem('count');
    localStorage.removeItem('clientfetchdetail');
    this.displayCreateletter = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
    //this.router.navigate(["/createbulkletter"]);
  }
  createLetterForm(data) {
    if (data == 'Clients') {
      this.isClientsActive = true;
      this.isTemplateActive = false;
      this.disabelCreationModeButton = false;
      this.createLetterPage = 'Clients';
    } else {
      this.isTemplateActive = true;
      this.isClientsActive = false;
      this.disabelCreationModeButton = false;
      this.createLetterPage = 'Templates';
    }
  }
  CreateBulkletter() {
    if (this.createLetterPage == 'Templates') {
      this.router.navigate(["/createbulkletter"]);
    } else {
      this.router.navigate(["/createclientbulkletter"]);
    }
  }


  //getbatchlist
  async getbatchlist() {
    this.SpinnerService.show();
    this.myRole = localStorage.getItem("NewRoles");
    const rolesArray = JSON.parse(this.myRole);
    var accountId = localStorage.getItem("AccountId");
    this.myRole = localStorage.getItem("NewRoles");

    if (rolesArray.length === 1 && rolesArray[0].trim() === 'Signer') {
      this.onlySigner = true;
    }
    else {
      this.onlySigner = false;
    }
    if ((rolesArray.includes('Signer') && !rolesArray.includes('Admin'))) {
      this.includesPartner = true;
    }
    else {
      this.includesPartner = false;
    }

    if (this.includesPartner || accountId == "b8bac0a0-3e6f-4ff1-8321-92cb221ddf98") {
      this.isCreateLetterEnabled = true;
    }

    await this.CreatebulkletterService.getbatchlist()?.then((res) => {
      setTimeout(() => {
        this.SpinnerService.hide();
      }, 1000);
      this.bulkbatchlist = res.Data;

      // if (rolesArray.includes('Signer')) {
      // // if (rolesArray.length === 1 && rolesArray[0].trim() === 'Signer' ) {
      //   this.onlyPartner = true;
      // }
      // else {
      //   this.onlyPartner = false;
      // }

      this.onlyPartner = this.includesPartner;
      localStorage.setItem("onlyPartner", this.onlyPartner);
      console.log('Users with only the Partner role:', this.onlyPartner);

      if (this.onlyPartner) {
        this.Username = localStorage.getItem("UserName");
        this.partnerNames = this.bulkbatchlist?.map(item => item.PartnerName);
        this.samePartner = this.partnerNames.includes(this.Username);
        localStorage.setItem("samePartner", JSON.stringify(this.samePartner));
      } else {
        this.samePartner = false;
        localStorage.setItem("samePartner", JSON.stringify(this.samePartner));
      }
    });

  }


  //getbatchlistby Id
  async getbatchlistbyid(batchid) {
    this.isNewFlow = this.bulkbatchlist?.find(batchlist => batchlist.BatchId === batchid)?.IsNewFlow ?? false;
    if (this.isNewFlow) {
      this.router.navigate(["/editcreateclientbulkletter", batchid]);
    } else {
      this.router.navigate(["/editbulkletter", batchid]);
    }
  }

  getbatchDetailsbyid(batchid) {
    if (this.onlyPartner) {
      this.partnersList(batchid);
    }
    else {
      this.batchDetailsbyid(batchid);
    }
  }

  async batchDetailsbyid(batchid) {
    this.SpinnerService.show();
    var username = localStorage.getItem("UserName");
    console.log(this.partnerOfficeList, 'partnerOfficeList');
    if (!this.onlyPartner) {
      await this.CreatebulkletterService.getbatchDetailsbyid(batchid, username)?.then((res) => {
        console.log(res);
        if (res.StatusCode == 200) {
          this.getbatchlistbyid(batchid);
          this.SpinnerService.hide();
        } else if (res.StatusCode == 304) {
          this.SpinnerService.hide();
          this.errorMessage = res.Data + " is currently using this batch, please try again later";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        } else if (res.StatusCode == 401 || res.StatusCode == 400 || res.status == 400 || res.status == 401) {
          this.SpinnerService.hide();
        }
        else if (res.StatusCode == 500) {
          this.SpinnerService.hide();
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
        else {
          this.SpinnerService.hide();
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
      });
    }
    else if (this.onlyPartner) {
      this.isPartner = this.partnerOfficeList.some(partner => partner.PartnerName === username);
      if (this.isPartner) {
        await this.CreatebulkletterService.getbatchDetailsbyid(batchid, username)?.then((res) => {
          console.log(res);
          if (res.StatusCode == 200) {
            this.getbatchlistbyid(batchid);
            this.SpinnerService.hide();
          } else if (res.StatusCode == 304) {
            this.SpinnerService.hide();
            this.errorMessage = res.Data + " is currently using this batch, please try again later";
            this.displayErrorModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
          } else if (res.StatusCode == 401 || res.StatusCode == 400 || res.status == 400 || res.status == 401) {
            this.SpinnerService.hide();
          }
          else if (res.StatusCode == 500) {
            this.SpinnerService.hide();
            this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
            this.displayErrorModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
          }
          else {
            this.SpinnerService.hide();
            this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
            this.displayErrorModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
          }
        });
      }
      else {
        this.SpinnerService.hide();
        this.errorMessage = "You don't have access to edit this Batch ID - " + batchid + " as you are not involved";
        this.displayErrorModal = 'block';
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
        return false;
      }
    }

  }

  //#region popup modal - error, success, warning

  closeWarningModal() {
    this.displayWarningModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  closeErrorModal() {
    this.displayErrorModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  closeSuccessModal() {
    this.displaySuccessModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }
  closeCreateLetterModal() {
    this.isClientsActive = false;
    this.isTemplateActive = false;
    this.displayCreateletter = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  //#endregion


  //deletebatch
  deleteBatchModal(batchId: number) {
    this.deleteBatchId = batchId;
    this.displayDeleteModal = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
  }

  onCloseDeleteModal() {
    this.displayDeleteModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  async deleteBatchById() {
    this.displayDeleteModal = "none";
    var deleteData = {
      "BatchId": this.deleteBatchId,
      "DeletedBy": this.Username,
      "IsDeleted": true,
      "DeletedOn": "2023-09-08T14:54:53.451Z"
    }
    this.SpinnerService.show();
    await this.CreatebulkletterService.deleteBatchById(deleteData)?.then((res) => {
      this.SpinnerService.hide();
      if (res.StatusCode == 200) {
        if (res.Data == true) {
          this.successMessage = "Engagement letter batch deleted successfully"
          this.displaySuccessModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
          this.getbatchlist();
        }
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400 || res.status == 400 || res.status == 401) {
        this.SpinnerService.hide();
      }
      else if (res.StatusCode == 500) {
        this.SpinnerService.hide();
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else {
        this.SpinnerService.hide();
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }

    });

  }


  public addDiv() {
    this.trustedHtml = this.s.bypassSecurityTrustHtml("<div id='demo' class='modal-backdrop show'></div>")
  }

  errorlogconsole() {
    var currPage = this;
    var consoleLogCapture = [];
    let defaultLog = console.log.bind(console);
    let defaultError = console.error.bind(console);

    console.error = function () {
      if (currPage.errorValue?.length == 0) {
        //defaultError.apply(console, arguments);
        consoleLogCapture.push({ 'type': 'error', 'value': Array.from(arguments) });
        var uniqueConsoleError = removeDuplicates(consoleLogCapture, "value");
        currPage.errorValue = uniqueConsoleError?.map(x => (x.value[1].toString()));
        for (var i = 0; i < currPage.errorValue?.length; i++) {
          if (currPage.errorValue[i].includes("Error: Uncaught (in promise): Error: Unknown source type.")) {
            currPage.errorValue.splice(i, 1)
          }
        }
        for (var j = 0; j < currPage.errorValue?.length; j++) {
          if (currPage.errorValue[j].includes("TypeError: Cannot read properties of undefined (reading 'div')")) {
            currPage.errorValue.splice(j, 1)
          }
        }
        for (var k = 0; k < currPage.errorValue?.length; k++) {
          if (currPage.errorValue[k].includes("Error: unsafe value used in a resource URL context")) {
            currPage.errorValue.splice(k, 1)
          }
        }
        var JSONcaptureLogError = JSON.stringify(currPage.errorValue);;
        if (currPage.errorLogCount != 1 && currPage.errorValue?.length != 0) {
          currPage.errorLogForSubmit(JSONcaptureLogError);
        }
      }
    };

  }

  errorLogForSubmit(JSONcaptureLogError) {
    console.log(JSONcaptureLogError, 'JSONcaptureLogError_errorLogForSubmit');
    localStorage.setItem("captureLogError", JSONcaptureLogError);
    this.displayConsoleLogerror = 'block';
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
    this.SpinnerService.hide();
  }
  ondefaultLogError() {
    this.displayConsoleLogerror = 'none';
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }
  async viewPartnerOfficeList(batchId: any) {
    this.SpinnerService.show();
    await this.CreatebulkletterService.getPartnerOfficeByBatchId(batchId)?.then((res) => {
      if (res.StatusCode == 200) {
        this.partnerOfficeList = res.Data;
        // Use the map function to add BatchId to each object
        this.partnerOfficeList = this.partnerOfficeList?.map(item => ({
          ...item, // Spread the existing properties
          BatchId: batchId, // Add the BatchId property
        }));
        this.SpinnerService.hide();
        this.displayPartnerOfficeList = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400 || res.status == 400 || res.status == 401) {
        this.SpinnerService.hide();
      }
      else if (res.StatusCode == 500) {
        this.SpinnerService.hide();
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else {
        this.SpinnerService.hide();
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }

  partnersList(batchId: any) {
    this.SpinnerService.show();
    this.CreatebulkletterService.getPartnerOfficeByBatchId(batchId)?.then((res) => {
      if (res.StatusCode == 200) {
        this.partnerOfficeList = res.Data;
        this.partnerOfficeList = this.partnerOfficeList?.map(item => ({
          ...item,
          BatchId: batchId,
        }));
        this.batchDetailsbyid(batchId);
        // var username = localStorage.getItem("UserName");
        // this.isPartner = this.partnerOfficeList.some(partner => partner.PartnerName === username);
        this.SpinnerService.hide();
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400 || res.status == 400 || res.status == 401) {
        this.SpinnerService.hide();
      }
      else if (res.StatusCode == 500) {
        this.SpinnerService.hide();
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else {
        this.SpinnerService.hide();
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }

  closePartnerOfficeList() {
    this.displayPartnerOfficeList = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }
}

//javascript function

function removeDuplicates(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}


