import { Injectable } from '@angular/core';
import { CreatebulkletterService } from './createbulkletter.service';

@Injectable({
  providedIn: 'root'
})
export class AddattachmentService {
  //IndividualAddAttachment & bulkaddattachment
  batchIDBulkLtr: any;
  IndividualAttachmentClientId: any
  IndividualAttachmentBulkletterId: any
  bulkAttachmentBulkletterId: any
  attachmentBulkLetterIdArray: any;
  successAttachmentClientId: any;
  constructor(private createbulkletterService: CreatebulkletterService) { }

  async addAttachment(bulklLetterId: any, batchID: any, attachmentType: any) {
    this.batchIDBulkLtr = batchID;
    if (attachmentType == 'individualaddattachment') {
      this.IndividualAttachmentBulkletterId = [bulklLetterId];
      this.attachmentBulkLetterIdArray = [bulklLetterId];
      if (this.IndividualAttachmentBulkletterId) {
        var AttachmentData = {
          "BulkLetterId": this.IndividualAttachmentBulkletterId,
          "BatchId": this.batchIDBulkLtr,
        }
      }
    } else {
      this.bulkAttachmentBulkletterId = bulklLetterId;
      this.attachmentBulkLetterIdArray = bulklLetterId;
      if (this.bulkAttachmentBulkletterId) {
        var AttachmentData = {
          "BulkLetterId": this.bulkAttachmentBulkletterId,
          "BatchId": this.batchIDBulkLtr
        }
      }
    }

    return this.createbulkletterService.getAddAttachmentById(AttachmentData);
  }
  //upload attachment
  async submitUploadedFile(uploadedFiles: any, selectedBulkletterId: any, clientData: any, templateID: any, Username: any, batchID: any) {
    var uploadJSON = JSON.stringify(uploadedFiles);
    this.batchIDBulkLtr = batchID;
    if (selectedBulkletterId?.length === clientData?.length) {
      this.attachmentBulkLetterIdArray = [];
    }
    const attachmentModel = {
      "BatchAttachmentJsonId": 0,
      "BatchId": this.batchIDBulkLtr,
      "BulkLetterId": this.attachmentBulkLetterIdArray,
      "TemplateId": templateID,
      "AttachmentJson": uploadJSON,
      "AttachmentUrl": "",
      "VersionNumber": 0,
      "CreatedOn": "2023-01-01T00:00:00Z",
      "CreatedBy": "",
      "ModifiedOn": "2023-01-01T00:00:00Z",
      "ModifiedBy": Username,
      "DeletedOn": "2023-01-01T00:00:00Z",
      "DeletedBy": "",
      "IsDeleted": false
    }

    return this.createbulkletterService.updateAttachmentsBulkClientLetter(attachmentModel);
  }
  async addAttachmentnewscreen(bulklLetterId: any, batchID: any, attachmentType: any) {
    this.batchIDBulkLtr = batchID;
    if (attachmentType == 'individualaddattachment') {
      this.IndividualAttachmentBulkletterId = [bulklLetterId];
      this.attachmentBulkLetterIdArray = [bulklLetterId];
      if (this.IndividualAttachmentBulkletterId) {
        var AttachmentData = {
          "BulkLetterId": this.IndividualAttachmentBulkletterId,
          "BatchId": this.batchIDBulkLtr,
        }
      }
      return this.createbulkletterService.newbulkAttachmentbyIdUrl(AttachmentData);

    } else {
      this.bulkAttachmentBulkletterId = [];
      this.attachmentBulkLetterIdArray = bulklLetterId;
      if (this.bulkAttachmentBulkletterId) {
        var AttachmentData = {
          "BulkLetterId": this.bulkAttachmentBulkletterId,
          "BatchId": this.batchIDBulkLtr
        }
      }
      return this.createbulkletterService.newbulkbatchAttachmentbyIdUrl(AttachmentData);

    }

  }
  async newflowsubmitUploadedFile(uploadedFiles: any, selectedBulkletterId: any,  templateID: any, Username: any, batchID: any) {
    var uploadJSON = JSON.stringify(uploadedFiles);
    this.batchIDBulkLtr = batchID;
    this.attachmentBulkLetterIdArray;
    selectedBulkletterId='';
    if (selectedBulkletterId === "" && (typeof this.attachmentBulkLetterIdArray === "undefined" || this.attachmentBulkLetterIdArray.length === 0)) {
      this.attachmentBulkLetterIdArray = [];
      const attachmentModel = {
        "BatchAttachmentJsonId": 0,
        "BatchId": this.batchIDBulkLtr,
        "BulkLetterId": this.attachmentBulkLetterIdArray,
        "TemplateId": templateID,
        "AttachmentJson": uploadJSON,
        "AttachmentUrl": "",
        "VersionNumber": 0,
        "CreatedOn": "2023-01-01T00:00:00Z",
        "CreatedBy": "",
        "ModifiedOn": "2023-01-01T00:00:00Z",
        "ModifiedBy": Username,
        "DeletedOn": "2023-01-01T00:00:00Z",
        "DeletedBy": "",
        "IsDeleted": false
      }
  
      return this.createbulkletterService.newupdateAttachmentsBatchClientLetter(attachmentModel);
    }else{
      const attachmentModel = {
        "BatchAttachmentJsonId": 0,
        "BatchId": this.batchIDBulkLtr,
        "BulkLetterId": this.attachmentBulkLetterIdArray,
        "TemplateId": templateID,
        "AttachmentJson": uploadJSON,
        "AttachmentUrl": "",
        "VersionNumber": 0,
        "CreatedOn": "2023-01-01T00:00:00Z",
        "CreatedBy": "",
        "ModifiedOn": "2023-01-01T00:00:00Z",
        "ModifiedBy": Username,
        "DeletedOn": "2023-01-01T00:00:00Z",
        "DeletedBy": "",
        "IsDeleted": false
      }
  
      return this.createbulkletterService.newupdateAttachmentsBulkClientLetter(attachmentModel);
    }
  
  }
}
