import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  public NameError = 'Value is required';

  public firstNameCharactersOnly = 'Field Name should be not more than 50 Characters';
  public firstMinCharactersOnly = 'Field Name should be at least 3 Characters';
  public TypeError = "Value is required";
  public toastError = "Value is required";
  public DisplayNameError = "Value is required";
  public displayNameCharactersOnly = 'Display Name should be not more than 50 Characters';
  public displayMinCharactersOnly = 'Display Name should be at least 3 Characters';
  public ChangeNotesError = "Value is required";
  public changenotesCharactersOnly = 'Change Notes should be not more than 255 Characters';
  public changenotesminCharactersOnly = 'Change Notes should be at least 5 Characters';
  public blockNameError = 'Value is required';
  public blockNameCharactersOnly = 'Block Name should be not more than 100 Characters';
  public blockMinCharactersOnly = 'Block Name should be at least 5 Characters';
  public blockdescriptionError = 'Value is required';
  public blockdescriptionCharactersOnly = 'Block Description should be not more than 255 Characters';
  public blockdescriptionMinCharactersOnly = 'Block Description should be at least 5 Characters';
  public contentError = 'Value is required';

  public responseMinCharactersOnly = 'Response Option should be at least 2 Characters';
  public responseMaxCharactersOnly = 'Response Option should be not more than 50 Characters';
  public questionCharactersOnly = "Question Text Does not allow Special Characters";
  public questionMinCharactersOnly = "Question Text should be at least 3 Characters";
  public questionMaxCharactersOnly = "Question Text should be not more than 255 Characters";
  public changenotesquesCharactersOnly = 'Change Notes should be not more than 255 Characters';
  public changenotesminquesCharactersOnly = 'Change Notes should be at least 5 Characters';


  /*Template*/
  public templateError = 'Value is required';
  public templateCharactersOnly = 'Template Name should be not more than 100 Characters';
  public templateMinCharactersOnly = 'Template Name should be at least 5 Characters';

  public templatedescriptionError = 'Value is required';
  public templatedescriptionCharactersOnly = 'Template Description should be not more than 255 Characters';
  public templatedescriptionMinCharactersOnly = 'Template Description should be at least 5 Characters';

  /*PageSetting*/
  public pagesettingError = 'Value is required';
  public maxPaperMarginHeader = 'Paper Margin Header should be Min 0.1 to Max 2.0 decimal';
  public minPaperMarginHeader = 'Paper Margin Header should be at least 0.1 decimal';

  public maxPaperMarginTop = 'Paper Margin Top should be Min 0.1 to Max 2.0 decimal';
  public minPaperMarginTop = 'Paper Margin Top should be at least 0.1 decimal';

  public maxPaperMarginLeft = 'Paper Margin Left should be Min 0.1 to Max 2.0 decimal';
  public minPaperMarginLeft = 'Paper Margin Left should be at least 0.1 decimal';

  public maxPaperMarginBottom = 'Paper Margin Bottom should be Min 0.1 to Max 2.0 decimal';
  public minPaperMarginBottom = 'Paper Margin Bottom should be at least 0.1 decimal';

  public maxPaperMarginRight = 'Paper Margin Right should be Min 0.1 to Max 2.0 decimal';
  public minPaperMarginRight = 'Paper Margin Right should be at least 0.1 decimal'

  public maxPaperFooterEdge = 'Paper Margin Footer should be Min 0.1 to Max 2.0 decimal';
  public minPaperFooterEdge = 'Paper Margin Footer should be at least 0.1 decimal'
  public cannotContainSpace = 'Space not allowed'

  public pagesetting60CharactersOnly = 'Page Setting Name should be not more than 60 Characters';
  public pagesettingCharactersOnly = 'Page Setting Name should be not more than 50 Characters';
  public pagesettingMinCharactersOnly = 'Page Setting Name should be at least 3 Characters';

  /*Approval*/
  public CommentsError = 'Value is required';
  public CommentsmaxCharactersOnly = 'Comments should be not more than 255 Characters';
  public CommentsminCharactersOnly = 'Comments should be at least 5 Characters';

  public filtermaxCharactersOnly = 'Filter Name should be not more than 20 Characters';
  public filterminquesCharactersOnly = 'Filter Name should be at least 1 Characters';

  /*watermark */
  public watermarkCharactersOnly = "Watermark Name Does not allow Special Characters";
  public watermarkMinCharactersOnly = "Watermark Name should be at least 3 Characters";
  public watermarkMaxCharactersOnly = "Watermark Name should be not more than 30 Characters";
  /*letterhead*/
  public LetterHeadCharactersOnly = "Letterhead Name Does not allow Special Characters";
  public LetterHeadMinCharactersOnly = "Letterhead Name should be at least 3 Characters";
  public letterheadMaxCharactersOnly = "Letterhead Name should be not more than 60 Characters";

  /*Signing*/
  public contactPerson1MinCharactersOnly = 'Contact Name should be at least 3 Characters';
  public contact2Person1MinCharactersOnly = 'Contact2 Name should be at least 3 Characters';
  public emailtoastError = "Signatory E-mail ID is not valid";
  public email2toastError = "Spouse E-mail ID is not valid";

  //  Resend E-Signing
  public signatoryNameMinCharOnly = "Signatory Name should be at least 2 Characters";
  public signatoryNameMaxCharOnly = "Signatory Name should be not more than 100 Characters";
  public spouseNameMinCharOnly = "Spouse Name should be at least 2 Characters";
  public spouseNameMaxCharOnly = "Spouse Name should be not more than 100 Characters";

  //  Client ID
  public clientIDError = "Client ID is not valid";

  //EngagementLetter Upload

  public minValue="Minimum value is 1"
  public maxValue="Maximum value is 250"
}
