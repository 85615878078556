import { Component, OnInit, ElementRef, ViewChild, Renderer2, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as Msal from "msal";
import { Router, NavigationEnd } from '@angular/router';
import { AppConfig, configData } from 'src/configData';
// const msalConfig = {
//   auth: {
//     clientId: "8390d001-8902-4612-bec7-6cc2bfed0340",
//     authority: "https://login.microsoftonline.com/4c7f4711-28b1-4fbb-8dff-fd40bfd51963",
//     //redirectUri: "http://localhost:4200/",
//   }
// };

const msalConfig = configData.msalConfig;
const clientId = msalConfig.auth.clientId;
const authId = msalConfig.auth.authority;

let clientApplication = new Msal.UserAgentApplication(msalConfig);
let accessToken;
let AccountId;
let GroupNamess;
let GroupIds;

clientApplication.handleRedirectCallback(authRedirectCallBack);

function authRedirectCallBack(error, response) {
  let endpoint = "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/";

  if (error) {
    console.log(error);
  }
  else {
    if (response.tokenType === "id_token") {
      console.log("id_token acquired at: " + new Date().toString());

      if (clientApplication.getAccount()) {
        console.log(clientApplication.getAccount())
        let accountid = response.account.accountIdentifier;
        let username = response.account.name;
        let token = response.idToken.rawIdToken;
        localStorage.setItem("AccountId", accountid);
        localStorage.setItem("UserName", username);
        localStorage.setItem("AccessToken", token);
      }

    } else if (response.tokenType === "access_token") {
      console.log("access_token acquired at: " + new Date().toString());
      accessToken = response.accessToken;

      try {
        //this.callMSGraphForGroups(endpoint, response.accessToken, this.getGroupNames)
        //this.callMSGraph("https://graph.microsoft.com/beta/me", accessToken);
      } catch (err) {
        console.log(err)
      } finally {
        // profileButton.classList.add('d-none');
        // mailButton.classList.remove('d-none');
      }
    } else {
      console.log("token type is:" + response.tokenType);
    }
  }
}

const loginRequest = {
  scopes: ["User.Read", "Group.Read.All"]

};
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  toggle: boolean = true;
  AppConfigdata: any;
  displayLogout = "none";
  trustedHtml: SafeHtml;
  isSidebarOpen = false;
  msalConfig;
  fieldval: boolean = false;
  letterdashboard: boolean = false;
  blockval: boolean = false;
  templateval: boolean = false;
  accessrightval: boolean = false;
  Username: any;

  //Roles and action variable
  Roles: any;
  DashboardRoles: any;
  isLetterGen: boolean = false;
  isFields: boolean = true;
  isBlocks: boolean = true;
  isTemplates: boolean = true;
  isbulkletter: boolean = true;
  isQuestions: boolean = true;
  displayNoRole = "none";
  screenpermissions: any;
  ScreenNames: any;
  newRoles: any;
  ADGroupNames: any;
  involveRoles: any;
  isNewRoles: boolean;
  bulkletterval: boolean;
  isquestionsval: boolean;
  isDisableRouterLink: boolean = false;
  currentUrl: any;
  isAdminRoles;

  @Input() screenNameValue: string;
  @Output() addClassEvent = new EventEmitter<string>();
  @ViewChild("sidebar", { static: false }) sidebar: ElementRef | undefined;
  @ViewChild("maintoggledrop", { static: false }) maintoggledrop: ElementRef | undefined;
  @ViewChild("headertoggle", { static: false }) headertoggle: ElementRef | undefined;

  constructor(private renderer: Renderer2, public s: DomSanitizer, private router: Router) {
    if (this.router.url == "/fields") {
      this.fieldval = true;
    }
    if (this.router.url == "/engagementletterdashboard") {
      this.letterdashboard = true;
    }
    if (this.router.url == "/blocks") {
      this.blockval = true;
    }
    if (this.router.url == "/templates") {
      this.templateval = true;
    }
    if (this.router.url == "/accessright") {
      this.accessrightval = true;
    }
    if (this.router.url == "/bulkletterdashboard") {
      this.bulkletterval = true;
    }
    if (this.router.url == "/createbulkletter") {
      this.bulkletterval = true;
      this.letterdashboard = false;
    }
    if (this.router.url == "/questions") {
      this.isquestionsval = true;
    }
  }

  ngOnInit(): void {
    //restrict Sidebar
    if (this.router.url == "/createbulkletter") {
      this.isFields = false;
      this.isLetterGen = false;
      this.isBlocks = false;
      this.isTemplates = false;
    }
    this.disableRouterLink();
    this.GetConfigData();
    this.GetScreenAccessPermission();
    this.msalConfig = msalConfig;
    this.Username = localStorage.getItem("UserName");
    this.Roles = localStorage.getItem("roles");
    this.involveRoles = localStorage.getItem("NewRoles");
    this.isNewRoles = this.involveRoles.includes("IT Helpdesk") || this.involveRoles.includes("Special User")


    var accountId = localStorage.getItem("AccountId");
    if ((this.router.url == "/fields" && this.isNewRoles == false)
      || (this.router.url == "/blocks" && this.isNewRoles == false)
      || (this.router.url == "/templates" && this.isNewRoles == false)
      || (this.router.url == "/templatecreate" && this.isNewRoles == false)) {
      this.router.navigate(["/engagementletterdashboard"]);
    }
    const rolesArray = JSON.parse(this.involveRoles);

    this.isAdminRoles = rolesArray.includes("Admin") || rolesArray.includes("Signer") ||rolesArray.includes("Special User");
    if (accountId == "b8bac0a0-3e6f-4ff1-8321-92cb221ddf98") {
      this.isAdminRoles = true;
    }
    if ((this.router.url == "/bulkletterdashboard" && this.isAdminRoles == false) ||
      (this.router.url == "/createbulkletter" && this.isAdminRoles == false)
    ) {
      this.router.navigate(["/engagementletterdashboard"]);
    }



  }

  logoutshow() {
    this.displayLogout = "block";
    if (this.displayNoRole = "block") {
      this.displayNoRole = "none";
    }
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
  }
  nologout() {
    this.displayLogout = "none";
    if (this.DashboardRoles.trim() == "" || this.DashboardRoles == undefined || this.DashboardRoles == null) {
      this.displayNoRole = "block";
    }
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }
  logOut() {
    const myMSALObj = new Msal.UserAgentApplication(this.msalConfig);
    myMSALObj.logout();
    localStorage.removeItem("ScreenAcessPermission");
    localStorage.removeItem("UserName");
    localStorage.removeItem("roles");
    localStorage.setItem("Logouttrigered", "true");
    localStorage.removeItem("ADGroupNames");
    localStorage.removeItem("ScreenNames");
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("samePartner");
    localStorage.removeItem("onlyPartner");
  }
  toogleclick() {

    if (document.getElementById("sidebar").classList.contains("collapsed")) {
      const content = 'sidebar-open'; // Replace with the actual content you want to emit
      this.addClassEvent.emit(content);
      this.renderer.removeClass(this.sidebar.nativeElement, 'collapsed');
      this.renderer.removeClass(this.headertoggle.nativeElement, 'collapsed');
    }
    else {
      const content = 'sidebar-close'; // Replace with the actual content you want to emit
      this.addClassEvent.emit(content);
      this.renderer.addClass(this.sidebar.nativeElement, 'collapsed');
      this.renderer.addClass(this.headertoggle.nativeElement, 'collapsed');
    }

  }
  public addDiv() {
    this.trustedHtml = this.s.bypassSecurityTrustHtml("<div id='demo' class='modal-backdrop show'></div>")
  }
  disableRouterLink() {
    this.currentUrl = this.router.url;
    if (this.currentUrl.includes('/templatecreate') || this.currentUrl.includes('/edittemplate') || this.currentUrl.includes('/createbulkletter') || this.currentUrl.includes('/editbulkletter') || this.currentUrl.includes('/createclientbulkletter') || this.currentUrl.includes('/editcreateclientbulkletter')) {
      this.isDisableRouterLink = true;
    } else {
      this.isDisableRouterLink = false;
    }

  }

  GetConfigData() {
    this.AppConfigdata = AppConfig.CommitTag;
  }

  GetScreenAccessPermission() {
    this.Roles = localStorage.getItem('roles');
    this.ADGroupNames = localStorage.getItem("ADGroupNames");
    this.Username = localStorage.getItem("UserName");
    let roles = localStorage.getItem("NewRoles");
    this.newRoles = JSON.parse(roles);
    var SN = localStorage.getItem('ScreenNames');
    this.ScreenNames = JSON.parse(SN);
    console.log(this.ScreenNames, 'this.ScreenNames')
    this.DashboardRoles = localStorage.getItem("IniRoles").replace(/,/g, ", ");
    console.log(this.DashboardRoles, 'this.DashboardRoles');
    if (this.ScreenNames) {
      if (this.ScreenNames.includes("Fields")) {
        this.isFields = false;
      }
      if (this.ScreenNames.includes("Blocks")) {
        this.isBlocks = false;
      }
      if (this.ScreenNames.includes("Templates")) {
        this.isTemplates = false;
      }
      if (this.ScreenNames.includes("Bulk Letter Generation")) {
        this.isbulkletter = false;
      }
      if (this.ScreenNames.includes("Questions")) {
        this.isQuestions = false;
      }
    }

    if (this.DashboardRoles.trim() == "" || this.DashboardRoles == undefined || this.DashboardRoles == null) {
      this.displayNoRole = "block";
      this.isLetterGen = true;
    }

  }

}
