import { Injectable } from '@angular/core';
import { ServicelinksService } from '../shared/servicelinks.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  getheadersSub: Subscription;
  getHeaders: any;
  postHeaders: any;
  postheadersSub: Subscription;

  constructor(private servicelinks: ServicelinksService, private http: HttpClient, public dataService: DataService) {
    this.getheadersSub = this.dataService.getHeadService.subscribe(message => this.getHeaders = message);
    this.postheadersSub = this.dataService.postHeaderService.subscribe(message => this.postHeaders = message);
  }


  async postSaveQuestionData(data: any): Promise<any> {
    //post
    let url = this.servicelinks.saveQuestionsUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        console.log(res);
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        }
        else {
          resolve(err);
        }
      });
    });
  }

  // questionlistUrl

  async listquestiondata(Data: any): Promise<any> {
    let url = this.servicelinks.questionlistUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              localStorage.removeItem("RefreshToken");
              resolve(response);
            }, error => {
              resolve(error);
            })
          }, 5000);
        }
        resolve(error);
      });
    }
    );
  }

  async getQuestionId(id: any): Promise<any> {
    let url = this.servicelinks.questionviewUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${id}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(`${url}=${id}`, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }
  async listquestioniddata(Data: any): Promise<any> {
    let url = this.servicelinks.questionidUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              localStorage.removeItem("RefreshToken");
              resolve(response);
            }, error => {
              resolve(error);
            })
          }, 5000);
        }
        resolve(error);
      });
    }
    );
  }

  // updateUrl

  async putUpdateQuestionData(data: any): Promise<any> {
    //put
    let url = this.servicelinks.questionupdateUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  // DeleteQuestion

  async deleteQuestionData(data: any): Promise<any> {
    //delete
    let url = this.servicelinks.deleteQuestionUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  // getAllStatus

  async getAllStatus(): Promise<any> {
    //get
    let url = this.servicelinks.fieldstatusUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }
  //getactive questions
  async getquestiondata(): Promise<any> {
    let url = this.servicelinks.getactivequestions;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }


}
