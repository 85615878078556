import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-accessrightssetting',
  templateUrl: './accessrightssetting.component.html',
  styleUrls: ['./accessrightssetting.component.css']
})
export class AccessrightssettingComponent implements OnInit {
  ScreenName: string = 'Access Rights';
  groupName = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Signer' },
    { id: 3, name: 'IT Helpdesk' }
  ];
  selectedCategories: any[] = [];
  checkBoxName = [];
  isSelected: boolean = false;
  //accessForm: FormGroup

  trustedHtml: SafeHtml;
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    // this.accessForm = this.fb.group({
    //   GroupName: [''],
    //   Feature: this.fb.array([])
    // });
    // this.checkBoxName.forEach(value => {
    //   this.Features.push(this.fb.group({
    //     isActive: value.isChecked,
    //     name: value.Name
    //   }));
    // });
  }

  //  get Features() {
  // return this.accessForm.get('Channel') as FormArray;
  // }

  selectValue(event) {
    console.log(event);
    this.isSelected = true;
    if (event.name == "Admin") {
      this.checkBoxName = [{ id: 1, name: "Manage Fields", isChecked: false },
      { id: 2, name: "Manage Blocks", isChecked: false },
      { id: 3, name: "Manage Templates", isChecked: false },
      { id: 4, name: "Dashboard", isChecked: true },
      { id: 5, name: "Bluk Letter Generation", isChecked: true },
      { id: 6, name: "History Log", isChecked: true },
      { id: 7, name: "Delete Letter", isChecked: true },
      { id: 8, name: "Refresh Status", isChecked: true },
      { id: 9, name: "Upload Letter", isChecked: true },
      { id: 10, name: "Cancel Signing", isChecked: true },
      { id: 11, name: "Resend Letter", isChecked: true },
      { id: 12, name: "Edit Fields", isChecked: true }
      ];
    } else if (event.name == "Signer") {
      this.checkBoxName = [{ id: 1, name: "Manage Fields", isChecked: false },
      { id: 2, name: "Manage Blocks", isChecked: false },
      { id: 3, name: "Manage Templates", isChecked: false },
      { id: 4, name: "Dashboard", isChecked: true },
      { id: 5, name: "Bluk Letter Generation", isChecked: false },
      { id: 6, name: "History Log", isChecked: true },
      { id: 7, name: "Delete Letter", isChecked: false },
      { id: 8, name: "Refresh Status", isChecked: false },
      { id: 9, name: "Upload Letter", isChecked: false },
      { id: 10, name: "Cancel Signing", isChecked: false },
      { id: 11, name: "Resend Letter", isChecked: false },
      { id: 12, name: "Edit Fields", isChecked: false }
      ];
    } else if (event.name == "IT Helpdesk") {
      this.checkBoxName = [{ id: 1, name: "Manage Fields", isChecked: true },
      { id: 2, name: "Manage Blocks", isChecked: true },
      { id: 3, name: "Manage Templates", isChecked: true },
      { id: 4, name: "Dashboard", isChecked: true },
      { id: 5, name: "Bluk Letter Generation", isChecked: false },
      { id: 6, name: "History Log", isChecked: true },
      { id: 7, name: "Delete Letter", isChecked: false },
      { id: 8, name: "Refresh Status", isChecked: false },
      { id: 9, name: "Upload Letter", isChecked: false },
      { id: 10, name: "Cancel Signing", isChecked: false },
      { id: 11, name: "Resend Letter", isChecked: false },
      { id: 12, name: "Edit Fields", isChecked: false }
      ];
    }
    this.selectedCategories = this.checkBoxName.filter((x) => x.isChecked == true);
  }

  accessrightsSubmit() {
    console.log(this.checkBoxName);
  }
  featureChecked(event) {
    console.log(event);
    console.log(this.checkBoxName);
  }
}
