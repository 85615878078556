<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>

        <main class="main-content p-2">
            <section class="container-fluid">
                <ngx-spinner size="medium" [type]="typeSelected">
                    <p style="color: white">{{percentageMessage}}</p>
                </ngx-spinner>
                <div>
                    <form id="bulkletterform" [formGroup]="BulkletterForm">
                        <div class="bg-white px-4 py-3 rounded mb-2 mb-md-4">
                            <div class="accordion accordion-flush" id="basic-details">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button id="collapseButton" #collapseButton
                                            class="accordion-button collapsed p-0 fs-6 fw-semibold highlight shadow-none bg-white collapsed"
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOneBasicDetail" (click)="collapseFilter()"
                                            aria-expanded="false" aria-controls="flush-collapseOneBasicDetail">
                                            Basic Details
                                        </button>
                                    </h2>
                                    <div id="collapseOne" #collapseOne class="accordion-collapse collapse show"
                                        data-bs-parent="#basic-details">
                                        <div class="accordion-body">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-1">
                                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Batch ID
                                                        </label>
                                                        <input type="text" readonly formControlName="BatchID"
                                                            [(ngModel)]="count" class="form-control"
                                                            id="exampleFormControlInput1" placeholder="">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-2">
                                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Admin
                                                        </label>
                                                        <input type="text" readonly formControlName="Admin"
                                                            [(ngModel)]="this.Username" class="form-control"
                                                            id="exampleFormControlInput1" placeholder="Admin">
                                                    </div>
                                                </div>

                                                <div class="col-12 col-md-6 col-lg-3">
                                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Partner
                                                            (PIC) - (At
                                                            least 1)
                                                            <span class="err-color">*</span></label>

                                                        <!-- <ng-select [items]="categories" bindLabel="name" placeholder="Select Category"
                                                appendTo="body" [multiple]="true" [(ngModel)]="selected">
                                            </ng-select> -->
                                                        <ng-multiselect-dropdown name="Partner" class="myPartner"
                                                            formControlName="PartnerName"
                                                            (ngModelChange)='onChangePartner()'
                                                            [placeholder]="'Select Partner'" [(ngModel)]="selectedItems"
                                                            [settings]="dropdownSettings" [data]="partnerStatusValue">


                                                        </ng-multiselect-dropdown>
                                                    </div>
                                                </div>


                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Template Name
                                                            <span class="err-color">*</span></label>
                                                        <ng-select [(ngModel)]="model.selectedtemplate"
                                                            [items]="templatedynamicnames"
                                                            [readonly]="isReadtemplatesName"
                                                            formControlName="Templatename" (ngModelChange)='onChange()'
                                                            bindLabel="name" bindValue="name" [searchable]="true"
                                                            [clearable]="false" placeholder="Select Template">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-2">
                                                    <div class="mb-2 mb-md-3 mb-lg-3">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Return Type Code
                                                            <span class="err-color">*</span></label>
                                                        <ng-multiselect-dropdown name="ReturnType" class="myPartner"
                                                            formControlName="ReturnType"
                                                            (ngModelChange)='onChangeReturnType()'
                                                            [placeholder]="'Select Code'"
                                                            [(ngModel)]="selectedcodeItems"
                                                            [settings]="dropdownReturnTypeSettings"
                                                            [data]="ReturntypeValue">
                                                        </ng-multiselect-dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="mb-2 mb-md-3 mb-lg-4 px-lg-0">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Select Clients
                                                        </label>
                                                        <div class="mt-2 ms-lg-4">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="radio"
                                                                    name="searchOption" id="search-client-radio"
                                                                    value="search-client"
                                                                    [checked]="specificClientIdRadioButton"
                                                                    [(ngModel)]="searchOption"
                                                                    (change)="onSearchOptionChange($event)"
                                                                    #clientNameRadio>
                                                                <label class="form-check-label"
                                                                    for="search-client-radio">Select certain
                                                                    clients</label>
                                                            </div>
                                                            <div class="form-check form-check-inline ms-lg-3">
                                                                <input class="form-check-input" type="radio"
                                                                    name="searchOption"
                                                                    [checked]="allclientIdRadioButton"
                                                                    id="all-client-radio" value="all-client"
                                                                    [(ngModel)]="searchOption"
                                                                    (change)="onSearchOptionChange($event)" #emailRadio>
                                                                <label class="form-check-label"
                                                                    for="all-client-radio">Select all clients (By
                                                                    Default)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="mb-2 mb-md-3 mb-lg-3">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Client ID/Name
                                                            <span class="err-color">*</span></label>
                                                        <!-- <ng-multiselect-dropdown [disabled]="allClientIdButton"
                                                                name="ClientIdName" formControlName="ClientIdName"
                                                                (ngModelChange)="onChangeClientIdName()"
                                                                [placeholder]="'Select Clients'"
                                                                [settings]="dropdownSettingsClient"
                                                                [(ngModel)]="selectedClientItems"
                                                                [data]="ClientIdNameList">
                                                            </ng-multiselect-dropdown> -->
                                                        <p-multiSelect #multiSelectRef class="table-multiselect"
                                                            [disabled]="allClientIdButton"
                                                            formControlName="ClientIdName" [options]="ClientIdNameList"
                                                            [showToggleAll]="true" [(ngModel)]="selectedClientItems"
                                                            optionLabel="ClientName" optionValue="ClientId"
                                                            [virtualScroll]="true" [filter]="true" [itemSize]="34"
                                                            placeholder="Select Clients" filterPlaceholder="Search"
                                                            (ngModelChange)="onChangeClientIdName()">
                                                            <ng-template pTemplate="header">
                                                                <div class="header-container p-multiselect-custom-icon">
                                                                    <i class="search-clear-icon" pTooltip="Clear"
                                                                        (click)="resetFilter(multiSelectRef)"></i>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="item" let-clientdata>
                                                                <div class="country-item">
                                                                    <div>{{clientdata.value.ClientName}}
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </p-multiSelect>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-2">
                                                    <div class="mb-2 mb-md-3 mb-lg-3">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label mb-1">Tax
                                                            Year
                                                            <span class="err-color">*</span></label>
                                                        <ng-multiselect-dropdown name="TaxYears" class="myTaxYears"
                                                            formControlName="selectedYearsform"
                                                            (ngModelChange)="onChangeYears()"
                                                            [placeholder]="'Select Tax Years'"
                                                            [(ngModel)]="selectedYears"
                                                            [settings]="dropdownSettings_taxyear" [data]="years"
                                                            [disabled]="selectyearsdropdown">
                                                        </ng-multiselect-dropdown>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-lg-6 ">
                                                    <div class="d-flex gap-3">
                                                        <button type="button" [hidden]="Discardgoback"
                                                            style="margin-top: 20px;" (click)="backToBatchList()"
                                                            class="btn p-btn-outline">
                                                            Discard & Go Back to Batch List
                                                        </button>
                                                        <button type="button" [hidden]="BacktoDashboard"
                                                            style="margin-top: 20px;" (click)="backToDashboard()"
                                                            class="btn p-btn-outline">
                                                            Back To Dashboard
                                                        </button>
                                                        <button type="button" [hidden]="Clearbulkletter"
                                                            style="margin-top: 20px;" (click)="getClear()"
                                                            class="btn p-btn-outline">
                                                            Clear
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6 ">
                                                    <div class="d-flex justify-content-end gap-3">
                                                        <button
                                                            [pTooltip]="(!BulkletterForm.valid || chooseparagraphbutton) ? null : 'Please choose the required paragraphs to load the client details'"
                                                            type="button" (click)="Chooseparagraph()"
                                                            tooltipClass="fs-12" class="btn p-btn"
                                                            style="margin-top: 20px;"
                                                            [disabled]="!BulkletterForm.valid || chooseparagraphbutton">
                                                            Choose Paragraphs
                                                        </button>
                                                        <button type="button" [hidden]="loadClientDetails"
                                                            style="margin-top: 20px;" (click)="getFetchClientdetails()"
                                                            class="btn p-btn"
                                                            [disabled]="!BulkletterForm.valid || isletterprocessdisable">
                                                            Load Client Details
                                                        </button>
                                                        <button type="button" [hidden]="reloadClientDetails"
                                                            style="margin-top: 20px;" (click)="editFetchClientdetails()"
                                                            class="btn p-btn"
                                                            [disabled]="!(isreloadavail && BulkletterForm.valid) || isletterprocessdisable">
                                                            Load Client Details
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                    <div *ngIf="this.Batchletterlist == true && progressValue === 100"
                        class="bg-white px-4 py-3 rounded mb-2 mb-md-4 bulk-letter-table">
                        <div class="d-flex justify-content-between flex-wrap mb-2">

                            <!-- <div class="mb-md-3 mb-lg-3">
                                <input type="text" class="form-control" id="exampleFormControlInput1"
                                    placeholder="Search by Client ID/Name">
                            </div> -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive position-relative">
                            <p-table #dt [value]="clientData" styleClass="p-datatable-gridlines" dataKey="id"
                                [rows]="10" [resizableColumns]="true" sortMode="multiple" [scrollable]="true"
                                scrollHeight="1000px" [showCurrentPageReport]="true" [paginator]="true"
                                [(first)]="firstpage" currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                [globalFilterFields]="['ClientId','ClientName','PartnerName','Office','SignatoryEmailId','SignatoryFirstName', 'ReturnTypeCode']"
                                (onFilter)="updateAndFilter($event)">
                                <ng-template pTemplate="colgroup">
                                    <colgroup>
                                        <!-- <col style="width:38%"> -->
                                        <col style="width:10%">
                                        <col style="width:30%">
                                        <col style="width:50%">
                                        <col style="width:35%">
                                        <col style="width:35%">
                                        <col style="width:44%">
                                        <col style="width:52%">
                                        <col style="width:48%">
                                        <col style="width:24%">



                                    </colgroup>
                                </ng-template>


                                <ng-template pTemplate="caption">

                                    <h5 class="highlight mb-0 fs-6 fw-semibold">Bulk Letter List </h5>
                                    <br />
                                    <div class="table-header ">
                                        <div class="row">
                                            <div class="col-12 col-md-9">
                                                <div class="d-flex align-items-center flex-wrap gap-2">
                                                    <button type="button" class="btn p-btn " #CreateFormEdit
                                                        data-toggle="modal" data-target="#CreateFormEdit"
                                                        [disabled]="!(Edit7216 && !isEdit7216Enable)"
                                                        (click)="createEdit7216()">Edit 7216 Provider
                                                    </button>
                                                    <button type="button" (click)="bulkChooseparagraphmodal()"
                                                        class="btn p-btn "
                                                        [disabled]="isChooseParagraphButtonEnabled">Choose
                                                        Paragraphs
                                                    </button>
                                                    <button type="button" (click)="bulkEditParagraphmodal('bulk')"
                                                        class="btn p-btn "
                                                        [disabled]="isEditParagraphButtonEnabled">Edit
                                                        Paragraphs
                                                    </button>
                                                    <button type="button" (click)="bulkClientDeleteModal()"
                                                        class="btn p-btn " [disabled]="isButtonEnabled">Remove
                                                        Clients</button>
                                                    <button type="button" #Addclient (click)="addclientmodal()"
                                                        class="btn p-btn ">Add Client</button>
                                                    <!-- <button type="button" class="btn p-btn" data-bs-toggle="modal"
                                                        data-bs-target="#add-attachment" (click)="editAttachment()">
                                                        Edit
                                                        Attachments</button> -->
                                                    <button type="button" class="btn p-btn "
                                                        [disabled]="isBulkEditAttachmentButton" data-bs-toggle="modal"
                                                        data-bs-target="#add-attachment"
                                                        (click)="bulkAddAttachmentsmodal()">
                                                        Edit
                                                        Attachments</button>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-3" id="searchfilter">
                                                <span class="p-input-icon-left w-100">
                                                    <i class="pi pi-search"></i>
                                                    <input pInputText type="text" [(ngModel)]="searchfilter"
                                                        (input)="updateAndFilter($event.target.value)"
                                                        placeholder="Search by column values"
                                                        class="shadow-none w-100" />
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </ng-template>

                                <ng-template pTemplate="header">
                                    <tr>
                                        <!-- <th pResizableColumn pSortableColumn="RowId">Row ID
                                            <p-sortIcon field="RowId">
                                            </p-sortIcon>
                                        </th> -->
                                        <th class="text-center"><input type="checkbox" style="width: 13px;"
                                                #selectAllCheckbox (change)="toggleAllCheckboxes($event)"
                                                [checked]="isAllCheckBoxChecked()"
                                                [disabled]="selectAllCheckBoxDisabled" /></th>
                                        <th pResizableColumn pSortableColumn="ClientId">Client ID<p-sortIcon
                                                field="ClientId">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="ClientName">Client Name<p-sortIcon
                                                field="ClientName">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="PartnerName"> Partner (PIC)<p-sortIcon field="PartnerName">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="Office">Office<p-sortIcon field="Office">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="ReturnTypeCode">ReturnTypeCode<p-sortIcon
                                                field="ReturnTypeCode">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="SignatoryFirstName">Signatory First
                                            Name<p-sortIcon field="SignatoryFirstName">
                                            </p-sortIcon>
                                        </th>
                                        <th pResizableColumn pSortableColumn="SignatoryEmailId">Signatory Email
                                            ID<p-sortIcon field="SignatoryEmailId">
                                            </p-sortIcon>
                                        </th>
                                        <th>Action</th>
                                    </tr>
                                    <!-- grid searchbox -->
                                    <!-- <tr>

                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'RowId', 'contains')"
                                                class="p-column-filter"></th>
                                        <th>
                                            <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'ClientId', 'contains')"
                                                class="p-column-filter">
                                        </th>
                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'ClientName', 'contains')"
                                                class="p-column-filter"></th>
                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'OfficeName', 'contains')"
                                                class="p-column-filter"></th>
                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'EmailID', 'contains')"
                                                class="p-column-filter"></th>
                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'SigningPerson', 'contains')"
                                                class="p-column-filter"></th>
                                        <th> <input pInputText type="text"
                                                (input)="dt.filter($event.target.value, 'Partners', 'contains')"
                                                class="p-column-filter"></th>
                                        <th></th>
                                    </tr> -->

                                </ng-template>
                                <ng-template pTemplate="body" let-client>

                                    <tr>
                                        <td class="text-center"> <input type="checkbox" [value]="client.BulkLettersId"
                                                [(ngModel)]="client.checked"
                                                (change)="toggleSingleCheckbox(client.BulkLettersId, client.checked)">
                                        </td>
                                        <td>{{client.ClientId}}</td>
                                        <td>{{client.ClientName}}</td>
                                        <td>{{client.PartnerName}}</td>
                                        <td>{{client.Office}}</td>
                                        <td>{{client.ReturnTypeCode}}</td>
                                        <td>{{client.SignatoryFirstName }}</td>
                                        <td>{{client.SignatoryEmailId}}</td>

                                        <td class="text-center align-middle">
                                            <div class="custom-icon"
                                                (click)="showMenu($event, menu, client.BulkLettersId,client.ClientId,client.ClientName,client.PartnerId,client.SignatoryEmailId,client.Is7216Available)">
                                                <i class="pi pi-ellipsis-v"></i>
                                            </div>
                                            <p-menu #menu [popup]="true" [class]="menuClicked ? 'custom-menu' : ''"
                                                [model]="items">
                                            </p-menu>
                                        </td>
                                    </tr>



                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="12" style="text-align: center;">No engagement letters found</td>
                                    </tr>
                                </ng-template>

                            </p-table>
                            <div class="d-flex align-items-center justify-content-between flex-wrap excel-btn">
                                <div>
                                    <button type="button" class="link-color me-3" (click)="getexportdata()"><img
                                            class="me-2" src="assets/images/download-icon.png"
                                            alt="Download-icon">Download the above details as excel</button>
                                    <input class="form-control" type="file" hidden="true" (change)="processFile($event)"
                                        accept=".xlsx, .xls, .csv" id="file" name="file">
                                    <button type="button" class="link-color" data-bs-toggle="modal"
                                        data-bs-target="#upload-files" (click)="openFileDialog($event)"><img
                                            class="me-2" src="assets/images/uploadbulk-icon.png"
                                            alt="Download-icon">Upload bulk letter details</button>
                                </div>
                            </div>

                        </div>
                        <div class="d-flex justify-content-end flex-wrap gap-3 mt-2 mt-lg-3">
                            <!--<button type="button" (click)="Discardgobackdelete()" #discardtbtn
                                class="btn p-btn-outline">Discard & Go Back </button>-->
                            <button type="button" (click)="saveDraft()" #Savedraftbtn class="btn p-btn-outline">Save as
                                Draft</button>
                            <button type="button" (click)="createBulkLetterConfirmModal()" class="btn p-btn">Create Bulk
                                letter</button>
                        </div>

                    </div>
                    <!-- </div> -->

                </div>
            </section>
        </main>
    </div>
</section>
<div [innerHTML]="trustedHtml"></div>
<!-- Edit field Modal -->
<div class="modal" tabindex="-1" id="bulk-letter-edit-field" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayEditField}" [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content p-4" id="bulk-letter-edit-field">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Edit Fields </h5>
                <button type="button" class="btn-close shadow-none" (click)="editFieldscloseModal()"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <p><span class="err-color">Note: </span>All the fields are mandatory</p>
            <div class="modal-body p-0" #editfieldScrollPosition>
                <form id="staticfieldsform" [formGroup]="StaticFieldsForm">
                    <div class="p-3 border rounded" id="ClientIfovalues">
                        <div class="row mb-2">
                            <span class="d-block mb-2 fw-bold">1.Client Details:</span>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Client ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="ClientID"
                                        placeholder="Client ID" name="ClientID" readonly
                                        [formControl]="StaticFieldsForm.controls['ClientID']"
                                        [(ngModel)]="this.PartnerValues.ClientID">
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Signatory Email ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="SignatoryEmailId"
                                        placeholder="Signatory Email ID" name="SignatoryEmailId"
                                        [(ngModel)]="this.PartnerValues.SignatoryEmailId"
                                        [formControl]="StaticFieldsForm.controls['SignatoryEmailId']">
                                    <ng-container *ngFor="let validation of validation_messages.SignatoryEmailId">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsForm.get('SignatoryEmailId').hasError(validation.type) && (StaticFieldsForm.get('SignatoryEmailId').dirty || StaticFieldsForm.get('SignatoryEmailId').touched)">
                                            <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-3" [hidden]="isspouseemailid">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Spouse Email ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="SpouseEmailId"
                                        placeholder="Spouse Email ID" name="SpouseEmailId"
                                        [(ngModel)]="this.PartnerValues.SpouseEmailId"
                                        [formControl]="StaticFieldsForm.controls['SpouseEmailId']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeName">
                                <div class="err-color"
                                    *ngIf="StaticFieldsForm.get('OfficeName').hasError(validation.type) && (StaticFieldsForm.get('OfficeName').dirty || StaticFieldsForm.get('OfficeName').touched)">
                                    <label class="errorcolor "> {{ validation.message }}</label>
                                </div>
                            </ng-container> -->
                                    <ng-container *ngFor="let validation of validation_messages.SpouseEmailId">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsForm.get('SpouseEmailId').hasError(validation.type) && (StaticFieldsForm.get('SpouseEmailId').dirty || StaticFieldsForm.get('SpouseEmailId').touched)">
                                            <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1 me-2">Tax Year <span
                                            class="err-color">*</span></label>
                                    <ng-multiselect-dropdown name="TaxYears" id="TaxYears" class="myTaxYears"
                                        (ngModelChange)="onChangeYearsclient()" [placeholder]="'Select Tax Years'"
                                        [(ngModel)]="selectedtaxyeareditfield"
                                        [formControl]="StaticFieldsForm.controls['selectedYearsformedit']"
                                        [settings]="dropdownSettings_taxyear" [data]="taxyears_bind">
                                    </ng-multiselect-dropdown>
                                    <ng-container *ngFor="let validation of validation_messages.TaxYears">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsForm.get('TaxYears').hasError(validation.type) && (StaticFieldsForm.get('TaxYears').dirty || StaticFieldsForm.get('TaxYears').touched)">
                                            <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="mddformbody" #mddformbody class="row p-0 m-0">

                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row">
                                    <span class="d-block mb-2 fw-bold">2. Child Entities:</span>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-3">
                                                <label for="Childclientid" class="form-label mb-1">Child Client
                                                    ID</label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="Childclientid" name="Childclientid"
                                                    [formControl]="StaticFieldsForm.controls['Childclientid']"
                                                    formControlName="Childclientid" placeholder="Child Client ID">
                                                <ng-container
                                                    *ngFor="let validation of validation_messages.Childclientid">
                                                    <div class="errorvl"
                                                        *ngIf="StaticFieldsForm.get('Childclientid').hasError(validation.type) && 
                                                       (StaticFieldsForm.get('Childclientid').dirty || StaticFieldsForm.get('Childclientid').touched)">
                                                        <label class="errorcolor">{{ validation.message }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="col-md-6 col-lg-3">
                                                <label for="ChildEntities" class="form-label mb-1">Child Client
                                                    Name</label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="ChildEntities" name="ChildEntities"
                                                    [formControl]="StaticFieldsForm.controls['ChildEntities']"
                                                    formControlName="ChildEntities" placeholder="Child Client Name">
                                                <ng-container
                                                    *ngFor="let validation of validation_messages.ChildEntities">
                                                    <div class="errorvl"
                                                        *ngIf="StaticFieldsForm.get('ChildEntities').hasError(validation.type) && 
                                                       (StaticFieldsForm.get('ChildEntities').dirty || StaticFieldsForm.get('ChildEntities').touched)">
                                                        <label class="errorcolor">{{ validation.message }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="col-md-6 col-lg-3" *ngIf="isEditFixedFee">
                                                <label for="ChildFees" class="form-label mb-1">Child Fees</label>
                                                <input type="number" autocomplete="off" class="form-control"
                                                    id="ChildFees" name="ChildFees"
                                                    [formControl]="StaticFieldsForm.controls['ChildFees']"
                                                    formControlName="ChildFees" placeholder="Child Fees">
                                                <ng-container *ngFor="let validation of validation_messages.ChildFees">
                                                    <div class="errorvl"
                                                        *ngIf="StaticFieldsForm.get('ChildFees').hasError(validation.type) && 
                                                       (StaticFieldsForm.get('ChildFees').dirty || StaticFieldsForm.get('ChildFees').touched)">
                                                        <label class="errorcolor">{{ validation.message }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="col-md-6 col-lg-3">
                                                <button type="button" #addeditclientbtn class="btn p-btn mb-2 mb-md-0"
                                                    (click)="onAddchildEntityOptions()"
                                                    [disabled]="!isAddButtonDisabled()" [hidden]="isAddBtnVisible"
                                                    style="margin-top:19px">Add</button>

                                                <button type="button" #updateclientbtn class="btn p-btn mb-2 mb-md-0"
                                                    (click)="onUpdatechildEntityOptions()"
                                                    [disabled]="!isAddButtonDisabled()" [hidden]="isUpdateBtnVisible"
                                                    style="margin-top:19px">Update</button>
                                            </div>
                                        </div>
                                        <!-- IsForm7216Available -->
                                        <div class="col-lg-12" style="margin-top: 20px;">
                                            <div class="col-md-12 col-lg-7">
                                                <div *ngIf="childEntitiesControls.length > 0">
                                                    <p-table #dt1 styleClass="p-datatable-gridlines" dataKey="childID"
                                                        [value]="childEntitiesArrayValue" [rows]="3"
                                                        [resizableColumns]="true" sortMode="multiple"
                                                        [scrollable]="true" scrollHeight="1200px"
                                                        [showCurrentPageReport]="true" [paginator]="true"
                                                        [(first)]="childfirstpage"
                                                        currentPageReportTemplate="{first} to {last} of {totalRecords}">
                                                        <ng-template pTemplate="colgroup">
                                                            <colgroup>
                                                                <col style="width:8%">
                                                                <col style="width:17%">
                                                                <col *ngIf="isEditFixedFee" style="width:10%">
                                                                <col *ngIf="Is7216TemplateAvailable" style="width:15%">
                                                                <col style="width:8%">
                                                            </colgroup>
                                                        </ng-template>
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th scope="col"
                                                                    style="height: 30px; padding: 0;text-align: center;">
                                                                    Child Client ID
                                                                </th>
                                                                <th scope="col"
                                                                    style="height: 30px; padding: 0; text-align: center;">
                                                                    Child Client Name
                                                                </th>
                                                                <th scope="col" *ngIf="isEditFixedFee"
                                                                    style="height: 30px; padding: 0; text-align: center;">
                                                                    Child Fees
                                                                </th>
                                                                <th scope="col" *ngIf="Is7216TemplateAvailable"
                                                                    style="height: 30px; padding: 0; text-align: center;">
                                                                    Child 7216 Available
                                                                </th>
                                                                <th scope="col"
                                                                    style="height: 30px; padding: 0;text-align: center;">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </ng-template>

                                                        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
                                                            <tr style="height: 30px;"
                                                                [formGroup]="childEntitiesControls[rowIndex]">
                                                                <td style="padding: 0;text-align: center;">
                                                                    {{
                                                                    childEntitiesControls[rowIndex].get('ChildClientId').value
                                                                    }}
                                                                </td>
                                                                <td
                                                                    style="padding:-1px; word-break: break-word; overflow-wrap: break-word;">
                                                                    {{
                                                                    childEntitiesControls[rowIndex].get('ChildClientName').value
                                                                    }}
                                                                </td>
                                                                <td *ngIf="isEditFixedFee"
                                                                    style="padding:-1px; word-break: break-word; overflow-wrap: break-word;">
                                                                    {{
                                                                    childEntitiesControls[rowIndex].get('ChildFees').value
                                                                    }}
                                                                </td>
                                                                <td *ngIf="Is7216TemplateAvailable"
                                                                    style="padding: -1px; word-break: break-word; overflow-wrap: break-word; text-align: center;">
                                                                    <div class="form-check form-check-inline">
                                                                        <input type="radio"
                                                                            id="inlineRadioYes{{rowIndex}}"
                                                                            formControlName="Child7216Available"
                                                                            [value]="true" class="form-check-input" />
                                                                        <label for="inlineRadioYes{{rowIndex}}"
                                                                            class="form-check-label">Yes</label>
                                                                    </div>

                                                                    <div class="form-check form-check-inline">
                                                                        <input type="radio"
                                                                            id="inlineRadioNo{{rowIndex}}"
                                                                            formControlName="Child7216Available"
                                                                            [value]="false" class="form-check-input" />
                                                                        <label for="inlineRadioNo{{rowIndex}}"
                                                                            class="form-check-label">No</label>
                                                                    </div>
                                                                </td>
                                                                <td style="padding: 0;text-align: center;"
                                                                    class="text-center align-middle">
                                                                    <p class="m-0">
                                                                        <button class="bg-transparent mx-1 link-color"
                                                                            pTooltip="Edit"
                                                                            (click)="editChildClientDetails(childEntitiesControls[rowIndex].controls,rowIndex)"><img
                                                                                src="./assets/images/edit.png"
                                                                                alt="Edit-icon">
                                                                        </button>
                                                                        <button class="bg-transparent mx-1 link-color"
                                                                            pTooltip="Delete" tooltipPosition="top"
                                                                            (click)="onRemoveEntityOptions(rowIndex)">
                                                                            <img src="./assets/images/delete-icon.png"
                                                                                alt="Delete-icon">
                                                                        </button>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </ng-template>


                                                        <ng-template pTemplate="emptymessage">
                                                            <tr>
                                                                <td colspan="4" style="text-align: center;">No
                                                                    ChildEntities found</td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <div class="row" id="officedetails">
                            <span class="d-block mb-2 fw-bold">3.Office Details:</span>

                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Partner ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" name="PartnerID" id="{{this.PartnerFieldId}}"
                                        required class="form-control" placeholder="Partner ID" readonly
                                        [(ngModel)]="this.PartnerValues.PartnerId"
                                        [formControl]="StaticFieldsForm.controls['PartnerID']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.PartnerID">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('PartnerID').hasError(validation.type) && (StaticFieldsForm.get('PartnerID').dirty || StaticFieldsForm.get('PartnerID').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2 ">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Partner Name
                                        <span class="err-color">*</span></label>
                                    <ng-select [(ngModel)]="this.PartnerValues.PartnerName"
                                        id="{{this.PartnerNameFieldId}}" [items]="partnerNameList"
                                        formControlName="PartnerName" (change)="onPartnerSelected($event)"
                                        bindLabel="FirstName" bindValue="FirstName" [searchable]="true"
                                        [clearable]="false" placeholder="Select Partner">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="{{this.OfficeFieldId}}" placeholder="Office ID" name="OfficeID"
                                        [(ngModel)]="this.PartnerValues.OfficeId" readonly
                                        [formControl]="StaticFieldsForm.controls['OfficeID']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Name
                                        <span class="err-color">*</span></label>
                                    <ng-select [(ngModel)]="this.PartnerValues.OfficeName" [items]="officeDetailsList"
                                        id="{{this.OfficeNameFieldId}}" formControlName="OfficeName"
                                        (change)="onOfficeSelected($event)" bindLabel="Name" bindValue="Name"
                                        [searchable]="true" [clearable]="false" placeholder="Select Partner">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Address Line1
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeAddressLine1}}" placeholder="Office Address Line1"
                                        name="OfficeAddressLine1" [(ngModel)]="this.PartnerValues.OfficeAddressLine1"
                                        [formControl]="StaticFieldsForm.controls['OfficeAddressLine1']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Address Line2
                                    </label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeAddressLine2}}" placeholder="Office Address Line2"
                                        name="OfficeAddressLine2" [(ngModel)]="this.PartnerValues.OfficeAddressLine2"
                                        [formControl]="StaticFieldsForm.controls['OfficeAddressLine2']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Address Line3
                                    </label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeAddressLine3}}" placeholder="Office Address Line3"
                                        name="OfficeAddressLine3" [(ngModel)]="this.PartnerValues.OfficeAddressLine3"
                                        [formControl]="StaticFieldsForm.controls['OfficeAddressLine3']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office City
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeCityFieldId}}" placeholder="Office City" name="OfficeCity"
                                        [(ngModel)]="this.PartnerValues.City"
                                        [formControl]="StaticFieldsForm.controls['OfficeCity']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office State
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeStateFieldId}}" placeholder="Office State" name="OfficeState"
                                        [(ngModel)]="this.PartnerValues.State"
                                        [formControl]="StaticFieldsForm.controls['OfficeState']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Zip
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficeZipCodeFieldId}}" placeholder="Office Zip" name="OfficeZip"
                                        [(ngModel)]="this.PartnerValues.ZipCode"
                                        [formControl]="StaticFieldsForm.controls['OfficeZip']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Phone
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.OfficePhoneNumberFieldId}}" placeholder="Office Phone"
                                        name="OfficePhone" [(ngModel)]="this.PartnerValues.PhoneNumber"
                                        [formControl]="StaticFieldsForm.controls['OfficePhone']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Jurisdiction
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" readonly
                                        id="{{this.jurisdictionFieldId}}" placeholder="Jurisdiction" name="Jurisdiction"
                                        [(ngModel)]="this.PartnerValues.jurisdiction"
                                        [formControl]="StaticFieldsForm.controls['Jurisdiction']">
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div>
                            <span class="d-block mb-2 fw-bold">4.Other Details:</span>
                            <div class="row">
                                <!-- Parent 7216 available -->
                                <div class="col-md-6 col-lg-3 mb-2" *ngIf="Template7216IsAvailable">
                                    <label for="" class="control-label mb-1">Parent 7216 Available?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="editField7216available"
                                                (change)="on7216OnItemChange($event.target.value)"
                                                formControlName="editField7216available" value="true"
                                                id="7216inlineRadio1" class="form-check-input">
                                            <label for="7216inlineRadio1" class="form-check-label">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="editField7216available"
                                                (change)="on7216OnItemChange($event.target.value)"
                                                formControlName="editField7216available" value="false"
                                                id="7216inlineRadio2" class="form-check-input"><label
                                                for="7216inlineRadio2" class="form-check-label">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6 col-lg-2">
                                    <label for="" class="control-label mb-1">E-Signing?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question ">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="inlineRadio5" name="Esigning"
                                                (change)="onItemChange($event.target.value)" formControlName="Esigning"
                                                value="true" class="form-check-input">
                                            <label for="inlineRadio5" class="form-check-label">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="Esigning" id="inlineRadio6"
                                                (change)="onItemChange($event.target.value)" formControlName="Esigning"
                                                value="false" class="form-check-input"><label for="inlineRadio6"
                                                class="form-check-label">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Template name
                                            <span class="err-color">*</span></label>
                                        <input type="text" autocomplete="off" class="form-control" id="templateName"
                                            placeholder="template name" name="templateName" readonly
                                            [formControl]="StaticFieldsForm.controls['TemplateName']"
                                            [(ngModel)]="this.PartnerValues.TemplateName">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2">
                                    <div class="mb-2 ">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Return Type Code
                                            <span class="err-color">*</span></label>
                                        <input type="text" autocomplete="off" class="form-control" id="3"
                                            placeholder="ReturnTypeCode" name="ReturnTypeCode" readonly
                                            [formControl]="StaticFieldsForm.controls['ReturnTypeCode']"
                                            [(ngModel)]="this.returntypecodebind">
                                        <!-- <ng-container *ngFor="let validation of validation_messages.PartnerName">
                                            <div class="err-color"
                                                *ngIf="StaticFieldsForm.get('PartnerName').hasError(validation.type) && (StaticFieldsForm.get('PartnerName').dirty || StaticFieldsForm.get('PartnerName').touched)">
                                                <label class="errorcolor "> {{ validation.message }}</label>
                                            </div>
                                        </ng-container> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="mt-3 mt-md-3 text-end sticky-bottom bg-white">
                            <button type="button" (click)="editFieldscloseModal()"
                                class="btn p-btn-outline">Close</button>
                            <button type="button" (click)="updateFields()" class="btn p-btn ms-3">Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Add client Modal -->
<div class="modal" tabindex="-1" id="bulk-letter-add-field" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayaddclient}" [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content p-4" id="bulk-letter-add-field">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Add New Client </h5>
                <button type="button" class="btn-close shadow-none" (click)="addclientscloseModal()"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <p><span class="err-color">Note: </span>All the fields are mandatory</p>
            <div class="modal-body p-0" #addClientScrollPosition>
                <form id="staticfieldsform" [formGroup]="StaticFieldsClientForm">
                    <div class="p-3 border rounded" id="ClientIfovalues">
                        <div class="row mb-2">
                            <span class="d-block mb-2 fw-bold">1.Client Details:</span>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div id="ClientId" class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Client ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" name="ClientID" required class="form-control"
                                        placeholder="Client ID"
                                        [formControl]="StaticFieldsClientForm.controls['ClientID']">
                                    <ng-container *ngFor="let validation of validation_messages.ClientID">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsClientForm.get('ClientID').hasError(validation.type) && (StaticFieldsClientForm.get('ClientID').dirty || StaticFieldsClientForm.get('ClientID').touched)">
                                            <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Signatory Email ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="SignatoryEmailId"
                                        placeholder="Signatory Email ID" name="SignatoryEmailId"
                                        [formControl]="StaticFieldsClientForm.controls['SignatoryEmailId']">
                                    <ng-container *ngFor="let validation of validation_messages.SignatoryEmailId">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsClientForm.get('SignatoryEmailId').hasError(validation.type) && (StaticFieldsClientForm.get('SignatoryEmailId').dirty || StaticFieldsClientForm.get('SignatoryEmailId').touched)">

                                            <label class="errorcolor" *ngIf="!condition">
                                                {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3" [hidden]="isspouseemailid">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Spouse Email ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="SpouseEmailId"
                                        placeholder="Spouse Email ID" name="SpouseEmailId"
                                        [formControl]="StaticFieldsClientForm.controls['SpouseEmailId']">
                                    <ng-container *ngFor="let validation of validation_messages.SpouseEmailId">
                                        <div class="errorvl"
                                            *ngIf="StaticFieldsClientForm.get('SpouseEmailId').hasError(validation.type) && (StaticFieldsClientForm.get('SpouseEmailId').dirty || StaticFieldsClientForm.get('SpouseEmailId').touched)">
                                            <label class="errorcolor" *ngIf="!condition">
                                                {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1 me-2">Tax Year <span
                                            class="err-color">*</span></label>
                                    <ng-multiselect-dropdown name="TaxYearsclient" class="myTaxYears"
                                        formControlName="selectedclientYearsform"
                                        (ngModelChange)="onChangeYearsclient()" [placeholder]="'Select Tax Years'"
                                        [(ngModel)]="clientselectedYears" [settings]="dropdownSettings_taxyear"
                                        [data]="years">
                                    </ng-multiselect-dropdown>

                                </div>
                            </div>
                            <div id="addclientformbody" #addclientformbody class="row m-0 p-0">


                            </div>
                            <div id="addformbody" #addformbody class="row m-0 p-0">

                            </div>


                        </div>
                        <hr>
                        <div class="row">
                            <!-- Add title -->
                            <span class="d-block mb-2 fw-bold">2.Child Entities:</span>

                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-md-6 col-lg-3">
                                        <label for="Childclientid" class="form-label mb-1">Child Client
                                            ID</label>
                                        <input type="text" autocomplete="off" class="form-control" id="Childclientid"
                                            name="Childclientid"
                                            [formControl]="StaticFieldsClientForm.controls['Childclientid']"
                                            formControlName="Childclientid" placeholder="Child Client ID">
                                        <ng-container *ngFor="let validation of validation_messages.Childclientid">
                                            <div class="errorvl"
                                                *ngIf="StaticFieldsClientForm.get('Childclientid').hasError(validation.type) && 
                                               (StaticFieldsClientForm.get('Childclientid').dirty || StaticFieldsClientForm.get('Childclientid').touched)">
                                                <label class="errorcolor">{{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-6 col-lg-3">
                                        <label for="ChildEntities" class="form-label mb-1">Child Client
                                            Name</label>
                                        <input type="text" autocomplete="off" class="form-control" id="ChildEntities"
                                            name="ChildEntities"
                                            [formControl]="StaticFieldsClientForm.controls['ChildEntities']"
                                            formControlName="ChildEntities" placeholder="Child Client Name">
                                        <ng-container *ngFor="let validation of validation_messages.ChildEntities">
                                            <div class="errorvl"
                                                *ngIf="StaticFieldsClientForm.get('ChildEntities').hasError(validation.type) && 
                                               (StaticFieldsClientForm.get('ChildEntities').dirty || StaticFieldsClientForm.get('ChildEntities').touched)">
                                                <label class="errorcolor">{{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-6 col-lg-3" *ngIf="isbatchChoosePara">
                                        <label for="ChildFees" class="form-label mb-1">Child Fees</label>
                                        <input type="number" autocomplete="off" class="form-control" id="ChildFees"
                                            name="ChildFees"
                                            [formControl]="StaticFieldsClientForm.controls['ChildFees']"
                                            formControlName="ChildFees" placeholder="Child Fees">
                                        <ng-container *ngFor="let validation of validation_messages.ChildFees">
                                            <div class="errorvl"
                                                *ngIf="StaticFieldsClientForm.get('ChildFees').hasError(validation.type) && 
                                               (StaticFieldsClientForm.get('ChildFees').dirty || StaticFieldsClientForm.get('ChildFees').touched)">
                                                <label class="errorcolor">{{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-6 col-lg-3">
                                        <button type="button" #addeditclientbtn class="btn p-btn mb-2 mb-md-0"
                                            (click)="onEditchildEntityOptions()" [disabled]="!ischildButtonDisabled()"
                                            [hidden]="isAddClientBtnVisible" style="margin-top:19px">Add</button>

                                        <button type="button" #updateclientbtn class="btn p-btn mb-2 mb-md-0"
                                            (click)="onUpdateAddClientChildEntity()"
                                            [disabled]="!ischildButtonDisabled()" [hidden]="isUpdateClientBtnVisible"
                                            style="margin-top:19px">Update</button>
                                    </div>
                                </div>

                                <div class="col-lg-12" style="margin-top: 20px;">
                                    <div class="col-md-12 col-lg-7">
                                        <div *ngIf="childEntitiesClientControls.length > 0">
                                            <p-table #dt1 styleClass="p-datatable-gridlines" dataKey="childID"
                                                [value]="childEntitiesClientValue" [rows]="3" [resizableColumns]="true"
                                                sortMode="multiple" [scrollable]="true" scrollHeight="1200px"
                                                [showCurrentPageReport]="true" [paginator]="true"
                                                [(first)]="childfirstpage"
                                                currentPageReportTemplate="{first} to {last} of {totalRecords}">

                                                <ng-template pTemplate="colgroup">
                                                    <colgroup>
                                                        <col style="width:8%">
                                                        <col style="width:17%">
                                                        <col *ngIf="isbatchChoosePara" style="width:10%">
                                                        <col *ngIf="overAll7216Button" style="width:15%">
                                                        <col style="width:8%">
                                                    </colgroup>
                                                </ng-template>

                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th scope="col"
                                                            style="height: 30px; padding: 0;text-align: center;">Child
                                                            Client ID</th>
                                                        <th scope="col"
                                                            style="height: 30px; padding: 0; text-align: center;">Child
                                                            Client Name</th>
                                                        <th scope="col" *ngIf="isbatchChoosePara"
                                                            style="height: 30px; padding: 0; text-align: center;">
                                                            Child Fees
                                                        </th>
                                                        <th scope="col" *ngIf="overAll7216Button"
                                                            style="height: 30px; padding: 0; text-align: center;">Child
                                                            7216 Available</th>
                                                        <th scope="col"
                                                            style="height: 30px; padding: 0;text-align: center;">Action
                                                        </th>
                                                    </tr>
                                                </ng-template>

                                                <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
                                                    <tr style="height: 30px;"
                                                        [formGroup]="childEntitiesClientControls[rowIndex]">
                                                        <td style="padding: 0;text-align: center;">
                                                            {{
                                                            childEntitiesClientControls[rowIndex].get('ChildClientId').value
                                                            }}
                                                        </td>
                                                        <td
                                                            style="padding:-1px; word-break: break-word; overflow-wrap: break-word;">
                                                            {{
                                                            childEntitiesClientControls[rowIndex].get('ChildClientName').value
                                                            }}
                                                        </td>
                                                        <td *ngIf="isbatchChoosePara"
                                                            style="padding:-1px; word-break: break-word; overflow-wrap: break-word;">
                                                            {{
                                                            childEntitiesClientControls[rowIndex].get('ChildFees').value
                                                            }}
                                                        </td>
                                                        <td *ngIf="overAll7216Button"
                                                            style="padding: -1px; word-break: break-word; overflow-wrap: break-word; text-align: center;">
                                                            <div class="form-check form-check-inline">
                                                                <input type="radio" id="inlineRadioYes{{rowIndex}}"
                                                                    formControlName="Child7216Available" [value]="true"
                                                                    class="form-check-input" />
                                                                <label for="inlineRadioYes{{rowIndex}}"
                                                                    class="form-check-label">Yes</label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input type="radio" id="inlineRadioNo{{rowIndex}}"
                                                                    formControlName="Child7216Available" [value]="false"
                                                                    class="form-check-input" />
                                                                <label for="inlineRadioNo{{rowIndex}}"
                                                                    class="form-check-label">No</label>
                                                            </div>
                                                        </td>
                                                        <td style="padding: 0;text-align: center;"
                                                            class="text-center align-middle">
                                                            <p class="m-0">
                                                                <button class="bg-transparent mx-1 link-color"
                                                                    pTooltip="Edit"
                                                                    (click)="editAddClientChildDetails(childEntitiesClientControls[rowIndex].controls,rowIndex)"><img
                                                                        src="./assets/images/edit.png" alt="Edit-icon">
                                                                </button>
                                                                <button class="bg-transparent mx-1 link-color"
                                                                    pTooltip="Delete" tooltipPosition="top"
                                                                    (click)="onEditRemoveEntityOptions(rowIndex)">
                                                                    <img src="./assets/images/delete-icon.png"
                                                                        alt="Delete-icon">
                                                                </button>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </ng-template>

                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="4" style="text-align: center;">No ChildEntities
                                                            found</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <hr>
                        <div class="row" id="officeadddetails">
                            <span class="d-block mb-2 fw-bold">3.Office Details:</span>

                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Partner ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" name="PartnerID" id="PartnerId" required
                                        class="form-control" placeholder="Partner ID" readonly
                                        [formControl]="StaticFieldsClientForm.controls['PartnerID']">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2 ">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Partner Name
                                        <span class="err-color">*</span></label>

                                    <ng-select [(ngModel)]="this.PartnerValues.PartnerName"
                                        id="{{this.PartnerNameFieldId}}" [items]="partnerNameList"
                                        formControlName="PartnerName" (change)="addClientPartnerSelected($event)"
                                        bindLabel="FirstName" bindValue="FirstName" [searchable]="true"
                                        [clearable]="false" placeholder="Select Partner">
                                    </ng-select>

                                    <!-- <ng-container *ngFor="let validation of validation_messages.PartnerName">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('PartnerName').hasError(validation.type) && (StaticFieldsForm.get('PartnerName').dirty || StaticFieldsForm.get('PartnerName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>


                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office ID
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="OfficeId"
                                        placeholder="Office ID" name="OfficeID" readonly
                                        [formControl]="StaticFieldsClientForm.controls['OfficeID']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeID">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeID').hasError(validation.type) && (StaticFieldsForm.get('OfficeID').dirty || StaticFieldsForm.get('OfficeID').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Name
                                        <span class="err-color">*</span></label>
                                    <ng-select [(ngModel)]="this.PartnerValues.OfficeName" id="Officename"
                                        [items]="officeDetailsList" formControlName="OfficeName"
                                        (change)="addClientOfficeChange($event)" bindLabel="Name" bindValue="Name"
                                        [searchable]="true" [clearable]="false" placeholder="Select Partner">
                                    </ng-select>

                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeName">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeName').hasError(validation.type) && (StaticFieldsForm.get('OfficeName').dirty || StaticFieldsForm.get('OfficeName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Address Line1
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="OfficeAddressLine1"
                                        placeholder="Office Address Line1" name="OfficeAddressLine1" readonly
                                        [formControl]="StaticFieldsClientForm.controls['OfficeAddressLine1']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeAddress">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeAddress').hasError(validation.type) && (StaticFieldsForm.get('OfficeAddress').dirty || StaticFieldsForm.get('OfficeAddress').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Address Line2
                                    </label>
                                    <input type="text" autocomplete="off" class="form-control" id="OfficeAddressLine2"
                                        placeholder="Office Address Line2" name="OfficeAddressLine2" readonly
                                        [formControl]="StaticFieldsClientForm.controls['OfficeAddressLine2']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeAddress">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeAddress').hasError(validation.type) && (StaticFieldsForm.get('OfficeAddress').dirty || StaticFieldsForm.get('OfficeAddress').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Address Line3
                                    </label>
                                    <input type="text" autocomplete="off" class="form-control" id="OfficeAddressLine3"
                                        placeholder="Office Address Line3" name="OfficeAddressLine3" readonly
                                        [formControl]="StaticFieldsClientForm.controls['OfficeAddressLine3']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeAddress">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeAddress').hasError(validation.type) && (StaticFieldsForm.get('OfficeAddress').dirty || StaticFieldsForm.get('OfficeAddress').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office City
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Officecity"
                                        placeholder="Office City" name="OfficeCity" readonly
                                        [formControl]="StaticFieldsClientForm.controls['OfficeCity']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeCity">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeCity').hasError(validation.type) && (StaticFieldsForm.get('OfficeCity').dirty || StaticFieldsForm.get('OfficeCity').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office State
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Officestate"
                                        placeholder="Office State" name="OfficeState" readonly
                                        [formControl]="StaticFieldsClientForm.controls['OfficeState']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeState">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeState').hasError(validation.type) && (StaticFieldsForm.get('OfficeState').dirty || StaticFieldsForm.get('OfficeState').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Zip
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Officezip"
                                        placeholder="Office Zip" name="OfficeZip" readonly
                                        [formControl]="StaticFieldsClientForm.controls['OfficeZip']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficeZip">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficeZip').hasError(validation.type) && (StaticFieldsForm.get('OfficeZip').dirty || StaticFieldsForm.get('OfficeZip').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Office Phone
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Officephone"
                                        placeholder="Office Phone" name="OfficePhone" readonly
                                        [formControl]="StaticFieldsClientForm.controls['OfficePhone']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficePhone">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficePhone').hasError(validation.type) && (StaticFieldsForm.get('OfficePhone').dirty || StaticFieldsForm.get('OfficePhone').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Jurisdiction
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control" id="Jurisdiction"
                                        placeholder="Jurisdiction" name="Jurisdiction" readonly
                                        [formControl]="StaticFieldsClientForm.controls['Jurisdiction']">
                                    <!-- <ng-container *ngFor="let validation of validation_messages.OfficePhone">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('OfficePhone').hasError(validation.type) && (StaticFieldsForm.get('OfficePhone').dirty || StaticFieldsForm.get('OfficePhone').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </div>
                            <!-- <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Citrin Name
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="exampleFormControlInput1" placeholder="Citrin Name" name="CitrinName"
                                        [formControl]="StaticFieldsForm.controls['CitrinName']">
                                    <ng-container *ngFor="let validation of validation_messages.CitrinName">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('CitrinName').hasError(validation.type) && (StaticFieldsForm.get('CitrinName').dirty || StaticFieldsForm.get('CitrinName').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div> -->
                            <!-- <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">Citrin Website
                                        <span class="err-color">*</span></label>
                                    <input type="text" autocomplete="off" class="form-control"
                                        id="exampleFormControlInput1" placeholder="Citrin Website" name="CitrinWebsite"
                                        [formControl]="StaticFieldsForm.controls['CitrinWebsite']">
                                    <ng-container *ngFor="let validation of validation_messages.CitrinWebsite">
                                        <div class="err-color"
                                            *ngIf="StaticFieldsForm.get('CitrinWebsite').hasError(validation.type) && (StaticFieldsForm.get('CitrinWebsite').dirty || StaticFieldsForm.get('CitrinWebsite').touched)">
                                            <label class="errorcolor "> {{ validation.message }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div> -->
                        </div>
                        <hr>
                        <div>
                            <span class="d-block mb-2 fw-bold">4.Other Details:</span>
                            <div class="row">
                                <!-- 7216 available -->
                                <div class="col-md-6 col-lg-3 mb-2" *ngIf="Template7216IsAvailable">
                                    <label for="" class="control-label mb-1">Parent 7216 Available?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="addClient7216available"
                                                (change)="on7216OnItemChange($event.target.value)"
                                                formControlName="addClient7216available" value="true"
                                                id="addClient7216inlineRadio1" class="form-check-input">
                                            <label for="addClient7216inlineRadio1" class="form-check-label">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="addClient7216available"
                                                (change)="on7216OnItemChange($event.target.value)"
                                                formControlName="addClient7216available" value="false"
                                                id="addClient7216inlineRadio2" class="form-check-input"><label
                                                for="addClient7216inlineRadio2" class="form-check-label">No</label>
                                        </div>
                                    </div>
                                </div>



                                <!-- <div class="col-md-6 col-lg-6 mb-2">
                                    <label for="" class="control-label mb-1">2.How Many Client Signatures?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="ClientSignatures"
                                                formControlName="ClientSignatures" value="1 Client Signatures"
                                                id="inlineRadio3" class="form-check-input">
                                            <label for="inlineRadio3" class="form-check-label">1 Client
                                                Signatures</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="ClientSignatures"
                                                formControlName="ClientSignatures" value="2 Client Signatures"
                                                id="inlineRadio4" class="form-check-input"><label for="inlineRadio4"
                                                class="form-check-label">2
                                                Client Signatures</label>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-12 col-md-6 col-lg-2">
                                    <label for="" class="control-label mb-1">E-Signing?<span
                                            class="err-color">*</span></label>
                                    <div class="response_question ">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" id="inlineRadio7" name="Esigningclient"
                                                (change)="onItemChange($event.target.value)"
                                                formControlName="Esigningclient" value="true" class="form-check-input">
                                            <label for="inlineRadio7" class="form-check-label">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input type="radio" name="Esigningclient" id="inlineRadio8"
                                                (change)="onItemChange($event.target.value)"
                                                formControlName="Esigningclient" value="false"
                                                class="form-check-input"><label for="inlineRadio8"
                                                class="form-check-label">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Template name
                                            <span class="err-color">*</span></label>
                                        <input type="text" autocomplete="off" class="form-control" id="templateName"
                                            placeholder="template name" name="templateName" readonly
                                            [formControl]="StaticFieldsClientForm.controls['TemplateName']"
                                            [(ngModel)]="this.PartnerValues.TemplateName">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-2">
                                    <div class="mb-2 ">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Return Type Code
                                            <span class="err-color">*</span></label>

                                        <ng-select formControlName="ReturnTypeCode" bindLabel="ReturnTypeCode"
                                            name="ReturnTypeCode" id="ReturnTypeCode" placeholder="Select"
                                            [formControl]="StaticFieldsClientForm.controls['ReturnTypeCode']"
                                            [(ngModel)]="model.selectedreturntype"
                                            (change)="Populatereturntype(model.selectedreturntype);"
                                            [virtualScroll]="true" required [searchable]="true" [clearable]="false">

                                            <ng-option *ngFor="let returntypes of fileteredreturntype"
                                                value={{returntypes.ReturnType}}>

                                                {{returntypes.ReturnType}}
                                            </ng-option>
                                        </ng-select>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="mt-3 mt-md-3 text-end sticky-bottom bg-white">
                            <button type="button" (click)="addclientscloseModal()"
                                class="btn p-btn-outline">Close</button>
                            <button type="button" (click)="addnewclient()" class="btn p-btn ms-3">Add</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="errorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModalexcel()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p *ngIf="this.excelerrorchecking >0" class="fs-16" style="word-wrap:break-word;text-align:center;">
                    {{errorMessage}}<br>{{errorclientid}}</p>
                <p *ngIf="this.excelerrorchecking <=0 " class="fs-16" style="word-wrap:break-word;text-align:center;">
                    {{errorMessage}}</p>
                <button type="button" id="errorOk" (click)="closeErrorModalexcel()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal blocks-->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModalblocks}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="errorModalBlocks">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModalblocks()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">

                <p class="fs-16" style="word-wrap:break-word;text-align:center;">
                    {{errorMessage}}</p>
                <button type="button" id="Okerror" (click)="closeErrorModalblocks()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Error Modal Attachment -->
<div class="modal" tabindex="-1" id="attachmenterror" [ngStyle]="{'display':displayErrorModalattachment}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="attachmenterrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16"> {{errorMessage}}</p>
                <button type="button" id="attachmenterrorOk" (click)="closeErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal discard -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModaldiscardelete}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="successDiscardDelete">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="discarddeleteclosesucessmodal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button type="button" id="successOk" (click)="discarddeleteclosesucessmodal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>


<!-- Discardgoback-->

<div class="modal" tabindex="-1" id="displaydiscardgoback" [ngStyle]="{'display':discardgobackdelete}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="discardgobackDelete">
            <div class="modal-header">
                <h5 class="modal-title fs-18 fw-semibold highlight">Confirm Discard & Go Back</h5>
                <button type="button" class="btn-close shadow-none" (click)="discardgobackdeleteClose()"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to discard this batch and go back to batch
                    dashboard?<br><br><b> Note:</b>&nbsp;By clicking "Yes", this batch will be deleted and screen
                    will
                    be redirected to batch dashboard.
                </p>
            </div>
            <div class="modal-footer">
                <button id="discardGoBackNo" type="button" class="btn p-btn-outline"
                    (click)="discardgobackdeleteNo()">No</button>
                <button id="discardGoBackYes" type="button" class="btn p-btn ms-2"
                    (click)="disacarddeleteBatchById()">Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- BacktoDashboard Modal -->
<div class="modal" tabindex="-1" id="backToDashboard" [ngStyle]="{'display':displayBacktoDashboard}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="backToDashboardContent">
            <div class="modal-header">
                <h5 class="modal-title fs-18 fw-semibold highlight">Confirm Redirect</h5>
                <button type="button" class="btn-close shadow-none" (click)="BacktoDahboardNo()"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to discard and go back to dashboard?</p>
            </div>
            <div class="modal-footer">
                <button id="backtoDashboardNo" type="button" class="btn p-btn-outline"
                    (click)="BacktoDahboardNo()">No</button>
                <button id="backtoDashboardYes" type="button" class="btn p-btn ms-2"
                    (click)="BacktoDahboardYes()">Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal -->
<div class="modal" tabindex="-1" id="clienterror" [ngStyle]="{'display':displaynewClientErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="clienterrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closenewClientErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="s-text fw-medium mb-0">Already same Client details are available in this batch, please provide
                    new client details and then submit</p>

                <button type="button" id="clienterrorOk" (click)="closenewClientErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Error Modal -->
<div class="modal" tabindex="-1" id="fielderror" [ngStyle]="{'display':displayFieldErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="fielderrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeFieldErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16" style="word-wrap:break-word;text-align: center;">
                    <span *ngIf="this.emptyFieldarr!=''" class="d-block text-center">Please provide input to
                        mandatory
                        field(s) - <span style="word-wrap:break-word;">{{emptyFieldarr}}</span>
                    </span><br>
                    <span class="d-block">{{clientiderrormessage}}</span>
                    <span class="d-block">{{errorMessageClientIDLength}}</span>
                    <span class="d-block">{{signatorybotherrormessage}}</span>
                    <span class="d-block">{{signatoryspouseerrormessage}}</span>
                    <span class="d-block">{{signatorymailerrormessage}}</span>
                    <span class="d-block">{{feesErrorMessage}}</span>
                </p>
                <button type="button" id="fielderrorOk" (click)="closeFieldErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->
<div class="modal" tabindex="-1" id="deleteModal" [ngStyle]="{'display':displayDeleteModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayDeleteModal">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                <button type="button" (click)="onCloseDeleteModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">{{deletePopupMessage}}</p>
            </div>
            <div class="modal-footer">
                <button id="deleteModalNo" type="button" (click)="onCloseDeleteModal()" class="btn p-btn-outline"
                    data-bs-dismiss="modal">No</button>
                <button id="deleteModalYes" type="button" (click)="deleteBulkLetterById()"
                    class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- create bulk letter confirm modal -->
<div class="modal" tabindex="-1" id="createBulkletterConfirmPopup"
    [ngStyle]="{'display':displayCreateBulkletterConfirmPopup}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayCreateBulkletterConfirmPopup">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm</h5>
                <button type="button" (click)="onCloseCreateBulkletterConfirmModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">{{CreateBulkletterConfirmPopupMessage}}</p>
            </div>
            <div class="modal-footer">
                <button id="createBulkletterNo" type="button" (click)="onCloseCreateBulkletterConfirmModal()"
                    class="btn p-btn-outline" data-bs-dismiss="modal">No</button>
                <button id="createBulkletterYes" type="button" (click)="createbulkletter()"
                    class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Client Bulk Delete Modal -->
<div class="modal" tabindex="-1" id="bulkClientDeleteModal" [ngStyle]="{'display':displayBulkClientDeleteModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayBulkClientDeleteModal">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                <button type="button" (click)="onCloseBulkClientDeleteModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">{{bulkClientdeletePopupMessage}}</p>
            </div>
            <div class="modal-footer">
                <button id="deleteBulkletterByIdNo" type="button" (click)="onCloseBulkClientDeleteModal()"
                    class="btn p-btn-outline" data-bs-dismiss="modal">No</button>
                <button id="deleteBulkletterByIdYes" type="button" (click)="deleteBulkClientLetterById()"
                    class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Common Error Modal -->
<div class="modal" tabindex="-1" id="commonerror" [ngStyle]="{'display':displayCommonErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayCommonErrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeDeleteErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16" [innerHTML]="errorMessage"></p>
                <button id="commonerrorOk" type="button" (click)="closeDeleteErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Edit para Error Modal -->
<div class="modal" tabindex="-1" id="commonerror" [ngStyle]="{'display':displayEditParaErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayEditParaErrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeEditParaErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <!-- <p style="word-wrap:break-word;text-align:center;">Client ID : {{EditParaClientIdErrors}}</p> -->
                <button type="button" id="Okcommonerror" (click)="closeEditParaErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- CK edit Error Modal -->
<div class="modal" tabindex="-1" id="commonerror" [ngStyle]="{'display':displayCKeditorErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="commonErrorCKeditor">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeCKeditorErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16" [innerHTML]="errorMessage"></p>
                <button type="button" id="okCommonError" (click)="closeCKeditorErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Missing Field CLientId warning Modal -->
<div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayWarningModal">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                <button type="button" (click)="closeWarningModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">{{warningMessage}}</p>
                <p style="word-wrap:break-word;text-align:center;">{{ClientIdErrors}}</p>
                <button type="button" id="warningOk" (click)="closeWarningModal()" class="warning-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- same CLientId/Name warning Modal -->
<div class="modal" tabindex="-1" id="sameclient" [ngStyle]="{'display':displaysameClientIdModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="sameclientIdModal">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                <button type="button" (click)="closesameClientModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">{{sameclientwarningMessage}}</p>
                <p style="word-wrap:break-word;text-align:center;">{{SameClientIdErrors}}</p>
                <button type="button" id="sameClientOk" (click)="closesameClientModal()" class="warning-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- if both validation occurs same CLientId/Missing Field CLientId warning Modal -->
<div class="modal" tabindex="-1" id="sameclientmissing" [ngStyle]="{'display':displaysameClientIdmissingModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="sameclientmissingModal">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                <button type="button" (click)="closesameClientmissingModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">{{sameclientwarningMessage}}</p>
                <p style="word-wrap:break-word;text-align:center;">{{SameClientIdErrors}}</p>
                <p class="fs-16">{{warningMessage}}</p>
                <p style="word-wrap:break-word;text-align:center;">{{ClientIdErrors}}</p>
                <button type="button" id="ClientMissingOk" (click)="closesameClientmissingModal()" class="warning-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Success Modal -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="successModal">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button id="succesOk" type="button" (click)="closeSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal for Blocks-->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModalblocks}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="SuccessModalblocks">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessModalblocks()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <!-- <p class="fs-16">{{successMessage}}<br>Client ID:{{ successBlockcontentClientID}}</p> -->
                <p class="fs-16">{{successMessage}}</p>
                <p style="word-wrap:break-word;text-align:center;">Client ID : {{successBlockcontentClientID}}</p>
                <button id="successBlocksOk" type="button" (click)="closeSuccessModalblocks()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Success Modal for Attachment-->
<div class="modal" tabindex="-1" id="attachmentsuccessmodal" [ngStyle]="{'display':displaySuccessAttachmentModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="attachmentsuccess">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessAttachmentModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <!-- <p class="fs-16">{{successMessage}}<br>Client ID:{{ successBlockcontentClientID}}</p> -->
                <p class="fs-16">{{successMessage}}</p>
                <p style="word-wrap:break-word;text-align:center;">Client ID : {{successAttachmentClientId}}</p>
                <button id="successOk" type="button" (click)="closeSuccessAttachmentModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!--discard Success Modal -->
<div class="modal" tabindex="-1" id="discardsuccess" [ngStyle]="{'display':displaydiscardSuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="discardSuccessModal">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closediscardSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button id="discardsuccessOk" type="button" (click)="closediscardSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Create Bulk Success Modal -->
<div class="modal" tabindex="-1" id="createsuccess" [ngStyle]="{'display':displaycreateSuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="createSuccessModal">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="okSuccessModalcreate()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <p class="fs-16">{{LettersCount}}</p>
                <button type="button" id="createsuccessOk" (click)="okSuccessModalcreate()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- PreviewSurvey Modal -->
<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="model-action">

                <a class=" action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
                        alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="model_title">Preview Template</h5>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <div class="template_preview_panel docpreview">
                                <div class="preview_header">
                                    <h5> Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100">

                                    <div id="content" #surveyContent>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Preview Modal -->
<div class="modal" id="preview_doc" [ngStyle]="{'display':displayPreview}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header border-0 justify-content-end">
                <button type="button" class="btn-close mx-3 mt-2 shadow-none" data-bs-dismiss="modal"
                    (click)="closePreview()"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="template_preview_panel doc_preview">
                                <div class="preview_body h-100">
                                    <div *ngIf="this.pdfSrc" style="height: 100%;">
                                        <pdf-viewer class="customtaxlayerremove" [src]="pdfSrc" [stick-to-page]="true"
                                            [show-borders]="true" [render-text]="true"
                                            style="display: block;width:100%;height:calc(100vh - 180px);position: relative;">
                                        </pdf-viewer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- savedraft Modal -->
<div class="modal" tabindex="-1" id="saveDraftModal" [ngStyle]="{'display':displaysaveDraft}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="saveDraftModal">
            <div class="modal-header">
                <h5 class="modal-title fs-18 fw-semibold highlight">Confirm Save & Redirect</h5>
                <button type="button" class="btn-close shadow-none" (click)="saveDraftClose()"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to save the details and go back?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" (click)="saveDraftNo()">No</button>
                <button type="button" class="btn p-btn ms-2" (click)="saveDraftYes()">Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- Success Modal -->
<div class="modal" tabindex="-1" id="savedraftsuccess" [ngStyle]="{'display':displaySuccesssavedraftModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="saveDraftSuccessModal">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccesssavedraftModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessagesavedraft}}</p>
                <button type="button" id="saveDraftOk" (click)="okSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!--  Console log error Modal-->
<div class="modal" tabindex="-1" id="consoleLogerror" [ngStyle]="{'display':displayConsoleLogerror}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayConsoleLogError">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="ondefaultLogError()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <div class="fs-16">
                    <h5>Sorry, something went wrong.</h5><br>
                    <h5>To continue please logout, close the browser, login again and resume the work on
                        this letter.</h5>
                    <h5>If any problem occurs again in this letter, please take a full screen screenshot
                        showing complete browser along with date time and share with IT Support.</h5>
                </div>
                <button type="button" id="LogErrorOk" (click)="ondefaultLogError()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="add-attachment" [ngStyle]="{'display':displayAddAttachment}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable upload-attachment">
        <div class="modal-content p-4" id="add-attachment">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0 mb-md-2">Upload Attachments
                    <span class="fs-12 label-color fw-regular">(Maximum of “5” attachments can be
                        added)</span>
                </h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeAttachmentPopup()"></button>
            </div>
            <div class="d-inline-flex">
                <span class="err-color">Note:</span>
                <ul class="ps-4">
                    <li class="label-color">Based on the attachment type (Letter or Email), System will
                        attach the attachments with letter or email.
                    </li>
                    <li class="label-color">If the attachment is marked as Letter, then it will be attached
                        with the letter.

                    </li>
                    <li class="label-color">If the attachment is marked as Email, then it will be attached
                        with the signing email and not with the letter.

                    </li>
                </ul>
            </div>
            <div class="modal-body p-0">
                <div class="p-2 border rounded h-100">

                    <div class="files-list py-3">
                        <table class="table new-implement-attachment">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th class="table-header-w-200">Name</th>
                                    <th>Level</th>
                                    <th>Attach with</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of staticRows; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td class="table-cell-ellipse" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="{{ uploadedFiles[i]?.Filename || '' }}">{{ uploadedFiles[i]?.Filename ||
                                        '' }}</td>
                                    <td class="table-cell-ellipse">{{ uploadedFiles[i]?.Filename ?
                                        uploadedFiles[i].Level : '' }}</td>

                                    <td>

                                        <label *ngIf="uploadedFiles[i]?.Filename" class="mx-2">
                                            <input type="radio" name="AttachWith{{i}}"
                                                [(ngModel)]="uploadedFiles[i].AttachWith" value="Email"
                                                class="form-check-input"> Email
                                        </label>
                                        <label *ngIf="uploadedFiles[i]?.Filename" class="mx-2">
                                            <input type="radio" name="AttachWith{{i}}"
                                                [(ngModel)]="uploadedFiles[i].AttachWith" value="Letter"
                                                class="form-check-input"> Letter
                                        </label>

                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="position-relative file-upload">
                                                <input type="file" id="upload-btn-{{i}}" class="visually-hidden"
                                                    (change)="onFileSelected($event, i)" accept=".pdf"
                                                    [disabled]="uploadedFiles[i]?.Filename">

                                                <!-- Label for input file element, which acts as the clickable area -->
                                                <label for="upload-btn-{{i}}" class="uploadLink"
                                                    style="margin-right: 7px;">
                                                    <i class="fa fa-plus-circle" aria-hidden="true"
                                                        [ngClass]="{'disabled-icon': uploadedFiles[i]?.Filename, 'fa-plus-circle': !uploadedFiles[i]?.Filename}"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Add">
                                                    </i>
                                                </label>
                                            </div>
                                            <ng-container *ngIf="uploadedFiles[i]?.Filename">
                                                <a class="link-color text-decoration-none fw-medium"
                                                    (click)="uploadedFiles[i] && openAttachmentPreview(uploadedFiles[i])"
                                                    style="margin-right: 10px;" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Preview">
                                                    <i class="fas fa-eye" aria-hidden="true"></i>
                                                </a>
                                                <i class="fa fa-trash" aria-hidden="true"
                                                    (click)="uploadedFiles[i] && removeAttachment(i)"
                                                    style="cursor:pointer;" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Delete"></i>
                                            </ng-container>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pb-0 pt-3 px-0">
                <p class="note" style="margin-right: 172px;"><i
                        class="fa-solid fa-triangle-exclamation "></i>&nbsp;<b>Note:
                        <span class="text-danger"> Do not</span> send any
                        PDFs with sensitive
                        information</b></p>
                <div>
                    <button type="button" class="btn p-btn-outline me-3" data-bs-dismiss="modal"
                        (click)="closeAttachmentPopup()">Close</button>
                    <!-- <button type="button" id="submitFileUpload" class="btn p-btn"
                        (click)="submitUploadedFile()">Submit</button> -->
                    <button type="button" id="submitFileUpload" class="btn p-btn"
                        (click)="submitBulkUploadedFile()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="preview_doc" [ngStyle]="{'display':displayPreviewdocument}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header border-0 justify-content-end">
                <button type="button" class="btn-close mx-3 mt-2 shadow-none" data-bs-dismiss="modal"
                    (click)="closeAttachmentPreview()"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="template_preview_panel doc_preview">
                                <div class="preview_body h-100">
                                    <pdf-viewer class="customtaxlayerremove" #docPdfViewer [src]="this.pdfsrcdoc"
                                        [stick-to-page]="true" [show-borders]="true" [render-text]="true"
                                        style="display: block;width:100%;height:calc(100vh - 180px);position: relative;">
                                    </pdf-viewer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- update Success Modal -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displayupdateSuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="successUpdate">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="updateSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">
                    {{updatesuccessMessage}}
                </p>
                <button type="button" id="UpdateSuccessOk" (click)="updateSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>

            </div>
        </div>
    </div>
</div>
<!-- Idle time confirmation popup -->
<div class="modal" tabindex="-1" id="backLogModal" [ngStyle]="{'display':displayIdleConfirm}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="displayIdleConfirm">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Redirect</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeIdlePopup()"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">You have been inactive after creating a batch of letters. Please press
                    "Continue in this screen" or "Close" to continue to use this batch, otherwise this batch will be
                    closed and redirected to dashboard in 10minutes.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal"
                    (click)="closeIdlePopup()">Continue in this screen</button>
                <button type="button" class="btn p-btn ms-2" (click)="SaveAndRedirect('click')">Save & redirect</button>
            </div>
        </div>
    </div>
</div>

<!-- Discard & Go Back to Batch List  Modal -->
<div class="modal" tabindex="-1" id="discardGoBack" [ngStyle]="{'display':displaydiscardgoback}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="discardGoBack">
            <div class="modal-header">
                <h5 class="modal-title fs-18 fw-semibold highlight">Confirm Redirect</h5>
                <button type="button" class="btn-close shadow-none" (click)="DiscardbatchlistNo()"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to discard & go back to batch list? <br><br>Note:
                    Discarding and going back will delete this batch.
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" (click)="DiscardbatchlistNo()">No</button>
                <button type="button" class="btn p-btn ms-2" (click)="BacktodiscardgobackYes()">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Display letter preview process-->

<div class="modal" id="viewtemplate" [ngStyle]="{'display':displayPreviewtemplate}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content" id="viewChooseParatemplate">
            <div class="modal-header border-0 px-4 py-3">
                <div class="col-12 col-sm-6 col-md-6">
                    <div class="d-flex align-items-center h-100 px-2">
                        <h5 class="text-dark fw-bold m-0">Choose Paragraphs</h5>
                    </div>
                </div>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    alt="close" (click)="closetemplatePreview()"></button>

            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 ">
                            <div class="para border h-100">
                                <div class="template_preview_panel ">
                                    <div class="preview_header">
                                        <h5 class="fw-bold text-dark fs-6 ">Choose Paragraphs</h5>
                                    </div>
                                    <div class="preview_body h-100 p-3 preview_body_scroll"
                                        #templateChooseParagraphScrollPosition>
                                        <div id="previewquestion" #previewques class=" new_height_align">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 ">
                            <div class='border h-100'>
                                <div class="template_preview_panel"
                                    [ngClass]="togglefullscreen ? 'panel-fullscreen' : ''">
                                    <div class="preview_header">
                                        <h5 class="fw-bold text-dark fs-6">Letter Preview</h5>
                                    </div>
                                    <div class="preview_body h-100 preview_body_scroll"
                                        #templateLetterPreviewScrollPosition>
                                        <div id="previewarea" #previewarea class="preview_inner new_height_align  p-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
            <div class="modal-footer">
                <div class="d-flex justify-content-lg-end gap-3 flex-wrap">
                    <button type="button" (click)="closetemplatePreview()" class="btn p-btn-outline">Close</button>

                    <button class="btn p-btn" id="submitConfirmQuestion" (click)="confirmQuestionaire()">Submit</button>

                </div>
            </div>
        </div>
    </div>

</div>

<!-- Display Wizard and Edits Blocks preview process-->

<div class="modal" id="viewtemplate" [ngStyle]="{'display':displayEditblockpreview}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content" id="viewChooseTemplate">
            <!-- Modal Header -->
            <div class="modal-header border-0 px-3 py-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="d-flex align-items-center h-100">
                                <h6 class="text-dark fw-bold m-0">Choose Paragraphs</h6>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 px-2">
                            <div class='text-end'>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close" alt="close" (click)="closeblockpreview()"></button>
                            </div>
                            <div class="d-flex justify-content-between align align-items-center flex-wrap">
                                <div *ngIf="!notToShowClientIdClientName">
                                    <p class="fw-bold mb-1">Client ID: <span class="fw-medium px-2">
                                            {{this.clientId}}</span>
                                    </p>
                                    <p class="fw-bold mb-1">Client Name: <span class="fw-medium px-2">
                                            {{this.ClientName}}</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="border h-100 para">
                                <div class="template_preview_panel">
                                    <div class="preview_header">
                                        <h5 class="text-dark fw-bold fs-6">Choose Paragraphs</h5>
                                    </div>
                                    <div class="preview_body h-100 p-3 preview_body_scroll"
                                        #ChooseParagraphScrollPosition>
                                        <div id="previewquestionblock" #previewquestionblock class=" new_height_align">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="border h-100">
                                <div class="template_preview_panel"
                                    [ngClass]="togglefullscreen ? 'panel-fullscreen' : ''">
                                    <div class="preview_header">
                                        <h5 class="text-dark fw-bold fs-6">Letter Preview</h5>
                                    </div>
                                    <div class="preview_body h-100 preview_body_scroll"
                                        #ChooseParagraphLetterPreviewScrollPosition>
                                        <div id="previewareablock" #previewareablock
                                            class="preview_inner new_height_align  p-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">

                <div>
                    <div class="d-flex justify-content-lg-end gap-3 flex-wrap">
                        <!-- <button type="button"
                            (click)="Wizardresponseback(this.clientId,this.ClientName,this.bulklLetterId)"
                            class="btn p-btn-outline">Back</button> -->
                        <button type="button" (click)="closeblockpreview()" class="btn p-btn-outline">Close</button>
                        <button type="button" id="submitQuestionBlocks" (click)="QuestionsBlocksubmit()"
                            class="btn p-btn">Submit</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="viewtemplate" [ngStyle]="{'display':displayEditblockpreviewquestion}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content" id="viewEditParatemplate">
            <!-- Modal Header -->
            <div class="modal-header border-0 px-4">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="d-flex align-items-center h-100 px-2">
                                <h6 class="text-dark fw-bold m-0">Edit Paragraphs</h6>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 px-2">
                            <div class='text-end'>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close" alt="close" (click)="closeblockpreviewquestion()"></button>
                            </div>
                            <div class="d-flex justify-content-between align align-items-center flex-wrap">
                                <div *ngIf="!notToShowClientIdClientName">
                                    <p class="fw-bold mb-1">Client ID: <span class="fw-medium px-2">
                                            {{this.clientId}}</span>
                                    </p>
                                    <p class="fw-bold mb-1">Client Name: <span class="fw-medium px-2">
                                            {{this.ClientName}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body" #EditParagraphScrollPosition>
                <div class="container-fluid">


                    <div class="col-md-12 ">
                        <div class="template_preview_panel">
                            <div class="preview_body h-100">
                                <div class="blockinner edit_preview new_height_align" #blockinner id="blockinner">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <!-- <div *ngIf="currentView === 'Wizard'">
                    <div class="d-flex justify-content-lg-end gap-3 flex-wrap">
                        <button type="button" (click)="closeblockpreview()" class="btn p-btn-outline">Close</button>
                        <button type="button" (click)="Blocks()" class="btn p-btn">Edit Paragraph</button>
                    </div>
                </div> -->

                <div class="d-flex justify-content-lg-end gap-3 flex-wrap">
                    <button type="button" (click)="closeblockpreviewquestion()" class="btn p-btn-outline">Close</button>
                    <button type="button" id="submitEditPara" (click)="bulkEditParagraphSubmit()"
                        class="btn p-btn">Submit</button>

                </div>

            </div>
        </div>
    </div>
</div>



<!--Preview Letter Modal start here -->
<div id="viewletter" class="modal" [class.shadow-popup]="thirdPopupOpen" tabindex="-1" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayCkeditor}">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header border-0 p-3 pb-0">
                <!-- <button type="button" (click)="closeeditor()" class="close mx-3 mt-2" data-dismiss="modal">×</button> -->
                <button type="button" class="btn-close shadow-none" (click)="closeeditor()" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="m-0">
                                Block Name:
                                <span class="fw-bold"> {{ this.editjsonvalue.BlockName }}</span>
                            </p>
                        </div>
                    </div>

                    <div class=" row no-gutters">
                        <div class="col-12 col-sm-12 col-md-12 mt-3" #editornew>
                            <div class=" edit_content_preview">
                                <ckeditor [(data)]="this.editjsonvalue.Content" tagName="textarea"
                                    bodyClass='document-editor' [config]="config" (ready)="onReady( $event)"
                                    (change)="onChangeevent( $event )" (focus)="onFocus( $event )" (key)="onkey($event)"
                                    (blur)="onBlur( $event )" (paste)="onPaste( $event )" (keyup)="onkeyDown($event)"
                                    (afterPaste)="onAfterPaste( $event )" (dragStart)="( $event )"
                                    (dragEnd)="onDragEnd( $event )" (drop)="onDrop( $event )"> </ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0">
                <form [formGroup]="updateForm">
                    <div class="form-group">
                        <!-- onDragStart  -->
                        <div class="text-end bg-white me-md-3">
                            <button type=" button" (click)="closeeditor()" class="btn p-btn-outline">Cancel</button>
                            <button type="button" (click)="updateblockcontent()" class="btn p-btn ms-3">Update</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="model-action">

                <a class=" action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
                        alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="model_title">Preview Template</h5>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <div class="template_preview_panel docpreview">
                                <div class="preview_header">
                                    <h5> Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100">

                                    <div id="signingblock" #siginingcontent>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- progress fetch modal -->
<div class="modal" tabindex="-1" id="progressBarModal" [ngStyle]="{'display':displayprogressBarModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="progressBarModal">
            <div class="modal-header justify-content-center">
                <h5 class="modal-title fw-semibold highlight">Loading client details</h5>
            </div>
            <div class="modal-body">
                <p-progressBar *ngIf="progressbarlist && progressValue <= 100" [value]="progressValue">
                </p-progressBar>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="CreateFormEdit" tabindex="-1" aria-modal="true" role="dialog"
    [ngStyle]="{'display':FormEditCkeditor}" [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog  modal-dialog-centered  modal-lg" id="CreateEdit7216ModalDialog">
        <div class="modal-content p-4" id="Edit7216">
            <div class="modal-header border-0 p-0">
                <h5 class="block_title ">Edit 7216 Provider<span class="mr-2"></span>
                </h5>
                <button type="button" class="btn-close shadow-none" (click)="closeeditor()" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body p-0">
                <div class="row no-gutters">
                    <div class="col-12 col-sm-12 col-md-12 mt-3" #editornew>
                        <div class=" edit_content_preview">
                            <ckeditor [(data)]="this.formeditjsonvalue.Content" tagName="textarea"
                                bodyClass='document-editor' [config]="config" (ready)="onReady( $event)"
                                (change)="onChangeevent( $event )" (focus)="onFocus( $event )" (key)="onkey($event)"
                                (blur)="onBlur( $event )" (paste)="onPaste( $event )" (keyup)="onkeyDown($event)"
                                (afterPaste)="onAfterPaste( $event )" (dragStart)="onDragStart( $event )"
                                (dragEnd)="onDragEnd( $event )" (drop)="onDrop( $event )">
                            </ckeditor>
                            <div class="modal-footer border-0 p-0 d-flex flex-column align-items-start">
                                <div class="d-flex w-100 mt-2">
                                    <h6 class="block_title Editing7216" style="
                                    margin-bottom: 0px;">Note:&nbsp;</h6>
                                    <span>This change will be applied to all the letters which has 7216 in this
                                        batch</span>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-md-12">
                                    <form [formGroup]="updateFormEdit">
                                        <div class="row">
                                            <div class="mt-3 mt-md-3 text-end sticky-bottom bg-white">
                                                <button type="button" (click)="closeeditor()"
                                                    class="btn p-btn-outline">Cancel</button>
                                                <button type="button" (click)="updateFormEdit7216()"
                                                    class="btn p-btn ms-3">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Error FormEdit7216 Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':FE7216ErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="FE7216ErrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeErrorModal()" class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>