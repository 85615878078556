<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>

        <main class="main-content p-4" style="height: auto;" id="maintoggledrop" #maintoggledrop>
            <div class="d-inline-flex">
                <span class="err-color">Note:</span>
                <p class="label-color ms-1">All the fields are mandatory.
                </p>
            </div>
            <section class="main_content">
                <ngx-spinner size="medium" [type]="typeSelected">
                    <p style="color: white">{{percentageMessage}}</p>
                </ngx-spinner>
                <form [formGroup]="createTemplateForm">
                    <div class="template" id="addtemplate">
                        <div class=" bg-white px-4 py-3 rounded mb-2 mb-md-4">
                            <h5 class="highlight mb-lg-1 fs-18 fw-semibold">Add New Templates</h5>

                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">ID
                                        </label>
                                        <input type="number" formControlName="TemplateId" class="form-control"
                                            id="exampleFormControlInput1" placeholder="" value="{{this.count}}"
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Department
                                            <span class="err-color">*</span></label>
                                        <ng-select [items]="departmentList" formControlName="Department" class=""
                                            bindLabel="Department" bindValue="DepartmentId" placeholder="Select"
                                            [(ngModel)]="selectedDepartment" name="carss" [clearable]="false"
                                            (ngModelChange)="getEngageType($event)">
                                        </ng-select>
                                        <ng-container *ngFor="let validation of validation_messages.Department">
                                            <div class="errorvl"
                                                *ngIf="createTemplateForm.get('Department').hasError(validation.type) && (createTemplateForm.get('Department').dirty || createTemplateForm.get('Department').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Type
                                            <span class="err-color">*</span></label>
                                        <ng-select [items]="engageTypeList" formControlName="Type" class=""
                                            bindLabel="EngageTypeName" bindValue="EngageTypeId" placeholder="Select"
                                            [(ngModel)]="selectedType" name="carss" [clearable]="false">
                                        </ng-select>
                                        <ng-container *ngFor="let validation of validation_messages.Type">
                                            <div class="errorvl"
                                                *ngIf="createTemplateForm.get('Type').hasError(validation.type) && (createTemplateForm.get('Type').dirty || createTemplateForm.get('Type').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Template Name

                                            <span class="err-color">*</span></label>
                                        <input type="text" formControlName="TemplateName" class="form-control"
                                            id="exampleFormControlInput1" placeholder="Enter Template Name">
                                        <ng-container *ngFor="let validation of validation_messages.TemplateName">
                                            <div class="errorvl"
                                                *ngIf="createTemplateForm.get('TemplateName').hasError(validation.type) && (createTemplateForm.get('TemplateName').dirty || createTemplateForm.get('TemplateName').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Template
                                            Description
                                            <span class="err-color">*</span></label>
                                        <textarea type="text" formControlName="Description"
                                            class="form-control input-md no-resize" id="exampleFormControlInput1"
                                            placeholder="Enter Template Description"></textarea>
                                        <ng-container *ngFor="let validation of validation_messages.Description">
                                            <div class="errorvl"
                                                *ngIf="createTemplateForm.get('Description').hasError(validation.type) && (createTemplateForm.get('Description').dirty || createTemplateForm.get('Description').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Change Notes
                                            <span class="err-color">*</span></label>
                                        <textarea type="text" formControlName="ChangeNotes"
                                            class="form-control input-md no-resize" id="exampleFormControlInput1"
                                            placeholder="Enter Change Notes"></textarea>
                                        <ng-container *ngFor="let validation of validation_messages.ChangeNotes">
                                            <div class="errorvl"
                                                *ngIf="createTemplateForm.get('ChangeNotes').hasError(validation.type) && (createTemplateForm.get('ChangeNotes').dirty || createTemplateForm.get('ChangeNotes').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Status
                                            <span class="err-color">*</span></label>
                                        <ng-select [items]="statusList" formControlName="Status" class=""
                                            bindLabel="StatusName" bindValue="StatusId" placeholder="Select"
                                            [(ngModel)]="selectedStatus" name="carss" [clearable]="false">
                                        </ng-select>
                                        <ng-container *ngFor="let validation of validation_messages.Status">
                                            <div class="errorvl"
                                                *ngIf="createTemplateForm.get('Status').hasError(validation.type) && (createTemplateForm.get('Status').dirty || createTemplateForm.get('Status').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Attachment Count
                                            <span class="err-color">*</span></label>
                                        <input type="number" formControlName="AttachmentCount" class="form-control"
                                            id="exampleFormControlInput1" placeholder="Enter Attachment Count" readonly
                                            disabled>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">7216 Available?
                                            <span class="err-color">*</span></label>
                                        <div class="d-flex gap-2">
                                            <input id="male" type="radio" class="custom-control-input" [value]="true"
                                                name="Available7216" formControlName="Is7216Available">
                                            <label class="custom-control-label" for="male">Yes</label>
                                            <input id="male" type="radio" class="custom-control-input" [value]="false"
                                                name="Available7216" formControlName="Is7216Available">
                                            <label class="custom-control-label" for="male">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-9">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">How Many Client
                                            Signatures?
                                            <span class="err-color">*</span></label>
                                        <div class="d-flex gap-2">
                                            <input id="male" type="radio" class="custom-control-input" [value]="1"
                                                name="ClientSignature" formControlName="ClientSignature">
                                            <label class="custom-control-label" for="male">1 Client Signatures</label>
                                            <input id="male" type="radio" class="custom-control-input" [value]="2"
                                                name="ClientSignature" formControlName="ClientSignature">
                                            <label class="custom-control-label" for="male">2 Client Signatures</label>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-12">
                                <div class="bg-white rounded px-4 py-3">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h5 class="highlight mb-lg-3 fs-18 fw-semibold">Assembled Blocks</h5>
                                        </div>
                                        <div class="col-md-6">
                                            <h5 class="highlight mb-lg-3 me-5 fs-18 fw-semibold text-center">
                                            </h5>
                                        </div>

                                    </div>
                                    <div class="block-list">
                                        <section class="survey_section">
                                            <div class="container-fluid p-0">
                                                <div id="surveyCreatorContainer">

                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-12 col-lg-4 mt-2 mt-lg-2">
                            <div class="bg-white rounded px-4 py-3">
                                <h5 class="highlight mb-lg-3 fs-18 fw-semibold">List of Blocks</h5>
                                <div class="block-list">
                                    <ul class="list-unstyled p-0 m-0">
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png"
                                                    alt="Drag-icon"></span>Tax-Entity 1.01
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Foreign Acct - 1.08
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Record Retention-1.01
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png"
                                                    alt="Drag-icon"></span>Tax-Entity 1.01
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Foreign Acct - 1.08
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Record Retention-1.01
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png"
                                                    alt="Drag-icon"></span>Tax-Entity 1.01
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Foreign Acct - 1.08
                                        </li>
                                        <li class="py-2 "><span class="me-2"><img
                                                    src="./assets/images/vertical-drag-icon.png" alt="Drag-icon"></span>
                                            Uni - Record Retention-1.01
                                        </li>


                                    </ul>

                                </div>
                            </div>
                        </div> -->
                        </div>
                    </div>
                    <div class="mt-3 mt-lg-4 d-flex flex-wrap justify-content-lg-end gap-3">
                        <button type="button" class="btn p-btn-outline" (click)="openBackToGrid()">Back To Grid</button>
                        <button type="button" class="btn p-btn-outline" (click)="openClearPopup()">Clear</button>
                        <button type="button" class="btn p-btn-outline" (click)="listOfBlocks()">List of Blocks</button>
                        <button type="button" class="btn p-btn-outline" data-bs-toggle="modal"
                            data-bs-target="#add-attachment" (click)="Addattachment()"> Add Attachments</button>
                        <button type="button" class="btn p-btn-outline" (click)="templatePreview()">Preview</button>
                        <button type="button" class="btn p-btn" [disabled]="(!createTemplateForm.valid)"
                            (click)="saveTemplate()">Save</button>
                    </div>
                </form>
            </section>


        </main>
    </div>
    <div [innerHTML]="trustedHtml"></div>
</section>
<!--Preview Template Modal start here -->
<div class="modal" id="viewtemplate" [ngStyle]="{'display':displayPreview}">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header border-0">
                <h5 class="model_title text-dark fw-medium m-0">Preview Template</h5>

                <a (click)="closePreview()" class="action_icon mt-2 mx-3" data-dismiss="modal"><img
                        src="assets/images/close.svg" class="img-fluid" alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row">

                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="template_preview_panel border h-100" #fullscreen>
                                <div class="preview_header">
                                    <h5 class="text-dark fw-bold">Question Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100 ">
                                    <div class="p-3">
                                        <div id="previewquestion" class="new_height_align">
                                            <!-- <p>What type of letter ?</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="template_preview_panel border h-100"
                                [ngClass]="togglefullscreen ? 'panel-fullscreen' : ''">
                                <div class="preview_header">
                                    <h5>Block Preview Panel</h5>
                                    <!-- <a (click)="settingsTemplate()" class="btn btn-primary action_btn" data-dismiss="modal" data-toggle="modal" data-target="#pagetemplate"><img src="assets/images/review.svg" class="img-fluid" alt="close" />Document Preview</a>	 -->
                                    <!-- <a (click)="addFullscreenpanel()" id="panel-fullscreen" role="button" title="Toggle fullscreen">
                                        <span  class="glyphicon-resize-full"><img class="compress-icon" src="assets/images/icon-compress-white.svg" alt="compress-icon"/>
                                        <img class="expand-icon" src="assets/images/icon-expand-blue.svg" alt="compress-icon"/>
                                        </span></a>										 -->
                                </div>
                                <div class="preview_body h-100">
                                    <div class="p-3">
                                        <div id="previewarea" #previewarea class="preview_inner new_height_align"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center mt-3">
                            <!-- <a (click)="closePreview()" class="btn btn-third mx-2" data-dismiss="modal">Close</a>
                            <a class="btn btn-secondary mx-2">Edit</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Logout Modal -->
<div class="modal" tabindex="-1" id="logOutModal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Logout</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you Sure want to Logout</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal">No</button>
                <button type="button" class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>


<div class="modal " tabindex="-1" id="add-attachment" [ngStyle]="{'display':displayAddAttachment}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable upload-attachment">
        <div class="modal-content p-4">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0 mb-md-2">
                    Upload Attachments
                    <span class="fs-12 label-color fw-regular">(Maximum of “5” attachments can be added)</span>
                </h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeAttachmentPopup()"></button>
            </div>
            <div class="d-inline-flex">
                <span class="err-color">Note:</span>
                <ul class="ps-4">
                    <li class="label-color">Based on the attachment type (Letter or Email), System will
                        attach the attachments with letter or email.
                    </li>
                    <li class="label-color">If the attachment is marked as Letter, then it will be attached
                        with the letter.

                    </li>
                    <li class="label-color">If the attachment is marked as Email, then it will be attached
                        with the signing email and not with the letter.

                    </li>
                </ul>
            </div>
            <div class="modal-body p-0">

                <div class="p-2 border rounded h-100">
                    <div *ngIf="showValidationMessage" class="alert alert-danger" role="alert">
                        Please select "Attach with" option for all uploaded files.
                    </div>

                    <div class="files-list py-3">
                        <table class="table new-implement-attachment">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th class="table-header-w-200">Name</th>
                                    <th>Level</th>
                                    <th>Attach with</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of staticRows; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td class="table-cell-ellipse" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="{{ uploadedFiles[i]?.Filename || '' }}">{{ uploadedFiles[i]?.Filename ||
                                        '' }}</td>

                                    <td class="table-cell-ellipse">{{ uploadedFiles[i]?.Filename ?
                                        uploadedFiles[i].Level : '' }}</td>
                                    <td>

                                        <label *ngIf="uploadedFiles[i]?.Filename" class="mx-2">
                                            <input type="radio" name="AttachWith{{i}}"
                                                [(ngModel)]="uploadedFiles[i].AttachWith" value="Email"
                                                [disabled]="true" class="form-check-input"> Email
                                        </label>
                                        <label *ngIf="uploadedFiles[i]?.Filename" class="mx-2">
                                            <input type="radio" name="AttachWith{{i}}"
                                                [(ngModel)]="uploadedFiles[i].AttachWith" value="Letter"
                                                [checked]="uploadedFiles[i].AttachWith === 'Letter'"
                                                class="form-check-input"> Letter
                                        </label>

                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="position-relative file-upload">
                                                <input type="file" id="upload-btn-{{i}}" class="visually-hidden "
                                                    (change)="onFileSelected($event, i)" accept=".pdf"
                                                    [disabled]="uploadedFiles[i]?.Filename">

                                                <!-- Label for input file element, which acts as the clickable area -->
                                                <label for="upload-btn-{{i}}" class="uploadLink"
                                                    style="margin-right: 7px;">
                                                    <i class="fa fa-plus-circle" aria-hidden="true"
                                                        [ngClass]="{'disabled-icon': uploadedFiles[i]?.Filename, 'fa-plus-circle': !uploadedFiles[i]?.Filename}"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Add">
                                                    </i>
                                                </label>
                                            </div>
                                            <ng-container *ngIf="uploadedFiles[i]?.Filename">
                                                <a class="link-color text-decoration-none fw-medium"
                                                    (click)="uploadedFiles[i] && openAttachmentPreview(uploadedFiles[i])"
                                                    style="margin-right: 10px;">
                                                    <i class="fas fa-eye" aria-hidden="true" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Preview"></i>
                                                </a>
                                                <i class="fa fa-trash" aria-hidden="true"
                                                    (click)="uploadedFiles[i] && removeAttachment(i) "
                                                    style="cursor:pointer;" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Delete"></i>
                                            </ng-container>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pb-0 pt-3 px-0">
                <p class="note" style="margin-right: 172px;"><i
                        class="fa-solid fa-triangle-exclamation "></i>&nbsp;<b>Note:
                        <span class="text-danger"> Do not</span> send any
                        PDFs with sensitive
                        information</b></p>

                <div>

                    <button type="button" class="btn p-btn-outline me-3" data-bs-dismiss="modal"
                        (click)="closeAttachmentPopup()">Close</button>
                    <button type="button" class="btn p-btn" (click)="submitUploadedFile()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="preview_doc" [ngStyle]="{'display':displayPreviewdocument}">
    <div class="modal-dialog modal-xl">

        <div class="modal-content">
            <div class="modal-header justify-content-end border-0">
                <button type="button" class="btn-close mx-3 mt-2 shadow-none" data-bs-dismiss="modal"
                    (click)="closeAttachmentPreview()"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="template_preview_panel doc_preview">
                                <div class="preview_body h-100">
                                    <pdf-viewer class="customtaxlayerremove" #docPdfViewer [src]="this.pdfsrcdoc"
                                        [stick-to-page]="true" [show-borders]="true" [render-text]="true"
                                        style="display: block;width:100%;height:calc(100vh - 180px);position: relative;">
                                    </pdf-viewer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="model-action">

                <a class=" action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
                        alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="model_title">Preview Template</h5>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <div class="template_preview_panel docpreview">
                                <div class="preview_header">
                                    <h5> Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100">

                                    <div id="content" #content>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="editletter" [ngStyle]="{'display':displayTemplatePreview}">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content" style="height: 100%;">
            <div class="modal-header justify-content-end border-0">
                <button type="button" (click)="closeTemplatePreview()" class="btn-close mx-3 mt-2"
                    data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid" style="height: 100%;">
                    <div class="row" style="height: 100%;">

                        <div class="col-12 col-sm-12 col-md-12" style="height: 100%;">
                            <div class="" style="height: 100%;">

                                <div class="preview_body" style="height: 100%;">

                                    <div *ngIf="this.pdfSrc" style="height: 100%;">
                                        <!-- <iframe id="stream_iframe"
                                            src='https://catsstep.education/public/engage/16d68897-769f-4ffb-b579-e9439254c230.pdf'
                                            width="100%" webkitallowfullscreen mozallowfullscreen allowfullscreen
                                            style="height: 100%;"></iframe> -->

                                        <iframe id="stream_iframe" [src]="pdfSrc" width="100%" webkitallowfullscreen
                                            mozallowfullscreen allowfullscreen style="height: 100%;"></iframe>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Error Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error
                </h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeErrorModal()" class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal -->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">
                    Success</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeSuccessModal()"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button type="button" class="success-btn" data-bs-dismiss="modal"
                    (click)="closeSuccessModal()">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- Warning Modal -->
<div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">
                    Warning</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeWarningModal()"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">{{warningMessage}}</p>
                <button type="button" class="warning-btn" data-bs-dismiss="modal"
                    (click)="closeWarningModal()">Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="clearLogModal" [ngStyle]="{'display':displayClear}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Clear</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeClearPopup()"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to clear all the
                    inputs provided in the screen?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal"
                    (click)="closeClearPopup()">No</button>
                <button type="button" class="btn p-btn ms-2" (click)="onClearForm()">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Modal -->
<div class="modal" tabindex="-1" id="backLogModal" [ngStyle]="{'display':displayBack}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Confirm Close</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="cancelBackToGrid()"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to discard and
                    close?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" data-bs-dismiss="modal"
                    (click)="cancelBackToGrid()">No</button>
                <button type="button" class="btn p-btn ms-2" (click)="backToGrid()">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal Upload-->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModalUpload}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">
                    Success</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeSuccessModalUpload()"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessageUpload}}</p>
                <button type="button" class="success-btn" data-bs-dismiss="modal"
                    (click)="closeSuccessModalUpload()">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- listOfBlocks -->

<div class="modal" tabindex="-1" id="add-attachment" [ngStyle]="{'display':displayListsOfBlock}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content p-4 rounded h-200">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-bold m-0 mb-md-2">List of
                    Blocks (Block Name)</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closelistOfBlocks()"></button>
            </div>
            <div class="row">
                <div class="col-md-3 col-lg-6 fw-bold">Template Id:<span style="color:blue">&nbsp;{{this.count}}</span>
                </div>
                <div class="col-md-3 col-lg-6 fw-bold">Template Name:<span *ngIf="templateName"
                        style="color:blue">&nbsp;{{templateName}}</span>
                </div>
            </div><br>
            <div class="modal-body p-0">
                <div class="p-3 border rounded" id="tempBlocklists"
                    style="min-height: 200px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <div class="col-md-6 col-lg-12">
                        <div *ngIf="tempBlocklist?.length > 10" style="max-height: 200px; overflow: auto;">
                            <ol>
                                <li *ngFor="let tempList of tempBlocklist">
                                    {{tempList.name}}</li>
                            </ol>
                        </div>
                        <div *ngIf="tempBlocklist?.length <= 10">
                            <ol>
                                <li *ngFor="let tempList of tempBlocklist">
                                    {{tempList.name}}</li>
                            </ol>
                        </div>

                    </div>
                    <div class="col-md-6 col-lg-12 d-flex justify-content-center align-items-center">
                        <div class="mb-2 mb-md-3 mb-lg-4">
                            <div style="text-align:center"
                                *ngIf="tempBlocklist?.length === 0 || tempBlocklist?.length === null">
                                <p class="highlight fs-5">No Blocks selected Yet</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)=" closelistOfBlocks()" class="btn p-btn-outline"
                        data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>