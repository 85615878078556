import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicelinksService } from '../shared/servicelinks.service';
import { Subscription } from 'rxjs';
import { DataService } from './data.service';

let headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'responseType': 'json'
});
let options = {
  headers: headers
}

@Injectable({
  providedIn: 'root'
})


export class TemplateService {
  getheadersSub: Subscription;
  getHeaders: any;
  postHeaders: any;
  postheadersSub: Subscription;
  constructor(private servicelinks: ServicelinksService, private http: HttpClient, public dataService: DataService) {
    this.getheadersSub = this.dataService.getHeadService.subscribe(message => this.getHeaders = message);
    this.postheadersSub = this.dataService.postHeaderService.subscribe(message => this.postHeaders = message);
  }

  getAllTemplateList(): Promise<any> {
    let url = this.servicelinks.templatelistUrl;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }

  // deletetemplate(id: any): Promise<any> {

  //   let url = this.servicelinks.deletetemplateUrl;
  //   console.log(url);
  //   console.log(id);
  //   return new Promise<any>((resolve, reject) => {
  //     this.http.delete(url + id).subscribe(res => {
  //       console.log(res);
  //       resolve(res);
  //     }, err => {
  //       console.log(err);
  //       reject(err);
  //     });
  //   });
  // }

  getDepartmentIdEngageGroup(departmentId: number): Promise<any> {
    let url = this.servicelinks.getDepartmentIdEngageGroup;
    return new Promise((resolve, reject) => {
      this.http.get(`${url}/${departmentId}`)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }
  detailsTemplate(Id: number): Promise<any> {
    console.log(Id);
    let url = this.servicelinks.detailstemplateUrl;
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${url}/${Id}`)
        .subscribe(res => {
          console.log(res);
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        });
    });

  }

  updateTemplate(data: any): Promise<any> {

    let url = this.servicelinks.templateupdateUrl;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  getEngageGroupIdToType(GroupId: number): Promise<any> {
    let url = this.servicelinks.getengageGroupToTypeUrl;
    return new Promise((resolve, reject) => {
      this.http.get(`${url}/${GroupId}`)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }


  getEngageTypeToBasic(TypeId: number): Promise<any> {
    let url = this.servicelinks.getengageBasicUrl;
    return new Promise((resolve, reject) => {
      this.http.get(`${url}/${TypeId}`)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }

  listtemplateiddata(Data: any): Promise<any> {
    let url = this.servicelinks.templateidUrl;
    console.log(url);
    console.log(Data);
    return new Promise<any>((resolve, reject) => {
      this.http.get(url, Data).subscribe(res => {
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  getAllDepartmentList(): Promise<any> {
    let url = this.servicelinks.ddepartmentlistUrl;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((responsesites: any) => {
          resolve(responsesites);
        }, error => {
          reject(error);
        });
    }
    );
  }
  // saveTemplate(data: any): Promise<any> {
  //   let url = this.servicelinks.saveTemplateUrl;
  //   //  let responseBody:{} = JSON.stringify(data);
  //   console.log(url);
  //   console.log(data);
  //   //console.log(responseBody);
  //   return new Promise<any>((resolve, reject) => {
  //     this.http.post(url, data, options).subscribe(res => {
  //       // this.http.post(url, responseBody,{headers: this.headers} ).subscribe(res => { 
  //       console.log(res);

  //       resolve(res);
  //     }, err => {
  //       console.log(err);
  //       reject(err);
  //     });
  //   });
  // }

  approveTemplate(data: any): Promise<any> {
    let url = this.servicelinks.approveUrl;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  getDefaultPagesettings(): Promise<any> {
    let url = this.servicelinks.defaultpagesettingsurl;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }

  templateLogic(data: any): Promise<any> {
    let url = this.servicelinks.gettemplatelogic;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  templatejsonLogic(data: any): Promise<any> {
    let url = this.servicelinks.getjsonlogic;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  //#region get
  async getTemplateList(): Promise<any> {
    let url = this.servicelinks.getTemplateListUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getTemplateIdList(): Promise<any> {
    let url = this.servicelinks.getTemplateIdList;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getDepartmentList(): Promise<any> {
    let url = this.servicelinks.getDepartment;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getStatus(): Promise<any> {
    let url = this.servicelinks.getStatus;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getEngageTypeByID(id: number): Promise<any> {
    let url = this.servicelinks.getEngageType;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}/${id}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async getTemplateDetailsByID(id: number): Promise<any> {
    let url = this.servicelinks.getTemplateByID;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}/${id}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  getletterheadcontent(): Promise<any> {
    //let url = this.servicelinks.getsignedname;
    return new Promise((resolve, reject) => {
      this.http.get('assets/previewtemplates/letterheadcontent.html', { responseType: 'text' })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    }
    );
  }

  getwatermarkcontent(): Promise<any> {
    //let url = this.servicelinks.getsignedname;
    return new Promise((resolve, reject) => {
      this.http.get('assets/previewtemplates/watermarkcontent.html', { responseType: 'text' })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    }
    );
  }


  getletterheadcontentForTempPreview(): Promise<any> {
    //let url = this.servicelinks.getsignedname;
    return new Promise((resolve, reject) => {
      this.http.get('assets/previewtemplates/tempPreviewLetterheadContent.html', { responseType: 'text' })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    }
    );
  }
  //#endregion

  //#region Post
  async saveTemplate(data: any): Promise<any> {
    let url = this.servicelinks.saveTemplate;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }


  async updateTemplateById(data: any): Promise<any> {
    let url = this.servicelinks.templateupdateUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async templatePreview(data: any): Promise<any> {
    let url = this.servicelinks.getPreview;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async templatePreviewWithAttachment(data: any): Promise<any> {
    let url = this.servicelinks.getCombinedPDFURL;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  //#endregion

  //#region Put
  async deleteTemplate(data: any): Promise<any> {
    let url = this.servicelinks.deleteTemplate;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  //#endregion
}
