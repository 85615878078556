import { Injectable } from '@angular/core';
import { ServicelinksService } from '../shared/servicelinks.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

let headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'responseType': 'json'
});
let options = {
  headers: headers
}
@Injectable({
  providedIn: 'root'
})
export class EngagementletterService {
  getheadersSub: Subscription;
  getHeaders: any;
  postHeaders: any;
  postheadersSub: Subscription;
  constructor(private servicelinks: ServicelinksService, private http: HttpClient, public dataService: DataService) {
    this.getheadersSub = this.dataService.getHeadService.subscribe(message => this.getHeaders = message);
    this.postheadersSub = this.dataService.postHeaderService.subscribe(message => this.postHeaders = message);
  }


  //Sample Api for Common Token in headers
  async getRoles(): Promise<any> {
    //get
    let url = this.servicelinks.getroles;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders })
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          if (error.status == 401 || error.status == 400) {
            this.dataService.refreshtoken();
            setTimeout(() => {
              //this.getRoles();
              this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
                resolve(response);
              }, error => {
                resolve(error);
              })
            }, 5000);
          }
          else {
            resolve(error);
          }
        });
    }
    );


    // if (refreshtoken != null) {
    //   // let url = "https://localhost:44324/api/Login/GetRolesandScreenList";
    //   return new Promise((resolve, reject) => {
    //     this.http.get(url, { headers: this.getHeaders })
    //       .subscribe((response: any) => {
    //         localStorage.removeItem("RefreshToken");
    //         resolve(response);
    //       }, error => {
    //         reject(error);
    //       });
    //   }
    //   );
    // }
    // else {
    //   // let url = "https://localhost:44324/api/Login/GetRolesandScreenList";
    //   return new Promise((resolve, reject) => {
    //     this.http.get(url, { headers: this.getHeaders })
    //       .subscribe((response: any) => {
    //         resolve(response);
    //       }, error => {
    //         if (error.status == 401) {
    //           this.dataService.refreshtoken();
    //           setTimeout(() => {
    //             this.getRoles();
    //           }, 5000);
    //         }
    //       });
    //   }
    //   );
    // }
  }

  async getAllEngagementLetters(): Promise<any> {
    //get
    let url = this.servicelinks.allEngagementLettersUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            //this.getAllEngagementLetters();
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );

  }

  async getAllLetterDropDown(): Promise<any> {
    //get
    let url = this.servicelinks.allLetterDropdownUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            //this.getAllLetterDropDown();
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              localStorage.removeItem("RefreshToken");
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    });

  }

  async searchEngagementLetter(data: any): Promise<any> {
    //post
    let url = this.servicelinks.searchLetterUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            //this.searchEngagementLetter(data);
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });

  }

  async PostrefreshStatus(data: any): Promise<any> {
    //post
    let url = this.servicelinks.manualRefreshStatusUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            //this.searchEngagementLetter(data);
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });

  }

  async getAutoRefreshStatus(): Promise<any> {
    //get
    let url = this.servicelinks.autoRefreshStatusUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            //this.getAllLetterDropDown();
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              localStorage.removeItem("RefreshToken");
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    });

  }


  //deleteBatch
  async deleteEngagementletterById(data: any): Promise<any> {
    //delete
    let url = this.servicelinks.deleteEngagementLetterUrl;
    let refreshtoken = localStorage.getItem("RefreshToken");
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.put(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async PostcancelSigningById(data: any): Promise<any> {
    //post
    let url = this.servicelinks.cancelsigningurl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            //this.searchEngagementLetter(data);
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });

  }

  async postUploadPdf(data: any): Promise<any> {
    //post
    let url = this.servicelinks.uploadpdfurl;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            //this.searchEngagementLetter(data);
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });

  }

  async getHistorylogbyId(EngagementLetterId): Promise<any> {
    //get
    let url = this.servicelinks.getHistorylogbyid;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${EngagementLetterId}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            //this.getAllLetterDropDown();
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              localStorage.removeItem("RefreshToken");
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    });

  }
  async getInfosigningStatus(EngagementLetterId): Promise<any> {
    //get
    let url = this.servicelinks.postInfosigningstatus;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${EngagementLetterId}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            //this.getAllLetterDropDown();
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              localStorage.removeItem("RefreshToken");
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    });

  }



  async resendForEsigning(data: any): Promise<any> {
    //let url = this.servicelinks.resendForEsigning;
    let url = this.servicelinks.resendForEsigning;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async getEngagementDetailsByID(id: number): Promise<any> {
    let url = this.servicelinks.getDetailsForResendEsigning;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}${id + "&resend=Resend"}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    }
    );
  }

  async postEngagementLetterUpdateField(data: any): Promise<any> {
    //post
    let url = this.servicelinks.engagementletterUpdateeditFields;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, this.postHeaders).subscribe(res => {
        localStorage.removeItem("RefreshToken");
        resolve(res);
      }, err => {
        if (err.status == 401 || err.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.post(url, data, this.postHeaders).subscribe(res => {
              localStorage.removeItem("RefreshToken");
              resolve(res);
            }, err => {
              resolve(err);
            });
          }, 5000);
        } else {
          resolve(err);
        }
      });
    });
  }

  async getEmailInfoById(EngagementLetterId: number): Promise<any> {
    //get
    let url = this.servicelinks.getEmailInfoById;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${EngagementLetterId}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              localStorage.removeItem("RefreshToken");
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    });
  }

  async getEmailRemainder(EngagementLetterId: number, userName: string): Promise<any> {
    let url = this.servicelinks.getEmailRemainder;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}=${EngagementLetterId}` + '&userName=' + `${userName}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              localStorage.removeItem("RefreshToken");
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    });
  }


  async getChildCountById(EngagementLetterId: number): Promise<any> {
    //get
    let url = this.servicelinks.getChildcount;
    let refreshtoken = localStorage.getItem("RefreshToken");

    return new Promise((resolve, reject) => {
      this.http.get(`${url}${EngagementLetterId}`, { headers: this.getHeaders }).subscribe((response: any) => {
        localStorage.removeItem("RefreshToken");
        resolve(response);
      }, error => {
        if (error.status == 401 || error.status == 400) {
          this.dataService.refreshtoken();
          setTimeout(() => {
            this.http.get(url, { headers: this.getHeaders }).subscribe((response: any) => {
              localStorage.removeItem("RefreshToken");
              resolve(response);
            }, error => {
              resolve(error);
            });
          }, 5000);
        } else {
          resolve(error);
        }
      });
    });
  }



}


