<section>
    <div class="wrapper">
        <app-header [screenNameValue]="ScreenName" (addClassEvent)="addClassInDashboard($event)"></app-header>
        <main class="main-content p-4" id="maintoggledrop" #maintoggledrop>
            <!-- Tabs -->
            <section [hidden]="norole">
                <ngx-spinner size="medium" [type]="typeSelected">
                    <p style="color: white">{{percentageUploadMessage}} {{percentageMessage}}</p>
                </ngx-spinner>
                <div class="tab">
                    <ul class="nav nav-pills mb-3 mb-lg-4" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button id="mylettertoggle" #mylettertoggle class="nav-link active" data-bs-toggle="pill"
                                data-bs-target="#pills-my-letter" (click)="getLetterList('myLetter')" type="button"
                                role="tab" aria-controls="pills-my-letter" aria-selected="true">My Letters</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button id="alllettertoggle" #alllettertoggle (click)="getLetterList('allLetter')"
                                class="nav-link rounded-top-start-0" data-bs-toggle="pill"
                                data-bs-target="#pills-all-letter" type="button" role="tab"
                                aria-controls="pills-all-letter" aria-selected="false">All Letters</button>
                        </li>
                        <button type="button" [hidden]="IsBulkLetter" [routerLink]="['/bulkletterdashboard']"
                            class="btn p-btn mt-3 mt-md-0 ms-md-auto">Bulk
                            Letter Generation</button>

                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-my-letter" role="tabpanel"
                            aria-labelledby="pills-all-letter" tabindex="0">
                            <div class="my-letter mb-4 px-4 py-3 bg-white rounded">
                                <div class="accordion accordion-flush" id="search-field">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button id="collapseButton" #collapseButton
                                                class="accordion-button collapsed shadow-none p-0" type="button"
                                                data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                                                aria-controls="collapseOne" (click)="collapseFilter()">
                                                <span class="fs-16 fw-semibold highlight">Search or Filter</span>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" #collapseOne class="accordion-collapse collapse"
                                            data-parent="#search-field">
                                            <div class="accordion-body px-0 pb-0">
                                                <form class="form-horizontal" [formGroup]="SearchFilterForm">
                                                    <!-- flex box  -->
                                                    <div class="search-fields row">
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Engagement Letter
                                                                    ID</label>
                                                                <ng-select [searchable]="true"
                                                                    formControlName="EngagementLetterId"
                                                                    [clearable]="false" [items]="EngagementLetterIds"
                                                                    bindValue="EngagementLetterId"
                                                                    [virtualScroll]="true"
                                                                    bindLabel="EngagementLetterId" placeholder="Select Engagement Letter
                                                                ID">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Engagement Letter
                                                                    Name</label>
                                                                <ng-select [searchable]="true"
                                                                    formControlName="EngagementLetterName"
                                                                    [clearable]="false" [items]="engageLetterName"
                                                                    bindValue="EngagementLetterName"
                                                                    [virtualScroll]="true"
                                                                    bindLabel="EngagementLetterName" placeholder="Select Engagement Letter
                                                                Name" focusOnce>
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Office</label>
                                                                <ng-select [searchable]="true" formControlName="Office"
                                                                    [clearable]="false" [items]="officeName"
                                                                    bindValue="OfficeName" [virtualScroll]="true"
                                                                    bindLabel="OfficeName" placeholder="Select Office"
                                                                    focusOnce>
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Tax Year</label>
                                                                <ng-select [searchable]="true" formControlName="TaxYear"
                                                                    [clearable]="false" [items]="yearsList"
                                                                    bindValue="TaxYear" [virtualScroll]="true"
                                                                    bindLabel="TaxYear" placeholder="Select Tax Year">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Template
                                                                    Name</label>
                                                                <ng-select [searchable]="true"
                                                                    formControlName="TemplateName" [clearable]="false"
                                                                    [items]="templateName" bindValue="TemplateName"
                                                                    [virtualScroll]="true" bindLabel="TemplateName"
                                                                    placeholder="Select Template
                                                                Name">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Client
                                                                    ID/Name</label>
                                                                <ng-select [searchable]="true"
                                                                    formControlName="ClientName" [clearable]="false"
                                                                    [items]="clientNames" bindValue="ClientId"
                                                                    [virtualScroll]="true" bindLabel="ClientName"
                                                                    placeholder="Select Client
                                                            ID/Name">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Partner
                                                                    (PIC)</label>
                                                                <ng-select [searchable]="true" formControlName="Partner"
                                                                    [clearable]="false" [items]="engagementPartner"
                                                                    [virtualScroll]="true" bindLabel="PartnerName"
                                                                    bindValue="PartnerName" placeholder="Select Partner"
                                                                    focusOnce>
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for=""
                                                                    class="label-color mb-1">Department</label>
                                                                <ng-select [searchable]="true"
                                                                    formControlName="DepartmentId" [clearable]="false"
                                                                    bindValue="DepartmentId" [items]="departmentList"
                                                                    [virtualScroll]="true" bindLabel="Department"
                                                                    placeholder="Select Department">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Type</label>
                                                                <ng-select [searchable]="true" formControlName="TypeId"
                                                                    [clearable]="false" bindValue="EngageTypeId"
                                                                    [items]="typeList" [virtualScroll]="true"
                                                                    bindLabel="EngageTypeName"
                                                                    placeholder="Select Type">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Admin</label>
                                                                <ng-select [searchable]="true" formControlName="Admin"
                                                                    [clearable]="false" bindValue="AdminName"
                                                                    [items]="adminList" [virtualScroll]="true"
                                                                    bindLabel="AdminName" placeholder="Select Admin">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3 mb-2">
                                                            <div class="gird-item">
                                                                <label for="" class="label-color mb-1">Status</label>
                                                                <ng-select [searchable]="true" [clearable]="false"
                                                                    formControlName="Status" [items]="StatusList"
                                                                    bindValue="DocumentStatusId" [virtualScroll]="true"
                                                                    bindLabel="Status" placeholder="Select Status">
                                                                </ng-select>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <!-- Grid -->
                                                    <div class="search-fields">












                                                    </div>
                                                    <!--  -->
                                                    <div class="text-md-end mt-2 mt-md-4">
                                                        <button (click)="onClear()" type="button"
                                                            class="btn p-btn-outline ">Clear</button>
                                                        <button (click)="Search()" type="button"
                                                            class="btn p-btn ms-md-3"
                                                            [disabled]="isSearchBtnDisabled">Search</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="engagement-letter bg-white px-4 py-3 rounded">
                                <h5 class="highlight mb-lg-3 fs-16 fw-semibold">Engagement Letters</h5>
                                <!--data table -->
                                <div class="table-responsive position-relative engagedash-table">
                                    <p-table #dt [value]="this.myLetterList" styleClass="p-datatable-gridlines"
                                        dataKey="id" [rows]="10" [resizableColumns]="true" sortMode="multiple"
                                        [scrollable]="true" scrollHeight="1000px" [showCurrentPageReport]="true"
                                        [paginator]="true" [(first)]="firstpage"
                                        currentPageReportTemplate="{first} to {last} of {totalRecords}">
                                        <ng-template pTemplate="colgroup">
                                            <colgroup>
                                                <col style="width:43%">
                                                <col style="width:45%">
                                                <col style="width:61%">
                                                <col style="width:39%">
                                                <col style="width:35%">
                                                <col style="width:66%">
                                                <col style="width:43%">
                                                <col style="width:57%">
                                                <col style="width:44%">
                                                <col style="width:42%">
                                                <col style="width:40%">
                                                <col style="width:28%">

                                            </colgroup>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th pResizableColumn pSortableColumn="BatchId">Batch ID<p-sortIcon
                                                        field="BatchId">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="EngagementLetterId">Letter
                                                    ID<p-sortIcon field="EngagementLetterId">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="EngagementLetterName">Letter
                                                    Name<p-sortIcon field="EngagementLetterName">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="OfficeName">Office<p-sortIcon
                                                        field="OfficeName">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="TaxYear">Year<p-sortIcon
                                                        field="TaxYear">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="TemplateName">Template
                                                    Name<p-sortIcon field="TemplateName">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="ClientId">Client
                                                    ID<p-sortIcon field="ClientId">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="ClientName">Client Name<p-sortIcon
                                                        field="ClientName">
                                                    </p-sortIcon>
                                                </th>
                                                <th pResizableColumn pSortableColumn="PartnerName">Partner(PIC)
                                                    <p-sortIcon field="PartnerName"></p-sortIcon>
                                                </th>

                                                <th pResizableColumn pSortableColumn="AdminName">Admin<p-sortIcon
                                                        field="AdminName">
                                                    </p-sortIcon>
                                                </th>

                                                <th pSortableColumn="DocumentDescription">Status<p-sortIcon
                                                        field="DocumentDescription">
                                                    </p-sortIcon>
                                                </th>
                                                <th>Action</th>


                                            </tr>

                                        </ng-template>
                                        <ng-template pTemplate="body" let-myLetterData let-i="rowIndex">
                                            <tr>
                                                <td>{{myLetterData.BatchId}}</td>
                                                <td>{{myLetterData.EngagementLetterId}}</td>
                                                <td>{{myLetterData.EngagementLetterName}}</td>
                                                <td>{{myLetterData.OfficeName}}
                                                </td>
                                                <td>{{myLetterData.TaxYear}}
                                                </td>
                                                <td>{{myLetterData.TemplateName
                                                    }}</td>
                                                <td>{{myLetterData.ClientId}}</td>
                                                <td>{{myLetterData.ClientName}}</td>
                                                <td>{{myLetterData.PartnerName}}</td>
                                                <td>{{myLetterData.AdminName}}</td>
                                                <td>

                                                    <span class="status invalid" [pTooltip]="tooltipText"
                                                        tooltipPosition="left"
                                                        *ngIf="myLetterData.DocumentDescription === 'Invalid Email Id'"
                                                        style="color:red">{{myLetterData.DocumentDescription}}</span>
                                                    <span class="status invalid" [pTooltip]="tooltipEsigningFailedText"
                                                        tooltipPosition="left"
                                                        *ngIf="myLetterData.DocumentDescription === 'E-Signing Failed, Retrying'"
                                                        style="color:red">{{myLetterData.DocumentDescription}}</span>
                                                    <span
                                                        *ngIf="myLetterData.DocumentDescription !== 'Invalid Email Id' && myLetterData.DocumentDescription !== 'E-Signing Failed, Retrying'"
                                                        class="status DocumentDescription">{{myLetterData.DocumentDescription}}</span>

                                                </td>

                                                <td class="text-center align-middle">
                                                    <div class="custom-icon"
                                                        (click)="showMenu($event, menu, myLetterData.DocumentDescription, myLetterData.EngagementLetterId, myLetterData.ClientName, myLetterData.ClientId, myLetterData.BulkLettersId, myLetterData.SignatoryEmailId, myLetterData.TemplateName,myLetterData.BatchId,myLetterData.PartnerName, myLetterData.TemplateId,myLetterData.Is7216Available,myLetterData.IsNewFlow,myLetterData.EngagementLetterName,myLetterData.AdminName)">
                                                        <i class="pi pi-ellipsis-v"></i>
                                                    </div>
                                                    <p-menu #menu [popup]="true"
                                                        [class]="menuClicked ? 'custom-menu' : ''" [model]="items">
                                                    </p-menu>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="12" style="text-align: center;">No
                                                    Records Found</td>
                                            </tr>
                                        </ng-template>

                                    </p-table>
                                    <div class="d-flex align-items-center justify-content-between flex-wrap excel-btn">
                                        <div>
                                            <button type="button" class="link-color me-3 d-flex align-items-center"
                                                (click)="exportExcel(myletterExportData, 'Engagement Letter Details.xlsx')"><img
                                                    class="me-2" src="./assets/images/document-download.png"
                                                    alt="Download-icon">Export above table
                                            </button>
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>
                        <div class="tab-pane fade" id="pills-all-letter" role="tabpanel"
                            aria-labelledby="pills-all-letter" tabindex="0">All letters</div>

                    </div>
                </div>

            </section>
            <!-- Edit field Modal -->
            <div class="modal" tabindex="-1" id="bulk-letter-edit-field" aria-modal="true" role="dialog"
                [ngStyle]="{'display':displayEditField}" [class.shadow-popup]="secondPopupOpen">
                <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div class="modal-content p-4">
                        <div class="modal-header border-0 p-0">
                            <h5 class="modal-title fs-16 highlight fw-semibold m-0">Edit Fields </h5>
                            <button type="button" class="btn-close shadow-none" (click)="editFieldscloseModal()"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <p><span class="err-color">Note: </span>All the fields are mandatory</p>
                        <div class="modal-body p-0" #editfieldScrollPosition>
                            <form id="staticfieldsform" [formGroup]="StaticFieldsForm">
                                <div class="p-3 border rounded" id="ClientIfovalues">
                                    <div class="row mb-2">
                                        <span class="d-block mb-2 fw-bold">1.Client Details:</span>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Client ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control" id="ClientID"
                                                    placeholder="Client ID" name="ClientID" readonly
                                                    [(ngModel)]="this.PartnerValues.ClientID"
                                                    [formControl]="StaticFieldsForm.controls['ClientID']">
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Signatory
                                                    Email ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="SignatoryEmailId" placeholder="Signatory Email ID"
                                                    name="SignatoryEmailId"
                                                    [(ngModel)]="this.PartnerValues.SignatoryEmailId"
                                                    [formControl]="StaticFieldsForm.controls['SignatoryEmailId']">
                                                <ng-container
                                                    *ngFor="let validation of validation_messages.SignatoryEmailId">
                                                    <div class="errorvl"
                                                        *ngIf="StaticFieldsForm.get('SignatoryEmailId').hasError(validation.type) && (StaticFieldsForm.get('SignatoryEmailId').dirty || StaticFieldsForm.get('SignatoryEmailId').touched)">
                                                        <label class="errorcolor" *ngIf="!condition"> {{
                                                            validation.message
                                                            }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1 me-2">Tax
                                                    Year <span class="err-color">*</span></label>
                                                <ng-multiselect-dropdown name="TaxYears" id="TaxYears"
                                                    class="myTaxYears" formControlName="selectedYearsform"
                                                    (ngModelChange)="onChangeYearsclient()"
                                                    [placeholder]="'Select Tax Years'"
                                                    [(ngModel)]="selectedtaxyeareditfield"
                                                    [settings]="dropdownSettings_taxyear" [data]="taxyears_bind">
                                                </ng-multiselect-dropdown>
                                                <ng-container *ngFor="let validation of validation_messages.TaxYears">
                                                    <div class="errorvl"
                                                        *ngIf="StaticFieldsForm.get('TaxYears').hasError(validation.type) && (StaticFieldsForm.get('TaxYears').dirty || StaticFieldsForm.get('TaxYears').touched)">
                                                        <label class="errorcolor" *ngIf="!condition"> {{
                                                            validation.message
                                                            }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3" [hidden]="isspouseemailid">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Spouse
                                                    Email ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="SpouseEmailId" placeholder="Spouse Email ID"
                                                    name="SpouseEmailId" [(ngModel)]="this.PartnerValues.SpouseEmailId"
                                                    [formControl]="StaticFieldsForm.controls['SpouseEmailId']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeName">
                            <div class="err-color"
                                *ngIf="StaticFieldsForm.get('OfficeName').hasError(validation.type) && (StaticFieldsForm.get('OfficeName').dirty || StaticFieldsForm.get('OfficeName').touched)">
                                <label class="errorcolor "> {{ validation.message }}</label>
                            </div>
                        </ng-container> -->
                                                <ng-container
                                                    *ngFor="let validation of validation_messages.SpouseEmailId">
                                                    <div class="errorvl"
                                                        *ngIf="StaticFieldsForm.get('SpouseEmailId').hasError(validation.type) && (StaticFieldsForm.get('SpouseEmailId').dirty || StaticFieldsForm.get('SpouseEmailId').touched)">
                                                        <label class="errorcolor" *ngIf="!condition"> {{
                                                            validation.message
                                                            }}</label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div id="mddformbody" #mddformbody class="row p-0 m-0">

                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <!-- Add title -->
                                        <span class="d-block mb-2 fw-bold">2.Child Entities:</span>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-3">
                                                    <label for="Childclientid" class="form-label mb-1">Child Client
                                                        ID</label>
                                                    <input type="text" autocomplete="off" class="form-control"
                                                        id="Childclientid" name="Childclientid"
                                                        [formControl]="StaticFieldsForm.controls['Childclientid']"
                                                        placeholder="Child Client ID">

                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.Childclientid">
                                                        <div class="errorvl"
                                                            *ngIf="StaticFieldsForm.get('Childclientid').hasError(validation.type) && (StaticFieldsForm.get('Childclientid').dirty || StaticFieldsForm.get('Childclientid').touched)">
                                                            <label class="errorcolor" *ngIf="!condition"> {{
                                                                validation.message
                                                                }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="col-md-6 col-lg-3">
                                                    <label for="ChildEntities" class="form-label mb-1">Child Client
                                                        Name</label>
                                                    <input type="text" autocomplete="off" class="form-control"
                                                        id="ChildEntities" name="ChildEntities"
                                                        [formControl]="StaticFieldsForm.controls['ChildEntities']"
                                                        placeholder="Child Client Name">

                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.ChildEntities">
                                                        <div class="errorvl"
                                                            *ngIf="StaticFieldsForm.get('ChildEntities').hasError(validation.type) && (StaticFieldsForm.get('ChildEntities').dirty || StaticFieldsForm.get('ChildEntities').touched)">
                                                            <label class="errorcolor" *ngIf="!condition"> {{
                                                                validation.message
                                                                }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="col-md-6 col-lg-3" *ngIf="isEditFixedFee">
                                                    <label for="ChildFees" class="form-label mb-1">Child Fees</label>
                                                    <input type="number" autocomplete="off" class="form-control"
                                                        id="ChildFees" name="ChildFees"
                                                        [formControl]="StaticFieldsForm.controls['ChildFees']"
                                                        formControlName="ChildFees" placeholder="Child Fees">
                                                    <ng-container
                                                        *ngFor="let validation of validation_messages.ChildFees">
                                                        <div class="errorvl"
                                                            *ngIf="StaticFieldsForm.get('ChildFees').hasError(validation.type) && 
                                                           (StaticFieldsForm.get('ChildFees').dirty || StaticFieldsForm.get('ChildFees').touched)">
                                                            <label class="errorcolor">{{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="col-md-6 col-lg-3">
                                                    <button type="button" #addclientbtn class="btn p-btn mb-2 mb-md-0"
                                                        (click)="onAddchildEntityOptions()"
                                                        [disabled]="!isAddButtonDisabled()" [hidden]="isAddBtnVisible"
                                                        style="margin-top:19px">Add</button>
                                                    <button type="button" #updateclientbtn
                                                        class="btn p-btn mb-2 mb-md-0"
                                                        (click)="onUpdatechildEntityOptions()"
                                                        [hidden]="isUpdateBtnVisible"
                                                        [disabled]="!isAddButtonDisabled()"
                                                        style="margin-top:19px">Update</button>
                                                </div>
                                            </div>
                                            <div class="col-lg-12" style="margin-top: 20px;">
                                                <div class="col-md-12 col-lg-7">
                                                    <div class="" *ngIf="childEntitiesControls.length > 0">
                                                        <p-table #dt1 styleClass="p-datatable-gridlines"
                                                            dataKey="childID" [value]="childEntitiesArrayValue"
                                                            [rows]="3" [resizableColumns]="true" sortMode="multiple"
                                                            [scrollable]="true" scrollHeight="1200px"
                                                            [showCurrentPageReport]="true" [paginator]="true"
                                                            [(first)]="childfirstpage"
                                                            currentPageReportTemplate="{first} to {last} of {totalRecords}">
                                                            <ng-template pTemplate="colgroup">
                                                                <colgroup>
                                                                    <col style="width:8%">
                                                                    <col style="width:17%">
                                                                    <col *ngIf="isEditFixedFee" style="width:10%">
                                                                    <col *ngIf="Is7216TemplateAvailable"
                                                                        style="width:15%">
                                                                    <col style="width:8%">
                                                                </colgroup>
                                                            </ng-template>

                                                            <ng-template pTemplate="header">
                                                                <tr>
                                                                    <th
                                                                        style="height: 30px; padding: 0;text-align: center;">
                                                                        Child Client ID
                                                                    </th>
                                                                    <th
                                                                        style="height: 30px; padding: 0; text-align: center;">
                                                                        Child Client Name
                                                                    </th>
                                                                    <th *ngIf="isEditFixedFee"
                                                                        style="height: 30px; padding: 0; text-align: center;">
                                                                        Child Fees
                                                                    </th>
                                                                    <th *ngIf="Is7216TemplateAvailable"
                                                                        style="height: 30px; padding: 0; text-align: center;">
                                                                        Child 7216 Available
                                                                    </th>
                                                                    <th
                                                                        style="height: 30px; padding: 0;text-align: center;">
                                                                        Action
                                                                    </th>
                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="body" let-row
                                                                let-rowIndex="rowIndex">
                                                                <tr style="height: 30px;"
                                                                    [formGroup]="childEntitiesControls[rowIndex]">
                                                                    <td style="padding: 0;text-align: center;">
                                                                        {{
                                                                        childEntitiesControls[rowIndex].get('ChildClientId').value
                                                                        }}
                                                                    </td>
                                                                    <td
                                                                        style="padding:-1px; word-break: break-word; overflow-wrap: break-word;">
                                                                        {{
                                                                        childEntitiesControls[rowIndex].get('ChildClientName').value
                                                                        }}
                                                                    </td>
                                                                    <td *ngIf="isEditFixedFee"
                                                                        style="padding:-1px; word-break: break-word; overflow-wrap: break-word;">
                                                                        {{
                                                                        childEntitiesControls[rowIndex].get('ChildFees').value
                                                                        }}
                                                                    </td>
                                                                    <td *ngIf="Is7216TemplateAvailable"
                                                                        style="padding: -1px; word-break: break-word; overflow-wrap: break-word; text-align: center;">
                                                                        <div class="form-check form-check-inline">
                                                                            <input type="radio"
                                                                                id="inlineRadioYes{{rowIndex}}"
                                                                                formControlName="Child7216Available"
                                                                                [value]="true"
                                                                                class="form-check-input" />
                                                                            <label for="inlineRadioYes{{rowIndex}}"
                                                                                class="form-check-label">Yes</label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input type="radio"
                                                                                id="inlineRadioNo{{rowIndex}}"
                                                                                formControlName="Child7216Available"
                                                                                [value]="false"
                                                                                class="form-check-input" />
                                                                            <label for="inlineRadioNo{{rowIndex}}"
                                                                                class="form-check-label">No</label>
                                                                        </div>
                                                                    </td>
                                                                    <td style="padding: 0;text-align: center;"
                                                                        class="text-center align-middle">
                                                                        <p class="m-0">
                                                                            <button
                                                                                class="bg-transparent mx-1 link-color"
                                                                                pTooltip="Edit" data-bs-toggle="modal"
                                                                                data-bs-target="#edit-field"
                                                                                (click)="editChildClientDetails(childEntitiesControls[rowIndex].controls,rowIndex)"><img
                                                                                    class="me-2"
                                                                                    src="./assets/images/edit.png"
                                                                                    alt="Edit-icon"></button>
                                                                            <button
                                                                                class="bg-transparent mx-1 link-color"
                                                                                pTooltip="Delete" tooltipPosition="top"
                                                                                (click)="onRemoveEntityOptions(rowIndex)">
                                                                                <img src="./assets/images/delete-icon.png"
                                                                                    alt="Delete-icon">
                                                                            </button>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="4" style="text-align: center;">No
                                                                        ChildEntities found</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row" id="officedetails">
                                        <span class="d-block mb-2 fw-bold">3.Office Details:</span>

                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Partner ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" name="PartnerID" id="PartnerID"
                                                    required class="form-control" id="{{this.PartnerFieldId}}"
                                                    placeholder="Partner ID" [(ngModel)]="this.PartnerValues.PartnerId"
                                                    readonly [formControl]="StaticFieldsForm.controls['PartnerID']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.PartnerID">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('PartnerID').hasError(validation.type) && (StaticFieldsForm.get('PartnerID').dirty || StaticFieldsForm.get('PartnerID').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2 ">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Partner
                                                    Name
                                                    <span class="err-color">*</span></label>
                                                <ng-select [(ngModel)]="this.PartnerValues.PartnerName"
                                                    id="{{this.PartnerNameFieldId}}" [items]="partnerNameList"
                                                    formControlName="PartnerName" (change)="onPartnerSelected($event)"
                                                    bindLabel="FirstName" bindValue="FirstName" [searchable]="true"
                                                    [clearable]="false" placeholder="Select Partner">
                                                </ng-select>
                                                <!-- <ng-container *ngFor="let validation of validation_messages.PartnerName">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('PartnerName').hasError(validation.type) && (StaticFieldsForm.get('PartnerName').dirty || StaticFieldsForm.get('PartnerName').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office ID
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="{{this.OfficeFieldId}}" placeholder="Office ID" name="OfficeID"
                                                    [(ngModel)]="this.PartnerValues.OfficeId" readonly
                                                    [formControl]="StaticFieldsForm.controls['OfficeID']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeID">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficeID').hasError(validation.type) && (StaticFieldsForm.get('OfficeID').dirty || StaticFieldsForm.get('OfficeID').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Name
                                                    <span class="err-color">*</span></label>
                                                <ng-select [(ngModel)]="this.PartnerValues.OfficeName"
                                                    [items]="officeDetailsList" id="{{this.OfficeNameFieldId}}"
                                                    formControlName="OfficeName" (change)="onOfficeSelected($event)"
                                                    bindLabel="Name" bindValue="Name" [searchable]="true"
                                                    [clearable]="false" placeholder="Select Partner">
                                                </ng-select>
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeName">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficeName').hasError(validation.type) && (StaticFieldsForm.get('OfficeName').dirty || StaticFieldsForm.get('OfficeName').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Address Line1
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control" readonly
                                                    id="{{this.OfficeAddressLine1}}" placeholder="Office Address Line1"
                                                    name="OfficeAddressLine1"
                                                    [(ngModel)]="this.PartnerValues.OfficeAddressLine1"
                                                    [formControl]="StaticFieldsForm.controls['OfficeAddressLine1']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeAddress">
                                                    <div class="err-color"
                                                        *ngIf="StaticFieldsForm.get('OfficeAddress').hasError(validation.type) && (StaticFieldsForm.get('OfficeAddress').dirty || StaticFieldsForm.get('OfficeAddress').touched)">
                                                        <label class="errorcolor "> {{ validation.message }}</label>
                                                    </div>
                                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Address Line2
                                                </label>
                                                <input type="text" autocomplete="off" class="form-control" readonly
                                                    id="{{this.OfficeAddressLine2}}" placeholder="Office Address Line2"
                                                    name="OfficeAddressLine2"
                                                    [(ngModel)]="this.PartnerValues.OfficeAddressLine2"
                                                    [formControl]="StaticFieldsForm.controls['OfficeAddressLine2']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeAddress">
                                                    <div class="err-color"
                                                        *ngIf="StaticFieldsForm.get('OfficeAddress').hasError(validation.type) && (StaticFieldsForm.get('OfficeAddress').dirty || StaticFieldsForm.get('OfficeAddress').touched)">
                                                        <label class="errorcolor "> {{ validation.message }}</label>
                                                    </div>
                                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Address Line3
                                                </label>
                                                <input type="text" autocomplete="off" class="form-control" readonly
                                                    id="{{this.OfficeAddressLine3}}" placeholder="Office Address Line3"
                                                    name="OfficeAddressLine3"
                                                    [(ngModel)]="this.PartnerValues.OfficeAddressLine3"
                                                    [formControl]="StaticFieldsForm.controls['OfficeAddressLine3']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeAddress">
                                                    <div class="err-color"
                                                        *ngIf="StaticFieldsForm.get('OfficeAddress').hasError(validation.type) && (StaticFieldsForm.get('OfficeAddress').dirty || StaticFieldsForm.get('OfficeAddress').touched)">
                                                        <label class="errorcolor "> {{ validation.message }}</label>
                                                    </div>
                                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    City
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control" readonly
                                                    id="{{this.OfficeCityFieldId}}" placeholder="Office City"
                                                    name="OfficeCity" [(ngModel)]="this.PartnerValues.City"
                                                    [formControl]="StaticFieldsForm.controls['OfficeCity']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeCity">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficeCity').hasError(validation.type) && (StaticFieldsForm.get('OfficeCity').dirty || StaticFieldsForm.get('OfficeCity').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    State
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control" readonly
                                                    id="{{this.OfficeStateFieldId}}" placeholder="Office State"
                                                    name="OfficeState" [(ngModel)]="this.PartnerValues.State"
                                                    [formControl]="StaticFieldsForm.controls['OfficeState']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeState">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficeState').hasError(validation.type) && (StaticFieldsForm.get('OfficeState').dirty || StaticFieldsForm.get('OfficeState').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office Zip
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control" readonly
                                                    id="{{this.OfficeZipCodeFieldId}}" placeholder="Office Zip"
                                                    name="OfficeZip" [(ngModel)]="this.PartnerValues.ZipCode"
                                                    [formControl]="StaticFieldsForm.controls['OfficeZip']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficeZip">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficeZip').hasError(validation.type) && (StaticFieldsForm.get('OfficeZip').dirty || StaticFieldsForm.get('OfficeZip').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1" class="form-label mb-1">Office
                                                    Phone
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control" readonly
                                                    id="{{this.OfficePhoneNumberFieldId}}" placeholder="Office Phone"
                                                    name="OfficePhone" [(ngModel)]="this.PartnerValues.PhoneNumber"
                                                    [formControl]="StaticFieldsForm.controls['OfficePhone']">
                                                <!-- <ng-container *ngFor="let validation of validation_messages.OfficePhone">
                                    <div class="err-color"
                                        *ngIf="StaticFieldsForm.get('OfficePhone').hasError(validation.type) && (StaticFieldsForm.get('OfficePhone').dirty || StaticFieldsForm.get('OfficePhone').touched)">
                                        <label class="errorcolor "> {{ validation.message }}</label>
                                    </div>
                                </ng-container> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div class="mb-2">
                                                <label for="exampleFormControlInput1"
                                                    class="form-label mb-1">Jurisdiction
                                                    <span class="err-color">*</span></label>
                                                <input type="text" autocomplete="off" class="form-control" readonly
                                                    id="{{this.jurisdictionFieldId}}" placeholder="Jurisdiction"
                                                    name="Jurisdiction" [(ngModel)]="this.PartnerValues.jurisdiction"
                                                    [formControl]="StaticFieldsForm.controls['Jurisdiction']">
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div>
                                        <span class="d-block mb-2 fw-bold">4.Other Details:</span>
                                        <div class="row">

                                            <!-- 7216 available -->
                                            <div class="col-md-6 col-lg-3 mb-2" *ngIf="Template7216IsAvailable">
                                                <label for="" class="control-label mb-1">Parent 7216 Available?<span
                                                        class="err-color">*</span></label>
                                                <div class="response_question">
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" name="editField7216available"
                                                            (change)="on7216OnItemChange($event.target.value)"
                                                            formControlName="editField7216available" value="true"
                                                            id="7216inlineRadio1" class="form-check-input">
                                                        <label for="7216inlineRadio1"
                                                            class="form-check-label">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" name="editField7216available"
                                                            (change)="on7216OnItemChange($event.target.value)"
                                                            formControlName="editField7216available" value="false"
                                                            id="7216inlineRadio2" class="form-check-input"><label
                                                            for="7216inlineRadio2" class="form-check-label">No</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6 col-lg-2">
                                                <label for="" class="control-label mb-1">E-Signing?<span
                                                        class="err-color">*</span></label>
                                                <div class="response_question ">
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" id="inlineRadio5" name="Esigning"
                                                            (change)="onItemChange($event.target.value)"
                                                            [checked]="isEsigningval" value="option1"
                                                            class="form-check-input">
                                                        <label for="inlineRadio5" class="form-check-label">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input type="radio" name="Esigning" id="inlineRadio6"
                                                            (change)="onItemChange($event.target.value)"
                                                            [checked]="isEsigning" value="option2"
                                                            class="form-check-input"><label for="inlineRadio6"
                                                            class="form-check-label">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="mb-2">
                                                    <label for="exampleFormControlInput1"
                                                        class="form-label mb-1">Template name
                                                        <span class="err-color">*</span></label>
                                                    <input type="text" autocomplete="off" class="form-control"
                                                        id="templateName" placeholder="template name"
                                                        name="templateName" readonly
                                                        [formControl]="StaticFieldsForm.controls['TemplateName']"
                                                        [(ngModel)]="this.PartnerValues.TemplateName">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-2">
                                                <div class="mb-2 ">
                                                    <label for="exampleFormControlInput1" class="form-label mb-1">Return
                                                        Type Code
                                                        <span class="err-color">*</span></label>
                                                    <input type="text" autocomplete="off" class="form-control" id="3"
                                                        placeholder="ReturnTypeCode" name="ReturnTypeCode" readonly
                                                        [formControl]="StaticFieldsForm.controls['ReturnTypeCode']"
                                                        [(ngModel)]="this.returntypecodebind">
                                                    <!-- <ng-container *ngFor="let validation of validation_messages.PartnerName">
                                                        <div class="err-color"
                                                            *ngIf="StaticFieldsForm.get('PartnerName').hasError(validation.type) && (StaticFieldsForm.get('PartnerName').dirty || StaticFieldsForm.get('PartnerName').touched)">
                                                            <label class="errorcolor "> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container> -->
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="mt-3 mt-md-3 text-end sticky-bottom bg-white">
                                        <button type="button" (click)="editFieldscloseModal()"
                                            class="btn p-btn-outline">Close</button>
                                        <button type="button" (click)="updateEngagementLetterFields()"
                                            class="btn p-btn ms-3">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Delete Modal -->
            <div class="modal" tabindex="-1" id="deleteModal" [ngStyle]="{'display':displayDeleteModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="deleteModal">
                        <div class="modal-header">
                            <h5 class="modal-title fw-semibold highlight">Confirm Delete</h5>
                            <button type="button" (click)="onCloseDeleteModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p class="s-text fw-medium mb-0">{{deletePopupMessage}}
                            </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" (click)="onCloseDeleteModal()" class="btn p-btn-outline"
                                data-bs-dismiss="modal">No</button>
                            <button type="button" (click)="deleteEngagementletterById()"
                                class="btn p-btn ms-2">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- cancel signing Modal -->
            <div class="modal" tabindex="-1" id="cancelSigningModal" [ngStyle]="{'display':displayCancelSigningModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="cancelSigningModal">
                        <div class="modal-header">
                            <h5 class="modal-title fw-semibold highlight">Confirm Cancel</h5>
                            <button type="button" (click)="onCloseSigningModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p class="s-text fw-medium mb-0">{{cancelPopupMessage}}
                            </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" (click)="onCloseSigningModal()" class="btn p-btn-outline"
                                data-bs-dismiss="modal">No</button>
                            <button type="button" (click)="cancelSigningById()" class="btn p-btn ms-2">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!--upload Modal start here -->
            <!-- <div class="modal" id="upload_doc" [ngStyle]="{'display':displayupload}"
                [class.shadow-popup]="secondPopupOpen">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="form-horizontal" [formGroup]="uploadForm">
                                        <div class="col-12 col-sm-12 col-sm-12">
                                            <h6 class=""><b>Upload Document</b></h6>

                                            <div class="form-group">
                                                <label class="control-label" for="fieldname">Attachment file</label>
                                                <div>
                                                    <input type="file" #fileInput formControlName="uploadDocument"
                                                        accept=".pdf" (change)="fileChange($event)" class="" /><br>
                                                    <div class="drop-area" (dragover)="dragOver($event)"
                                                        (dragleave)="dragLeave($event)" (drop)="fileDropped($event)"
                                                        accept=".pdf">
                                                        <p>Drag & Drop PDF file here or</p>
                                                        <p>Browse to upload</p>
                                                    </div>
                                                    <div class="image_file_notes"
                                                        style="margin-bottom:21px;padding-top: 10px">
                                                        <p><strong>Note: </strong> PDF file should be less than 10MB in
                                                            file size</p>
                                                        <p>Please contact Helpdesk to upload files more than 10MB</p>
                                                    </div>
                                                    <div *ngIf="selectedFileName">{{ selectedFileName }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="col-sm-12 text-right">
                                        <a (click)="cancelupload()" class="btn btn-third mx-2"
                                            data-dismiss="modal">Cancel</a>
                                        <button (click)="UploadPdfDownload()" [disabled]="pdfSelected"
                                            class="btn btn-secondary mx-2" data-dismiss="modal">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="modal" id="upload_doc" [ngStyle]="{'display':displayupload}"
                [class.shadow-popup]="secondPopupOpen">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header border-0 pb-0">
                            <h5 class="modal-title fw-semibold highlight">Upload Engagement Letter</h5>
                            <button type=" button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                aria-label="Close" (click)="cancelupload()"></button>
                        </div>
                        <div class="modal-body">
                            <form class="form-horizontal" [formGroup]="uploadForm">
                                <div>
                                    <div class="form-group">
                                        <div>
                                            <div class="drop-area" (dragover)="dragOver($event)"
                                                (dragleave)="dragLeave($event)" (drop)="fileDropped($event)"
                                                accept=".pdf">
                                                <img class="mb-2" src="./assets/images/upload_file.png"
                                                    alt="Upload-file">
                                                <p class="fw-semibold fs-5 mb-2">Drag and Drop Letter.</p>
                                                <p class="mb-2" style="color:#939393;font-size: 16px;">or</p>
                                                <div class="file-input-container">
                                                    <button class="btn p-btn">Browse</button>
                                                    <input type="file" #fileInput formControlName="uploadDocument"
                                                        accept=".pdf" (change)="fileChange($event)" id="file-input"
                                                        class="hidden-input" /><br>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        
                                    </div>
                                </div>
                            </form>
                            <div class="d-flex align-items-center p-3 mt-3 rounded" style="box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.20);
                        " *ngIf="selectedFileName">
                                <div style="flex-grow: 1;">{{ selectedFileName }}</div>
                                <a (click)="cancelUploadFile()" class="btn p-btn-outline mx-3"
                                    data-dismiss="modal">Cancel</a>
                                <button (click)="UploadPdfDownload()" [disabled]="pdfSelected" class="btn p-btn "
                                    data-dismiss="modal">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="modal" id="upload_doc" [ngStyle]="{'display': displayupload}"
                [class.shadow-popup]="secondPopupOpen">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div class="modal-content">
                        <!-- Modal Header -->
                        <div class="modal-header border-0 pb-1">
                            <div>
                                <h5 class="modal-title fw-semibold highlight mb-2">Upload Engagement Letter</h5>
                                <p class="err-color mb-2">Note<span class="text-dark">: Please provide the page number
                                        which is displayed in Adobe Acrobat instead of taking it from engagement letter
                                        header.</span></p>
                            </div>

                            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                aria-label="Close" (click)="cancelupload()"></button>
                        </div>

                        <!-- Modal Body -->
                        <div class="modal-body p-4">
                            <div class="p-3 border rounded">
                                <form class="form-horizontal" [formGroup]="uploadForm">
                                    <!-- Drag and Drop Area -->
                                    <div>
                                        <div class="form-group">
                                            <div>
                                                <div class="drop-area" (dragover)="dragOver($event)"
                                                    (dragleave)="dragLeave($event)" (drop)="fileDropped($event)"
                                                    accept=".pdf">
                                                    <img class="mb-2" src="./assets/images/upload_file.png"
                                                        alt="Upload-file">
                                                    <p class="fw-semibold fs-5 mb-2">Drag and Drop Letter.</p>
                                                    <p class="mb-2" style="color:#939393;font-size: 16px;">or</p>
                                                    <div class="file-input-container">
                                                        <button class="btn p-btn">Browse</button>
                                                        <input type="file" #fileInput formControlName="UploadDocument"
                                                            accept=".pdf" (change)="fileChange($event)" id="file-input"
                                                            class="hidden-input" /><br>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <!-- Form Fields (3 Column Layout) -->
                                    <div *ngIf="selectedFileName">

                                        <div class="d-flex align-items-center p-3 mt-3 rounded"
                                            style="box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.20);">
                                            <div style="flex-grow: 1;">{{ selectedFileName }}</div>
                                        </div>
                                        <div *ngIf="IsTemplate7216Available">
                                            <div class="row mb-3 mt-3">
                                                <div class="col-md-4">
                                                    <label for="numberOfClients" class="form-label">Number of Child
                                                        Clients<span class="err-color">*</span></label>
                                                    <input type="number" id="numberOfClients" class="form-control"
                                                        formControlName="NumberOfClients" min="1" value="2" readonly>
                                                </div>
                                            </div>
                                            <div class="row mb-3 mt-3">
                                                <div class="col-md-4">
                                                    <label for="exampleFormControlInput1"
                                                        class="form-label mb-1 me-2">Parent Client Id
                                                        <span class="err-color">*</span></label>
                                                    <input type="number" id="parentPageNo" class="form-control"
                                                        placeholder="Enter" formControlName="ParentClientId" readonly>
                                                </div>
                                                <input [hidden]="true" formControlName="Parent7216Available">

                                                <div class="col-md-4" hidden>
                                                    <label for="exampleFormControlInput1"
                                                        class="form-label mb-1 me-2">Child
                                                        7216 Available?
                                                        <span class="err-color">*</span></label>
                                                    <ng-select [items]="yesNoOptions" bindLabel="label"
                                                        bindValue="value" formControlName="Child7216Available"
                                                        [clearable]="false" placeholder="Select Child 7216 Available"
                                                        [(ngModel)]="selectedChild7216Available"
                                                        (change)="onSelectParentChild($event,'child')">
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="parentSigned" class="form-label">Parent 7216
                                                        Signed?<span class="err-color">*</span></label>
                                                    <ng-select [items]="yesNoParentAvailOptions" bindLabel="label"
                                                        bindValue="value" formControlName="Parent7216Signed"
                                                        [clearable]="false" placeholder="Select Parent 7216 Signed"
                                                        [(ngModel)]="selectedParent7216Signed">
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="parentPageNo" class="form-label">Parent 7216 Page
                                                        No<span class="err-color">*</span></label>
                                                    <input type="number" id="parentPageNo" class="form-control"
                                                        placeholder="Enter Page No" formControlName="ParentPageNumber">
                                                    <ng-container
                                                        *ngFor="let validation of validationMessages.ParentPageNumber">
                                                        <div class="errorvl"
                                                            *ngIf="uploadForm.get('ParentPageNumber').hasError(validation.type) && (uploadForm.get('ParentPageNumber').dirty || uploadForm.get('ParentPageNumber').touched)">
                                                            <label class="errorcolor"> {{ validation.message }}</label>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="mb-3" *ngIf="isChildAvailable" formArrayName="Child7216">
                                                <div *ngFor="let childGroup of child7216Array.controls; let i = index"
                                                    [formGroupName]="i">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <label for="parentPageNo" class="form-label">Child{{ i + 1
                                                                }}
                                                                Client Id<span class="err-color">*</span></label>
                                                            <input type="number" id="parentPageNo" class="form-control"
                                                                placeholder="Enter" formControlName="ChildClientId"
                                                                readonly>
                                                        </div>
                                                        <input [hidden]="true" formControlName="ChildClientName">
                                                        <input [hidden]="true" formControlName="ChildFees">
                                                        <input [hidden]="true" formControlName="Child7216Available">
                                                        <div class="col-md-4 mb-4">
                                                            <label class="form-label">Child{{ i + 1 }} 7216 Signed?<span
                                                                    class="err-color">*</span></label>
                                                            <ng-select
                                                                [items]="childGroup.get('childDropdownOptions').value"
                                                                bindLabel="label" bindValue="value"
                                                                formControlName="Child7216Signed" [clearable]="false"
                                                                [(ngModel)]="selectedChild7216Signed[i]"
                                                                placeholder="Select Child 7216 Signed">
                                                            </ng-select>
                                                        </div>
                                                        <div class="col-md-4 mb-4">
                                                            <label class="form-label">Child{{ i + 1 }} 7216 Page No<span
                                                                    class="err-color">*</span></label>
                                                            <input type="number" class="form-control"
                                                                placeholder="Enter Page No"
                                                                formControlName="Child7216PageNo">
                                                            <ng-container
                                                                *ngFor="let validation of validationMessages.Child7216PageNo">
                                                                <div class="errorvl"
                                                                    *ngIf="childGroup.get('Child7216PageNo')?.hasError(validation.type) &&
                                                                        (childGroup.get('Child7216PageNo')?.dirty || childGroup.get('Child7216PageNo')?.touched)">
                                                                    <label class="errorcolor">{{ validation.message
                                                                        }}</label>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- Modal footer -->
                        <div class="modal-footer border-0 p-3" *ngIf="selectedFileName">
                            <a (click)="cancelUploadFile()" class="btn p-btn-outline mx-3"
                                data-dismiss="modal">Clear</a>
                            <button (click)="UploadPdfDownload()" class="btn p-btn "
                                data-dismiss="modal">Submit</button>
                        </div>

                    </div>
                </div>
            </div>
            <!-- warning Modal -->
            <div class="modal" tabindex="-1" id="warning" [ngStyle]="{'display':displayWarningModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="warning">
                        <div class="modal-header warning-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                            <button type="button" (click)="closeWarningModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                            <p class="fs-16">{{warningMessage}}</p>
                            <button type="button" (click)="closeWarningModal()" class="warning-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Error Modal -->
            <div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="error">
                        <div class="modal-header error-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                            <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                            <p class="fs-16">{{errorMessage}}</p>
                            <button type="button" (click)="closeErrorModal()" class="error-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Preview Error Modal-->
            <div class="modal" tabindex="-1" id="previewerror" [ngStyle]="{'display':displayPreviewErrorModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="previewerror">
                        <div class="modal-header error-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                            <button type="button" (click)="previewCloseErrorModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                            <p class="fs-16">{{errorMessage}}</p>
                            <p class="fs-15">{{errorInfo}}</p>
                            <button type="button" (click)="previewCloseErrorModal()" class="error-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Edit field Error Modal -->
            <div class="modal" tabindex="-1" id="fielderror" [ngStyle]="{'display':displayFieldErrorModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="fielderror">
                        <div class="modal-header error-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                            <button type="button" (click)="closeFieldErrorModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                            <p class="fs-16" style="word-wrap:break-word;text-align: center;">
                                <span *ngIf="this.emptyFieldarr!=''" class="d-block text-center">Please provide input to
                                    mandatory
                                    field(s) - <span style="word-wrap:break-word;">{{emptyFieldarr}}</span>
                                </span><br>
                                <span class="d-block">{{signatorybotherrormessage}}</span>
                                <span class="d-block">{{signatoryspouseerrormessage}}</span>
                                <span class="d-block">{{signatorymailerrormessage}}</span>
                                <span class="d-block">{{feesErrorMessage}}</span>
                            </p>
                            <button type="button" (click)="closeFieldErrorModal()" class="error-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Upload Error Modal -->
            <!-- Edit field Error Modal -->
            <div class="modal" tabindex="-1" id="uploaderror" [ngStyle]="{'display':displayUplodErrorModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="uploaderror">
                        <div class="modal-header error-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                            <button type="button" (click)="closeUploadErrorModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                            <p class="fs-16" style="word-wrap:break-word;text-align: center;">
                                <span class="d-block text-center">Please provide
                                    input to
                                    mandatory
                                    field(s)
                                </span><br>
                            </p>
                            <button type="button" (click)="closeUploadErrorModal()" class="error-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Success Modal -->
            <div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModal}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="success">
                        <div class="modal-header success-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                            <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                            <p class="fs-16">{{successMessage}}</p>
                            <button type="button" (click)="closeSuccessModal()" class="success-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Success Modal -->
            <div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':disaplayblocks}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="success">
                        <div class="modal-header success-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                            <button type="button" (click)="closepopupblocks()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                            <!-- <p class="fs-16">{{successMessage}}<br>EngagementLetter ID:{{ successBlockcontentEngageId}}
                            </p> -->
                            <p class="fs-16">{{successMessage}}</p>
                            <p style="word-wrap:break-word;text-align:center;">Engagement Letter ID :
                                {{successBlockcontentEngageId}}</p>
                            <button type="button" (click)="closepopupblocks()" class="success-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <!--  Console log error Modal-->
            <div class="modal" tabindex="-1" id="consolelogerror" [ngStyle]="{'display':displayConsoleLogerror}">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" id="consolelogerror">
                        <div class="modal-header error-modal">
                            <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                            <button type="button" (click)="ondefaultLogError()" class="btn-close shadow-none"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center">
                            <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                            <div class="fs-16">
                                <h5>Sorry, something went wrong.</h5><br>
                                <h5>To continue please logout, close the browser, login again and resume the work on
                                    this letter.</h5>
                                <h5>If any problem occurs again in this letter, please take a full screen screenshot
                                    showing complete browser along with date time and share with IT Support.</h5>
                            </div>
                            <button type="button" (click)="ondefaultLogError()" class="error-btn"
                                data-bs-dismiss="modal">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>

    <!-- Engage history pop up 20/10/2023 -->
    <div class="modal" tabindex="-1" id="engage-history" [ngStyle]="{'display':displayHistorylog}"
        [class.shadow-popup]="secondPopupOpen">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div class="modal-content px-4 py-3" id="engage-historylog">
                <div class="modal-header pt-0 px-0 pb-2">
                    <h5 class="modal-title fs-18 highlight fw-semibold m-0">
                        Engagement Letter History
                    </h5>
                    <button type="button" (click)="historylogClose()" class="btn-close shadow-none"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="pt-3">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p class="pe-2 fw-semibold s-text">Batch ID</p>
                                    </td>
                                    <td>
                                        <p class="s-text fw-medium">: {{ historylogBatchId }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="pe-2 fw-semibold s-text">Engagement Letter ID</p>
                                    </td>
                                    <td>
                                        <p class="s-text fw-medium">: {{ historylogEngagementLetterId }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="pe-2 fw-semibold s-text">Engagement Letter Name</p>
                                    </td>
                                    <td>
                                        <p class="s-text fw-medium">: {{ historylogEngagementLetterName }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="table-responsive history-log">
                            <p-table #dt [value]="historylogData" dataKey="id" styleClass="p-datatable-gridlines">

                                <!-- #dt [rowHover]="false" [value]="historylogData" dataKey="id"
                                styleClass="p-datatable-gridlines" [resizableColumns]="true" sortMode="multiple"
                                [scrollable]="false" scrollHeight="1000px" [paginator]="true" [rows]="10"
                                [showCurrentPageReport]="true" [paginator]="true"
                                currentPageReportTemplate="{first} to {last} of {totalRecords}"> -->

                                <ng-template pTemplate="colgroup">
                                    <colgroup>
                                        <col style="width:5%">
                                        <col style="width:28%">
                                        <col style="width:15%">
                                        <col style="width:16%">
                                        <col style="width:25%">

                                    </colgroup>
                                </ng-template>


                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pResizableColumn>Ver #
                                        </th>
                                        <th pResizableColumn>Status
                                        </th>
                                        <th pResizableColumn>Actionee Name
                                        </th>

                                        <th pResizableColumn>Date Time
                                        </th>
                                        <th pResizableColumn><span>Action</span>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-history>
                                    <tr>

                                        <td>{{history.Version}}</td>
                                        <td>{{history.Status}}</td>
                                        <td>{{history.EditedBy}}</td>
                                        <td>{{history.ModifiedOn}}</td>
                                        <td>
                                            <ng-container *ngIf="history.Version === lastVersion">
                                                <ng-container *ngIf="!this.botStatusExists;  else elseBlock">
                                                    <a style="color: inherit;" class="text-decoration-none mx-2"
                                                        (click)="openPreviewDownload(history, 'preview')"
                                                        *ngIf="history.Status== 'With Client for E-Signing'||'With Client for Manual Signing'||'Completed'||'Fields Edited'||'Cancelled the Signing Request'||'With Admin for Review'||'Client declined the Signing'">
                                                        Preview
                                                    </a>
                                                    <a [hidden]="ISdownlod" style="color: inherit;"
                                                        class="text-decoration-none mx-2"
                                                        (click)="openPreviewDownload(history, 'download')"
                                                        *ngIf="history.Status== 'With Client for E-Signing'||'With Client for Manual Signing'||'Completed'||'Fields Edited'||'Cancelled the Signing Request'||'With Admin for Review'||'Client declined the Signing'">
                                                        Download
                                                    </a>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #elseBlock></ng-template>
                                            <ng-container
                                                *ngIf="history.Version === completedVersionBeforeBot && history.Status === 'Completed' && botStatusExists && completedVersionBeforeBot !== 0">
                                                <a style="color: inherit;" class="text-decoration-none mx-2"
                                                    (click)="openPreviewDownload(history,'preview')">Preview</a>
                                                <a [hidden]="this.ISdownlod" style="color: inherit;"
                                                    class="text-decoration-none mx-2"
                                                    (click)="openPreviewDownload(history,'download')">Download</a>

                                            </ng-container>

                                            <a style="color: inherit;" class="text-decoration-none mx-2"
                                                (click)="InFosigningStatusmodal()"
                                                *ngIf="history.Status=='With Client For E-Signing'&&history.Version == latestversioncheck">Info</a>
                                            <a style="color: inherit;" class="text-decoration-none mx-2"
                                                (click)="emailInfo(history.EngagementLetterId)"
                                                *ngIf="(history.Status.includes('Reminder email sent to') || history.Status.includes('Signing email sent to')) && history.Version == latestversioncheckEmail">
                                                Email Info
                                            </a>
                                            <ng-container *ngIf="history.Version !== lastVersion">
                                                <a style="color: inherit;" class="text-decoration-none mx-2"
                                                    *ngIf="history.Status=='Client declined the Signing'"
                                                    (click)="declinePopup(history?.ReasonforDecline, history?.DeclineTimestamp, history?.ClientEmailId)">Reason</a>
                                                <!-- <a style="color: inherit;" class="text-decoration-none mx-2"
                                                    *ngIf="history.Status=='With Client for E-Signing'">Info</a> -->
                                            </ng-container>
                                        </td>
                                    </tr>

                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="12" style="text-align: center;">No
                                            fields Found</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 px-0 pb-0">
                    <div>
                        <button type="button" (click)="historylogClose()" class="btn p-btn" data-bs-dismiss="close">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Decline for a Reason-->
    <div class="modal" id="declineReason" [ngStyle]="{'display':displayDeclineReason}">
        <div class="modal-dialog  modal-dialog-centered new_popup">
            <div class="modal-content" id="declineReason">
                <div class="modal-header">
                    <h5 class="modal-title highlight fw-semibold m-0">Reason for Declined
                    </h5>
                    <!-- <button type=" button" class="btn-close shadow-none" data-bs-dismiss="modal"
                        aria-label="Close"></button> -->
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-4"><span class="s-text fw-semibold">Email Id</span></div>
                            <div class="col-sm-8 new_label_structure mb-2">
                                <p><span class="s-text fw-medium">: {{ this.clientEmailId }}</span></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4"><span class="s-text fw-semibold">Timestamp</span></div>
                            <div class="col-sm-8 new_label_structure mb-2">
                                <p><span class="s-text fw-medium">: {{ this.DeclineTimestamp }}</span></p>
                            </div>
                        </div>
                        <div class="form-outline">
                            <textarea name="ans" [(ngModel)]="this.reasonForDecline" class="form-control"
                                id="textAreaExample1" rows="4" style="border: 1px solid #ced4da;" readonly></textarea>
                            <div class="col-sm-12 text-end mt-3">
                                <a (click)="closeDeclinePopup()" class="btn p-btn" data-toggle="modal"
                                    data-target="#clearletter">Close</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [innerHTML]="trustedHtml"></div>
</section>

<!-- Resend -->
<div class="modal" tabindex="-1" id="{{resendDynamicId}}" [ngStyle]="{'display':displayResendModal}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-centered upload-attachment">
        <div class="modal-content p-4">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0 mb-md-2">Resend the letter for signing</h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeResendPopup()"></button>
            </div>

            <div class="modal-body p-0">
                <form [formGroup]="ResendForm">
                    <div class="p-3 border rounded" id="ClientIfovalues">
                        <div class="col-md-6 col-lg-6 mb-2">
                            <!-- <label for="" class="control-label mb-1">E-Signing?<span class="err-color">*</span></label>
                            <div class="response_question ">
                                <div class="form-check form-check-inline">
                                    <input type="radio" id="inlineRadio5" name="Esigning"
                                        (change)="onItemChange($event.target.value)" value="true"
                                        class="form-check-input" formControlName="eSigning">
                                    <label for="inlineRadio5" class="form-check-label">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="radio" name="Esigning" id="inlineRadio6"
                                        (change)="onItemChange($event.target.value)" value="false"
                                        class="form-check-input"><label for="inlineRadio6" class="form-check-label"
                                        formControlName="eSigning">No</label>
                                </div>
                            </div> -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="mb-2 mb-md-3 mb-lg-4">
                                    <label for="exampleFormControlInput1" class="form-label mb-1">E-Signing?
                                        <span class="err-color">*</span></label>
                                    <div class="d-inline-flex gap-3">
                                        <input id="yes" type="radio" class="custom-control-input" [value]="true"
                                            name="eSigning" formControlName="eSigning"
                                            (change)="onItemChangeResend($event.target.value)">
                                        <label class="custom-control-label" for="yes">Yes</label>
                                        <input id="yes" type="radio" class="custom-control-input" [value]="false"
                                            name="eSigning" formControlName="eSigning"
                                            (change)="onItemChangeResend($event.target.value)">
                                        <label class="custom-control-label" for="yes">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <!-- Add title -->
                            <span class="d-block mb-2 fw-bold">Client Details:</span>

                            <div class="row" id="ContentDisable">
                                <div class="col-md-6 col-lg-6">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Signatory First
                                            Name<span class="err-color">*</span>
                                        </label>
                                        <input type="text" formControlName="SignatoryFirstName" class="form-control"
                                            id="SignatoryFirstName" name="SignatoryFirstName"
                                            placeholder="Signatory First Name">
                                        <ng-container *ngFor="let validation of validationMessages.SignatoryFirstName">
                                            <div class="errorvl"
                                                *ngIf="ResendForm.get('SignatoryFirstName').hasError(validation.type) && (ResendForm.get('SignatoryFirstName').dirty || ResendForm.get('SignatoryFirstName').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Signatory Email
                                            ID<span class="err-color">*</span>
                                        </label>
                                        <input type="text" formControlName="SignatoryEmailID" class="form-control"
                                            id="SignatoryEmailID" name="SignatoryEmailID"
                                            placeholder="Signatory Email ID">
                                        <ng-container *ngFor="let validation of validationMessages.SignatoryEmailID">
                                            <div class="errorvl"
                                                *ngIf="ResendForm.get('SignatoryEmailID').hasError(validation.type) && (ResendForm.get('SignatoryEmailID').dirty || ResendForm.get('SignatoryEmailID').touched)">
                                                <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                    }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6" [hidden]="IsSpouse">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Spouse First
                                            Name<span class="err-color">*</span>
                                        </label>
                                        <input type="text" formControlName="SpouseFirstName" class="form-control"
                                            id="SpouseFirstName" name="SpouseFirstName" placeholder="Spouse First Name">
                                        <ng-container *ngFor="let validation of validationMessages.SpouseFirstName">
                                            <div class="errorvl"
                                                *ngIf="ResendForm.get('SpouseFirstName').hasError(validation.type) && (ResendForm.get('SpouseFirstName').dirty || ResendForm.get('SpouseFirstName').touched)">
                                                <label class="errorcolor"> {{ validation.message }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6" [hidden]="IsSpouse">
                                    <div class="mb-2 mb-md-3 mb-lg-4">
                                        <label for="exampleFormControlInput1" class="form-label mb-1">Spouse Email
                                            ID<span class="err-color">*</span>
                                        </label>
                                        <input type="text" formControlName="SpouseEmailID" class="form-control"
                                            id="SpouseEmailID" name="SpouseEmailID" placeholder="Spouse Email ID">
                                        <ng-container *ngFor="let validation of validationMessages.SpouseEmailID">
                                            <div class="errorvl"
                                                *ngIf="ResendForm.get('SpouseEmailID').hasError(validation.type) && (ResendForm.get('SpouseEmailID').dirty || ResendForm.get('SpouseEmailID').touched)">
                                                <label class="errorcolor" *ngIf="!condition"> {{ validation.message
                                                    }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="modal-footer border-0 pb-0 pt-3 px-0">
                            <div>
                                <button type="button" class="btn p-btn-outline me-3" data-bs-dismiss="modal"
                                    (click)="closeResendPopup()">Close</button>
                                <button *ngIf="IsSpouse" type="button" class="btn p-btn" id="clientSignature1"
                                    [disabled]="(ResendForm.get('eSigning').value === true && (!ResendForm.get('SignatoryFirstName').value || !ResendForm.get('SignatoryEmailID').value || ResendForm.get('SignatoryFirstName').invalid || ResendForm.get('SignatoryEmailID').invalid))"
                                    (click)="submitResendLetter()">Submit</button>
                                <button *ngIf="!IsSpouse" type="button" class="btn p-btn" id="clientSignature2"
                                    [disabled]="!ResendForm.valid && ResendForm.get('eSigning').value"
                                    (click)="submitResendLetter()">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal -->
<div class="modal" tabindex="-1" id="resenderror" [ngStyle]="{'display':displayResendErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="resenderror">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeResendErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p style="word-wrap: break-word;text-align: center;"><span class="d-block text-center">Please provide
                        input to mandatory
                        field(s)-</span>
                    {{getEmptyFields}}
                </p>
                <button type="button" (click)="closeResendErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- PreviewSurvey Modal -->
<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="model-action">

                <a class=" action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
                        alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="model_title">Preview Template</h5>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <div class="template_preview_panel docpreview">
                                <div class="preview_header">
                                    <h5> Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100">

                                    <div id="content" #surveyContent>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Preview Modal -->
<div class="modal" id="preview_doc" [ngStyle]="{'display':displayPreview}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header border-0 justify-content-end">
                <button type="button" class="btn-close mx-3 mt-2 shadow-none" data-bs-dismiss="modal"
                    (click)="closePreview()"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="template_preview_panel doc_preview">
                                <div class="preview_body h-100">
                                    <div *ngIf="this.pdfSrc" style="height: 100%;">
                                        <pdf-viewer #pdfViewer class="customtaxlayerremove" [src]="pdfSrc"
                                            [stick-to-page]="true" [show-borders]="true" [render-text]="true"
                                            style="display: block;width:100%;height:calc(100vh - 180px);position: relative;">
                                        </pdf-viewer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Info List Modal -->
<div class="modal" tabindex="-1" id="bulk-letter-edit-field" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayInfoEsigningList}">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content p-4">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0">Letter Signing Status</h5>
                <button type="button" class="btn-close shadow-none" (click)="closeInfoEsigningList()"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <!-- <p><span class="err-color">Note: </span>All the fields are mandatory</p> -->
            <div class="modal-body p-0">
                <p-table #dt1 styleClass="p-datatable-gridlines" dataKey="id" [rows]="10" [value]="this.InfosigningData"
                    [resizableColumns]="true" sortMode="multiple" [scrollable]="true" scrollHeight="1000px"
                    [showCurrentPageReport]="true" [(first)]="firstpage"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    [globalFilterFields]="['SignerName','SignerEmailId','SigningStatus']">
                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col style="width:25%">
                            <col style="width:30%">
                            <col style="width:30%">

                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn>Signer Name </th>
                            <th pResizableColumn> Signer Email ID</th>
                            <th pResizableColumn>Signing Status</th>

                        </tr>
                        <!-- grid searchbox -->
                        <!-- <tr>
    
                            <th> <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'BatchId', 'contains')"
                                    class="p-column-filter"></th>
    
                            <th> <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'PartnerName', 'contains')"
                                    class="p-column-filter"></th>
                            <th> <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'Office', 'contains')"
                                    class="p-column-filter"></th>
    
                        </tr> -->
                    </ng-template>
                    <ng-template pTemplate="body" let-InfoSigning>
                        <tr>
                            <td>{{InfoSigning.SignerName}}</td>
                            <td>{{InfoSigning.SignerEmailId}}</td>
                            <td>{{InfoSigning.SigningStatus}}</td>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="12" style="text-align: center;">No E-Signing Status found</td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>
            <div class="modal-footer border-0 pb-0 pt-3 px-0">
                <div>
                    <button type="button" (click)="closeInfoEsigningList()" class="btn p-btn-outline me-3"
                        data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Common Error Modal -->
<div class="modal" tabindex="-1" id="commonerror" [ngStyle]="{'display':displayCommonErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="commonerror">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeDeleteErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeDeleteErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Display Wizard and Edits Blocks preview process-->

<div class="modal" id="viewtemplate" [ngStyle]="{'display':displayEditblockpreview}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content" id="viewChooseParaTemplate">
            <!-- Modal Header -->
            <div class="modal-header border-0 px-4 py-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="d-flex align-items-center h-100">
                                <h6 class="text-dark fw-bold m-0">Choose Paragraphs</h6>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class='text-end'>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close" alt="close" (click)="closeblockpreview()"></button>
                            </div>
                            <div class="d-flex justify-content-between align align-items-center flex-wrap">
                                <div>
                                    <p class="fw-bold mb-1">Client ID: <span class="fw-medium px-2">
                                            {{this.clientId}}</span>
                                    </p>
                                    <p class="fw-bold mb-1">Client Name: <span class="fw-medium px-2">
                                            {{this.ClientName}}</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="border h-100 para">
                                <div class="template_preview_panel">
                                    <div class="preview_header">
                                        <h5 class="text-dark fw-bold fs-6">Choose Paragraphs</h5>
                                    </div>
                                    <div class="preview_body h-100 p-3 preview_body_scroll"
                                        #ChooseParagraphScrollPosition>
                                        <div id="previewquestionblock" #previewquestionblock class=" new_height_align">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="border h-100">
                                <div class="template_preview_panel"
                                    [ngClass]="togglefullscreen ? 'panel-fullscreen' : ''">
                                    <div class="preview_header">
                                        <h5 class="text-dark fw-bold fs-6">Letter Preview</h5>
                                    </div>
                                    <div class="preview_body h-100 p-3 preview_body_scroll"
                                        #ChooseParagraphLetterPreviewScrollPosition>
                                        <div id="previewareablock" #previewareablock
                                            class="preview_inner new_height_align shadow p-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <div>
                    <div class="d-flex justify-content-lg-end gap-3 flex-wrap">
                        <button type="button" (click)="closeblockpreview()" class="btn p-btn-outline">Close</button>
                        <button type="button" (click)="QuestionsBlocksubmit()" class="btn p-btn">Submit</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="viewtemplate" [ngStyle]="{'display':displayEditblockpreviewquestion}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content" id="viewEditParaTemplate">
            <!-- Modal Header -->
            <div class="modal-header border-0 px-4 py-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class="d-flex align-items-center h-100">
                                <h6 class="text-dark fw-bold m-0">Edit Paragraphs</h6>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6">
                            <div class='text-end'>
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal"
                                    aria-label="Close" alt="close" (click)="closeblockpreviewquestion()"></button>
                            </div>
                            <div class="d-flex justify-content-between align align-items-center flex-wrap">
                                <div>
                                    <p class="fw-bold mb-1">Client ID: <span class="fw-medium px-2">
                                            {{this.clientId}}</span>
                                    </p>
                                    <p class="fw-bold mb-1">Client Name: <span class="fw-medium px-2">
                                            {{this.ClientName}}</span>
                                    </p>
                                </div>
                                <!-- <div class="ms-auto">
                                    <button *ngIf="currentView === 'Blocks'" type=" button" [disabled]="IsEditButton"
                                        (click)="EditBlock()" class="btn p-btn mx-5">Edit</button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body" #EditParagraphScrollPosition>
                <div class="container-fluid">


                    <div class="col-md-12 ">
                        <div class="template_preview_panel">
                            <div class="preview_body h-100">
                                <div class="blockinner edit_preview new_height_align" #blockinner id="blockinner">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <!-- <div *ngIf="currentView === 'Wizard'">
                    <div class="d-flex justify-content-lg-end gap-3 flex-wrap">
                        <button type="button" (click)="closeblockpreview()" class="btn p-btn-outline">Close</button>
                        <button type="button" (click)="Blocks()" class="btn p-btn">Edit Paragraph</button>
                    </div>
                </div> -->

                <div class="d-flex justify-content-lg-end gap-3 flex-wrap">
                    <button type="button" (click)="closeblockpreviewquestion()" class="btn p-btn-outline">Close</button>
                    <button type="button" (click)="EditParagraphsubmit()" class="btn p-btn">Submit</button>

                </div>

            </div>
        </div>
    </div>
</div>

<!--Preview Letter Modal start here -->
<div id="viewletter" class="modal" [class.shadow-popup]="thirdPopupOpen" tabindex="-1" aria-modal="true" role="dialog"
    [ngStyle]="{'display':displayCkeditor}">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header border-0 p-3 pb-0">
                <!-- <button type="button" (click)="closeeditor()" class="close mx-3 mt-2" data-dismiss="modal">×</button> -->
                <button type="button" class="btn-close shadow-none" (click)="closeeditor()" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="m-0">
                                Block Name:
                                <span class="fw-bold"> {{ this.editjsonvalue.BlockName }}</span>
                            </p>
                        </div>
                    </div>

                    <div class=" row no-gutters">
                        <div class="col-12 col-sm-12 col-md-12 mt-3" #editornew>
                            <div class=" edit_content_preview">
                                <ckeditor [(data)]="this.editjsonvalue.Content" tagName="textarea"
                                    bodyClass='document-editor' [config]="config" (ready)="onReady( $event)"
                                    (change)="onChangeevent( $event )" (focus)="onFocus( $event )" (key)="onkey($event)"
                                    (blur)="onBlur( $event )" (paste)="onPaste( $event )" (keyup)="onkeyDown($event)"
                                    (afterPaste)="onAfterPaste( $event )" (dragStart)="( $event )"
                                    (dragEnd)="onDragEnd( $event )" (drop)="onDrop( $event )"> </ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0">
                <form [formGroup]="updateForm">
                    <div class="form-group">
                        <!-- onDragStart  -->
                        <div class="text-end bg-white me-md-3">
                            <button type=" button" (click)="closeeditor()" class="btn p-btn-outline">Cancel</button>
                            <button type="button" (click)="updateblockcontent()" class="btn p-btn ms-3">Update</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="model-action">

                <a class=" action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
                        alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5 class="model_title">Preview Template</h5>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <div class="template_preview_panel docpreview">
                                <div class="preview_header">
                                    <h5> Preview Panel</h5>

                                </div>
                                <div class="preview_body h-100">

                                    <div id="signingblock" #siginingcontent>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center mt-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- CK edit Error Modal -->
<div class="modal" tabindex="-1" id="commonerror" [ngStyle]="{'display':displayCKeditorErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="commonerror">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeCKeditorErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16" [innerHTML]="errorMessage"></p>
                <button type="button" (click)="closeCKeditorErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>


<!-- Success Modal blocks-->
<div class="modal" tabindex="-1" id="success" [ngStyle]="{'display':displaySuccessModalblocks}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="success">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessModalblocks()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}<br>Engagement Letter ID : {{
                    successBlockcontentEngageId}}
                </p>
                <button type="button" (click)="closeSuccessModalblocks()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>


<div class="modal" tabindex="-1" id="add-attachment" [ngStyle]="{'display':displayAddAttachment}"
    [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable upload-attachment">
        <div class="modal-content p-4" id="add-attachment">
            <div class="modal-header border-0 p-0">
                <h5 class="modal-title fs-16 highlight fw-semibold m-0 mb-md-2">Upload Attachments
                    <span class="fs-12 label-color fw-regular">(Maximum of “5” attachments can be
                        added)</span>
                </h5>
                <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeAttachmentPopup()"></button>
            </div>
            <div class="d-inline-flex">
                <span class="err-color">Note:</span>
                <ul class="ps-4">
                    <li class="label-color">Based on the attachment type (Letter or Email), System will
                        attach the attachments with letter or email.
                    </li>
                    <li class="label-color">If the attachment is marked as Letter, then it will be attached
                        with the letter.

                    </li>
                    <li class="label-color">If the attachment is marked as Email, then it will be attached
                        with the signing email and not with the letter.

                    </li>
                </ul>
            </div>
            <div class="modal-body p-0">
                <div class="p-2 border rounded h-100">

                    <div class="files-list py-3">
                        <table class="table new-implement-attachment">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th class="table-header-w-200">Name</th>
                                    <th>Level</th>
                                    <th>Attach with</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of staticRows; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td class="table-cell-ellipse" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="{{ uploadedFiles[i]?.Filename || '' }}">{{ uploadedFiles[i]?.Filename ||
                                        '' }}</td>
                                    <td class="table-cell-ellipse">{{ uploadedFiles[i]?.Filename ?
                                        uploadedFiles[i].Level : '' }}</td>

                                    <td>
                                        <span>
                                            <label *ngIf="uploadedFiles[i]?.Filename " class="mx-2">
                                                <input type="radio" name="AttachWith{{i}}"
                                                    [(ngModel)]="uploadedFiles[i].AttachWith" value="Email"
                                                    [disabled]="newflowradiobutton" class="form-check-input"> Email
                                            </label>
                                            <label *ngIf="uploadedFiles[i]?.Filename " class="mx-2">
                                                <input type="radio" name="AttachWith{{i}}"
                                                    [(ngModel)]="uploadedFiles[i].AttachWith" value="Letter"
                                                    class="form-check-input"> Letter
                                            </label></span>


                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="position-relative file-upload">
                                                <input type="file" id="upload-btn-{{i}}" class="visually-hidden"
                                                    (change)="onFileSelected($event, i)" accept=".pdf"
                                                    [disabled]="uploadedFiles[i]?.Filename">

                                                <!-- Label for input file element, which acts as the clickable area -->
                                                <label for="upload-btn-{{i}}" class="uploadLink"
                                                    style="margin-right: 7px;">
                                                    <i class="fa fa-plus-circle" aria-hidden="true"
                                                        [ngClass]="{'disabled-icon': uploadedFiles[i]?.Filename, 'fa-plus-circle': !uploadedFiles[i]?.Filename}"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Add">
                                                    </i>
                                                </label>
                                            </div>
                                            <ng-container *ngIf="uploadedFiles[i]?.Filename">
                                                <a class="link-color text-decoration-none fw-medium"
                                                    (click)="uploadedFiles[i] && openAttachmentPreview(uploadedFiles[i])"
                                                    style="margin-right: 10px;" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Preview">
                                                    <i class="fas fa-eye" aria-hidden="true" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Preview"></i>
                                                </a>
                                                <i class="fa fa-trash" aria-hidden="true"
                                                    (click)="uploadedFiles[i] && removeAttachment(i)"
                                                    style="cursor:pointer;" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Delete"></i>
                                            </ng-container>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pb-0 pt-3 px-0">
                <p class="note" style="margin-right: 172px;"><i
                        class="fa-solid fa-triangle-exclamation "></i>&nbsp;<b>Note:
                        <span class="text-danger"> Do not</span> send any
                        PDFs with sensitive
                        information</b></p>
                <div>
                    <button type="button" class="btn p-btn-outline me-3" data-bs-dismiss="modal"
                        (click)="closeAttachmentPopup()">Close</button>
                    <!-- <button type="button" id="submitFileUpload" class="btn p-btn"
                        (click)="submitUploadedFile()">Submit</button> -->
                    <button type="button" id="submitFileUpload" class="btn p-btn"
                        (click)="submitBulkUploadedFile()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="preview_doc" [ngStyle]="{'display':displayPreviewdocument}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header border-0 justify-content-end">
                <button type="button" class="btn-close mx-3 mt-2 shadow-none" data-bs-dismiss="modal"
                    (click)="closeAttachmentPreview()"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="template_preview_panel doc_preview">
                                <div class="preview_body h-100">
                                    <pdf-viewer class="customtaxlayerremove" #docPdfViewer [src]="this.pdfsrcdoc"
                                        [stick-to-page]="true" [show-borders]="true" [render-text]="true"
                                        style="display: block;width:100%;height:calc(100vh - 180px);position: relative;">
                                    </pdf-viewer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal Attachment -->
<div class="modal" tabindex="-1" id="attachmenterror" [ngStyle]="{'display':displayErrorModalattachment}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="attachmentErrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeAttachmentErrorModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16"> {{errorMessage}}</p>
                <button type="button" id="attachmentErrorOk" (click)="closeAttachmentErrorModal()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Success Modal for Attachment-->
<div class="modal" tabindex="-1" id="attachmentsuccessmodal" [ngStyle]="{'display':displaySuccessAttachmentModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="attachmentsuccess">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessAttachmentModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <!-- <p class="fs-16">{{successMessage}}<br>Client ID:{{ successBlockcontentClientID}}</p> -->
                <p class="fs-16">{{successMessage}}</p>
                <p style="word-wrap:break-word;text-align:center;">Engagement Letter ID :
                    {{successAttachmentEngagementId}}</p>
                <button id="successOk" type="button" (click)="closeSuccessAttachmentModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>


<!-- Edit attachment end -->

<div class="modal" id="CreateFormEdit" tabindex="-1" aria-modal="true" role="dialog"
    [ngStyle]="{'display':FormEditCkeditor}" [class.shadow-popup]="secondPopupOpen">
    <div class="modal-dialog  modal-dialog-centered  modal-lg" id="CreateEdit7216ModalDialog">
        <div class="modal-content p-4" id="Edit7216">
            <div class="modal-header border-0 p-0">
                <h5 class="block_title">Edit 7216 Provider
                </h5>
                <button type="button" class="btn-close shadow-none" (click)="closeeditor()" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>

            <div class="modal-body p-0">
                <div class="row no-gutters">
                    <div class="col-12 col-sm-12 col-md-12 mt-3" #editornew>
                        <div class=" edit_content_preview">
                            <ckeditor [(data)]="this.formeditjsonvalue.Content" tagName="textarea"
                                bodyClass='document-editor' [config]="config" (ready)="onReady( $event)"
                                (change)="onChangeevent( $event )" (focus)="onFocus( $event )" (key)="onkey($event)"
                                (blur)="onBlur( $event )" (paste)="onPaste( $event )" (keyup)="onkeyDown($event)"
                                (afterPaste)="onAfterPaste( $event )" (dragStart)="onDragStart( $event )"
                                (dragEnd)="onDragEnd( $event )" (drop)="onDrop( $event )">
                            </ckeditor>
                            <div class="modal-footer border-0 p-0 d-flex flex-column align-items-start">
                                <div class="d-flex w-100 mt-2">
                                    <h6 class="block_title Editing7216" style="
                                    margin-bottom: 0px;">Note:&nbsp;</h6>
                                    <span>This change will be applied to all the letters which has 7216 in this
                                        batch</span>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col-md-12">
                                    <form [formGroup]="updateFormEdit">
                                        <div class="row">
                                            <div class="mt-3 mt-md-3 text-end sticky-bottom bg-white">
                                                <button type="button" (click)="closeeditor()"
                                                    class="btn p-btn-outline">Cancel</button>
                                                <button type="button" (click)="updateFormEdit7216()"
                                                    class="btn p-btn ms-3">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Error FormEdit7216 Modal -->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':FE7216ErrorModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="FE7216ErrorModal">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModal()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{errorMessage}}</p>
                <button type="button" (click)="closeErrorModal()" class="error-btn" data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="engage-InformationEmail" [ngStyle]="{'display':displayemailInfo}"
    [class.shadow-popup]="thirdPopupOpen">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content px-4 py-3" id="EmailInfo-content">
            <div class="modal-header pt-0 px-0 pb-2">
                <h5 class="modal-title fs-18 highlight fw-semibold m-0">Email Information</h5>
                <button type="button" (click)="closeeditor()" class="btn-close shadow-none" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body p-0">
                <div class="pt-3">
                    <div class="table-responsive email-info">
                        <p-table #dt [value]="signerOneData" dataKey="Signer" styleClass="p-datatable-gridlines">
                            <ng-template pTemplate="colgroup">
                                <colgroup>
                                    <col style="width:40%">
                                    <col style="width:30%">
                                    <col style="width:30%">
                                </colgroup>
                            </ng-template>

                            <ng-template pTemplate="header">
                                <tr>
                                    <th scope="col">Signer Email ID</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Timestamp</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-emailInformation let-i="rowIndex">
                                <tr>
                                    <td>{{ emailInformation.EmailId}}</td>
                                    <td>{{ emailInformation.Status}}</td>
                                    <td>{{ emailInformation.Timestamp }}</td>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="4" style="text-align: center;">No fields Found</td>
                                </tr>
                            </ng-template>
                        </p-table>

                        <p-table #dt *ngIf="signerTwoData && signerTwoData.length > 0" [value]="signerTwoData"
                            dataKey="Signer" styleClass="p-datatable-gridlines mt-4">
                            <ng-template pTemplate="colgroup">
                                <colgroup>
                                    <col style="width:40%">
                                    <col style="width:30%">
                                    <col style="width:30%">
                                </colgroup>
                            </ng-template>

                            <ng-template pTemplate="header">
                                <tr>
                                    <th scope="col">Signer Email ID</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Timestamp</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-emailInformation let-i="rowIndex">
                                <tr>
                                    <td>{{ emailInformation.EmailId}}</td>
                                    <td>{{ emailInformation.Status }}</td>
                                    <td>{{ emailInformation.Timestamp }}</td>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="12" style="text-align: center;">No fields Found</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <br>

                        <div class="my-letter mb-4 p-0 bg-white rounded">
                            <div class="accordion accordion-flush" id="email-information-accordion">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button id="emailInfoButton" #emailInfoButton
                                            class="accordion-button collapsed p-2 fs-6 fw-semibold highlight shadow-none"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#emailInfoCollapse"
                                            (click)="collapseFilter2()" aria-expanded="false"
                                            aria-controls="emailInfoCollapse">
                                            <h5>Technical Information</h5>
                                        </button>
                                    </h2>
                                    <div id="emailInfoCollapse" #emailInfoCollapse class="accordion-collapse collapse"
                                        data-bs-parent="#email-information-accordion">
                                        <div class="accordion-body">
                                            <div *ngFor="let mailJetItem of InformationEmail.MailJetListView">
                                                <h6>MailJet Signer One</h6>
                                                <pre>{{ mailJetItem.MailJetSignerOne[0].Response | json }}</pre>

                                                <ng-container
                                                    *ngIf="mailJetItem.MailJetSignerTwo && mailJetItem.MailJetSignerTwo[0] && mailJetItem.MailJetSignerTwo[0].Response">
                                                    <h6>MailJet Signer Two</h6>
                                                    <pre>{{ mailJetItem.MailJetSignerTwo[0].Response | json }}</pre>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 px-0 pb-0">
                <div>
                    <button type="button" (click)="closeeditor()" class="btn p-btn"
                        data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="emailRemainderModal" [ngStyle]="{'display':emailRemainderConfirm}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="emailRemainderConfirm">
            <div class="modal-header">
                <h5 class="modal-title fw-semibold highlight">Send Signing Reminder</h5>
                <button type="button" (click)="onCloseSigningModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table aria-labelledby="table-description">
                    <tbody>
                        <tr>
                            <td>
                                <p class="pe-2 fw-semibold s-text">Engagement Letter ID</p>
                            </td>
                            <td>
                                <p class="s-text fw-medium">: {{ engagementLetterId }}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="pe-2 fw-semibold s-text">Engagement Letter Name</p>
                            </td>
                            <td>
                                <p class="s-text fw-medium">: {{ EngagementLetterName }}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="pe-2 fw-semibold s-text">Client Name</p>
                            </td>
                            <td>
                                <p class="s-text fw-medium">: {{ ClientName }}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <p class="s-text fw-bold mb-0 fs-16">{{emailRemainderMessage}}</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onCloseSigningModal()" class="btn p-btn-outline"
                    data-bs-dismiss="modal">No</button>
                <button type="button" (click)="emailRemainderById()" class="btn p-btn ms-2">Yes</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="emailRemainderSuccessmodal" [ngStyle]="{'display':EmailRemainderSuccessModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="EmailRemainderSuccessModal">
            <div class="modal-header success-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Success</h5>
                <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/success-icon.png" class="mb-3" alt="warning">
                <p class="fs-16">{{successMessage}}</p>
                <button id="successOk" type="button" (click)="closeSuccessModal()" class="success-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="emailRemainderWarning" [ngStyle]="{'display':EmailRemainderWarningModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="EmailRemainderWarningModal">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Warning</h5>
                <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">{{warningMessage}}</p>
                <button type="button" (click)="closeSuccessModal()" class="warning-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>

<!-- Error Modal blocks-->
<div class="modal" tabindex="-1" id="error" [ngStyle]="{'display':displayErrorModalblocks}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="errorModalBlocks">
            <div class="modal-header error-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                <button type="button" (click)="closeErrorModalblocks()" class="btn-close shadow-none"
                    data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">

                <p class="fs-16" style="word-wrap:break-word;text-align:center;">
                    {{errorMessage}}</p>
                <button type="button" id="Okerror" (click)="closeErrorModalblocks()" class="error-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>